import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

import * as thunks from './thunks';
import { columnRMA, GetRmasRequest, RMAColumns, RMAData } from './types';
import { PaginationV2 } from 'src/types';

type State = {
  rmas: RMAData[];
  rmaGetPayload: GetRmasRequest;
  rmaColumn: RMAColumns;
  paginationMeta: PaginationV2;
  loading: boolean;
};

const initialState: State = {
  rmas: [],
  rmaGetPayload: {
    limit: 100,
    status: '',
    serial_no: '',
    customer_name: '',
    product_name: '',
    date_created_from: '',
    date_created_to: '',
    location: '',
    supplier_id: 0,
    category_id: 0,
    manufacturer_id: 0
  },
  rmaColumn: {
    rma_id: true,
    customer_name: true,
    contact_no: true,
    serial_no: true,
    supplier_name: true,
    transaction_no: true,
    reported_problem: true,
    remarks: true,
    product_name: true,
    status: true,
    created_by: true,
    created_at: true,
    tech: true,
    location: true,
    images: true,
    turnaround_time: true
  },
  paginationMeta: {},
  loading: false
};

const slice = createSlice({
  name: 'rmas',
  initialState,
  reducers: {
    updateGetRmaPayload: (
      state,
      { payload }: PayloadAction<GetRmasRequest>
    ) => {
      state.rmaGetPayload = { ...state.rmaGetPayload, ...payload };
    },
    resetGetRmaPayload: (state) => {
      state.rmaGetPayload = initialState.rmaGetPayload;
      state.loading = false;
    },
    showAllColumns: (state) => {
      const allEnabled = Object.values(state.rmaColumn).every(Boolean);

      Object.keys(state.rmaColumn).forEach((key) => {
        state.rmaColumn[key] = !allEnabled;
      });
    },
    toggleRmaColumn: (state, { payload }: PayloadAction<columnRMA>) => {
      const key = payload;
      state.rmaColumn = {
        ...state.rmaColumn,
        [key]: !state.rmaColumn[key]
      };
    },
    updatePageFilter: (state, action: PayloadAction<{ page: number }>) => {
      state.loading = true;
      state.paginationMeta = {
        ...state.paginationMeta,
        current_page: action.payload.page
      };
    }
  },
  extraReducers(builder) {
    builder.addCase(
      thunks.updateRmaThunk.fulfilled,
      (state, { payload, meta }) => {
        const rmaLists = cloneDeep(state.rmas);

        const index = rmaLists.findIndex(
          (x) => x?.rma_id === meta?.arg?.rma_id
        );

        if (index > -1) {
          if (rmaLists[index] && payload?.originalData?.data) {
            rmaLists[index] = payload?.originalData.data;
            state.rmas = rmaLists;
          }
        }
      }
    );

    builder.addCase(thunks.getRmasThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(thunks.getRmasThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        if (
          state.paginationMeta.current_page &&
          state.paginationMeta.current_page > 1
        ) {
          state.rmas = [...state.rmas, ...(payload?.originalData?.data || [])];
        } else {
          state.rmas = payload?.originalData?.data || [];
        }

        state.paginationMeta = payload?.originalData?.meta || {};

        state.loading =
          (state.paginationMeta.current_page ?? 0) >=
          (state.paginationMeta.last_page ?? 0);
      }

      state.loading = false;
    });

    builder.addCase(thunks.getRmasThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(thunks.rmaDeleteThunk.fulfilled, (state, actions) => {
      const rmaId = actions.meta.arg;
      const clonedProducts = cloneDeep(state.rmas);
      const index = state?.rmas?.findIndex((x) => x?.rma_id === rmaId);
      if (index > -1) {
        clonedProducts.splice(index, 1);
      }
      state.rmas = clonedProducts;
    });
  }
});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions,
  ...thunks
};
