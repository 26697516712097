import { createAsyncThunk, Dispatch } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api, { defaultHeaders } from 'src/services/ApiService';
import {
  CheckTokenRequest,
  CheckTokenResponse,
  CommonAxiosResponse,
  CommonResponseMessage,
  CreateUserRequest,
  FixMeLaterApi,
  GetAllUsersResponse,
  GetUserResponse,
  LoginRequest,
  LoginResponse,
  Passcode,
  UpdateUserRequest,
  UpdateUserResponse,
  UserBasicInfo
} from 'src/types';
import { actions } from '../user';
import { SupabaseLoginResponse } from '../taptap-customer/types';
import { SupabaseClientService } from 'src/services';
import { reverseString } from 'src/utils';

export const createUserThunk = createAsyncThunk<
  CommonAxiosResponse<CommonResponseMessage>,
  CreateUserRequest,
  { state: RootState }
>('user/createUser', async (data) => {
  const response = await Api.post(`/register`, data);
  return response;
});

export const getAllUsersThunk = createAsyncThunk<
  CommonAxiosResponse<GetAllUsersResponse>,
  undefined,
  { state: RootState }
>('user/getAllUsers', async () => {
  const response = await Api.get(`/users/getAllUsers`);
  if (!response.success) {
    throw new Error('Failed at getAllUsers.');
  }
  return response;
});

export const getUserThunk = createAsyncThunk<
  CommonAxiosResponse<GetUserResponse>,
  number,
  { state: RootState }
>('user/getUserThunk', async (id) => {
  const response = await Api.get(`/users/getUser/${id}`);
  if (!response.success) {
    throw new Error('Failed at getUserThunk.');
  }
  return response;
});

export const checkUserTokenThunk = createAsyncThunk<
  CommonAxiosResponse<CheckTokenResponse>,
  CheckTokenRequest
>('user/checkUserTokenThunk', async (data) => {
  const response = await Api.get('/check-token', data);
  if (!response.success) {
    throw new Error('Failed at getUserThunk.');
  }
  return response;
});

export const updateOwnAccountThunk = createAsyncThunk<
  CommonAxiosResponse<UpdateUserResponse>,
  UpdateUserRequest,
  { state: RootState }
>('user/createUser', async (data) => {
  if (data?.passcode) {
    const passcodeResponse = await Api.patch('user/passcode/set', {
      username: data.username,
      passcode: data.passcode
    });
    const response = await Api.patch(
      `/users/updateOwnAccount/${data?.id}`,
      data
    );
    return response?.success && passcodeResponse?.success
      ? response
      : { ...response, success: false };
  }

  const response = await Api.patch(`/users/updateOwnAccount/${data?.id}`, data);
  return response;
});

export const updateUserThunk = createAsyncThunk<
  CommonAxiosResponse<GetUserResponse>,
  UpdateUserRequest,
  { state: RootState }
>('user/createUser', async (data) => {
  const response = await Api.patch(`/users/updateUser/${data?.id}`, data);
  return response;
});

export const deleteUserThunk = createAsyncThunk<
  CommonAxiosResponse<CommonResponseMessage>,
  number,
  { state: RootState }
>('user/deleteUserThunk', async (id) => {
  const response = await Api.delete(`/users/deleteUser/${id}`);
  return response;
});

export const login = createAsyncThunk<
  CommonAxiosResponse<LoginResponse>,
  LoginRequest,
  { state: RootState }
>('user/login', async (data) => {
  const headers = {
    ...defaultHeaders,
    Authorization: `Bearer ${data?.oAuthBearerToken}`
  };

  const loginData = {
    username: data?.username,
    password: data?.password
  };

  const response = await Api.post('login', loginData, { headers });

  if (response?.originalData?.user) {
    let supabaseUserInfoUponLogin: SupabaseLoginResponse = {};
    const userInfo: UserBasicInfo = response?.originalData?.user;
    const supabaseLoginCreds = {
      email: `${userInfo?.id}@pcworth.com`,
      password: reverseString(`${userInfo?.id}@pcworth.com`)
    };
    const supabaseLoginResp = await SupabaseClientService.auth.signInWithPassword(
      supabaseLoginCreds
    );

    if (!supabaseLoginResp?.error) {
      supabaseUserInfoUponLogin = supabaseLoginResp;
    }
    if (supabaseLoginResp?.error?.code === 'invalid_credentials') {
      // If you got here. It means you logged in successfully and you dont have an account yet in supabase
      const supabaseSignUpResp = await SupabaseClientService.auth.signUp({
        ...supabaseLoginCreds,
        options: {
          data: {
            first_name: userInfo?.first_name,
            last_name: userInfo?.last_name,
            internal_id: userInfo?.id,
            username: userInfo?.username,
            display_name: `${userInfo?.first_name} ${userInfo?.last_name}`
          }
        }
      });
      if (supabaseSignUpResp?.data?.user) {
        // eslint-disable-next-line no-unused-vars
        supabaseUserInfoUponLogin = supabaseSignUpResp;
      }
    }

    if (supabaseUserInfoUponLogin?.data?.user) {
      const supabaseUserData = supabaseUserInfoUponLogin?.data?.user;
      await SupabaseClientService.rpc('set_current_user', {
        user_id: supabaseUserData?.id || null,
        display_name: supabaseUserData?.user_metadata?.display_name || null
      });
    }
  }

  return response;
});

export const logout = createAsyncThunk<
  FixMeLaterApi,
  undefined,
  { state: RootState }
>('user/logout', async () => {
  const response = await Api.post('logout');
  return response;
});

export const validatePasscode = (payload: Passcode) => {
  return async (dispatch: Dispatch) => {
    dispatch(actions.validatePasscodeRequest());
    let data = null;
    let problem = null;
    try {
      const response = await Api.post('user/passcode/enter', payload);
      data = response.originalData;
      problem = response.message;
    } catch (error) {
      dispatch(actions.validatePasscodeReject({ message: problem }));
    }

    if (!data) {
      dispatch(actions.validatePasscodeReject({ message: problem }));
      return;
    }

    if (data?.errors) {
      dispatch(actions.validatePasscodeReject(data));
      return;
    }

    if (data?.success === false) {
      dispatch(actions.validatePasscodeReject(data));
      return;
    }

    if (data?.success) {
      dispatch(actions.validatePasscodeResolve(data));
    }
  };
};
