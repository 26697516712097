import React, { useMemo, useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Typography,
  Divider,
  IconButton,
  Button,
  Tooltip,
  InputAdornment
} from '@material-ui/core';
import CropFreeIcon from '@material-ui/icons/CropFree';

import { GalleryComponent, Product } from 'src/types';
import {
  componentProductsToQuotationProducts,
  formatCurrency
} from 'src/utils';
import clsx from 'clsx';
import { LinkComponent, LoaderBar } from 'src/components';
import { useNavigate } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import { slices, useAppDispatch } from 'src/redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { cloneDeep, debounce } from 'lodash';
import { useSnackBar } from 'src/hooks';
import MobileScanner from 'src/components/scanner/MobileScanner';
import { useIsMobile } from 'src/hooks/use-is-mobile';

const { actions: productActions } = slices.product;
const { actions: transactionActions } = slices.transaction;

interface Props {
  data?: GalleryComponent[];
  onComponentsChange?: (newComponents: GalleryComponent[]) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  cpuField: {
    borderWidth: 5
  },
  subHeader: {
    marginBottom: theme.spacing(2)
  },
  addBtn: {
    marginTop: theme.spacing(2)
  },
  componentsListDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  productTitle: {
    fontWeight: 600,
    letterSpacing: 0.5,
    fontSize: 12
  },
  optionListPrice: {
    fontWeight: 500,
    fontSize: 12
  },
  category: {
    fontSize: 10,
    marginLeft: theme.spacing(0.5)
  },
  deleteIcon: {
    padding: 0,
    marginRight: theme.spacing(1)
  }
}));

const component = ({ data = [], onComponentsChange }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const isMobile = useIsMobile();

  //TODO: by default branch id is equal to 1
  const branch_ids = [1];

  const [transactionNoInput, setTransactionNoInput] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [transactionLoading, setTransactionLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [isScannerOpen, setScannerOpen] = useState(false);

  const getProducts = debounce(async (keyword: string) => {
    setLoading(true);
    const response = unwrapResult(
      await dispatch(
        productActions.getProductsForGalleryThunk({ keyword, branch_ids })
      ).finally(() => setLoading(false))
    );
    if (response?.success && response?.originalData?.data?.length > 0) {
      setProducts(response?.originalData?.data || []);
    }
  }, 500);

  /**
   * Adds products from a transaction.
   */
  const getProductsFromTransaction = async (
    transactionNo: string = transactionNoInput
  ) => {
    setTransactionLoading(true);
    const response = unwrapResult(
      await dispatch(
        transactionActions.getTransactionViaTransactionNoForGallery(
          transactionNo?.trim()
        )
      ).finally(() => {
        setTransactionLoading(false);
        setTransactionNoInput('');
      })
    );
    if (
      response?.success &&
      response?.originalData?.items &&
      response?.originalData?.items?.length > 0
    ) {
      const items = response?.originalData?.items || [];
      const newProducts: GalleryComponent[] = items?.map((item) => ({
        id: null,
        action: 'create',
        product_id: item?.product_id,
        product_name: item?.product_name,
        retail_price: item?.retail_price,
        category: {
          id: item?.category_id || 0,
          name: item?.category_name || '--'
        }
      }));
      if (onComponentsChange) {
        const clonedData = cloneDeep(data);
        onComponentsChange([...clonedData, ...newProducts]);
      }
    } else {
      snackBar.show({
        severity: 'error',
        message: 'Transaction does not exist'
      });
    }
  };

  const onAddComponentInternal = (newProduct: Product | null) => {
    if (onComponentsChange && newProduct) {
      const clonedData = cloneDeep(data);
      const adjustedNewProduct: GalleryComponent = {
        id: null,
        product_id: newProduct?.id,
        action: 'create',
        product_name: newProduct?.name,
        retail_price: newProduct?.retail_price,
        category: {
          id: newProduct?.category_id || 0,
          name: newProduct?.category || '--'
        }
      };
      onComponentsChange([...clonedData, adjustedNewProduct]);
    }
  };

  const onRemoveComponent = (index: number) => {
    if (onComponentsChange) {
      const clonedData = cloneDeep(data);
      if (clonedData[index]?.id) {
        clonedData[index].product_id = null;
        clonedData[index].action = 'delete';
      } else {
        clonedData.splice(index, 1);
      }
      onComponentsChange(clonedData);
    }
  };

  const onEnterTransactionNoPress = (e: any) => {
    const inputValue = e.target?.value;
    if (inputValue) {
      getProductsFromTransaction(inputValue);
    }
  };

  const productsToQuotation = useMemo(
    () => componentProductsToQuotationProducts(data || []),
    [data]
  );

  const pcbuildsToQuotation = () => {
    navigate(`/app/quotation`, { state: productsToQuotation });
  };

  return (
    <CardContent>
      <Typography className={classes.subHeader} variant="h6">
        Add Components / Products
      </Typography>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <Autocomplete
            fullWidth
            loading={loading}
            onChange={(_, newValue) => onAddComponentInternal(newValue)}
            onInputChange={(_, newInputValue) => getProducts(newInputValue)}
            id="product_name"
            options={products}
            getOptionLabel={(option) => `${option?.name}`}
            renderInput={(params) => (
              <TextField
                {...params}
                name="product_name"
                label="Product Name"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            label={
              isMobile
                ? 'Add Transaction No.'
                : 'Add From Transaction. Enter Transaction No.'
            }
            name=""
            variant="outlined"
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onEnterTransactionNoPress(e);
              }
            }}
            onChange={(e) => setTransactionNoInput(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Scan QR Code Transaction No.">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => setScannerOpen(true)}
                    >
                      <CropFreeIcon fontSize="large" />
                    </Button>
                  </Tooltip>
                </InputAdornment>
              )
            }}
          />

          <LoaderBar isLoading={transactionLoading} />
        </Grid>

        <Grid item xs={12}>
          {data?.map((product, i) => {
            if (product?.action === 'delete') {
              return null;
            } else {
              return (
                <div
                  className={classes.componentsListDiv}
                  key={`${product?.product_id}${i}`}
                >
                  <IconButton
                    className={classes.deleteIcon}
                    onClick={() => onRemoveComponent(i)}
                  >
                    <DeleteIcon
                      onClick={() => {}}
                      fontSize="small"
                      color="secondary"
                    />
                  </IconButton>
                  <div>
                    <Typography className={classes.productTitle}>
                      <LinkComponent
                        onClick={() =>
                          navigate(`/app/products/${product?.product_id}`)
                        }
                        href={`/app/products/${product?.product_id}`}
                        title={product?.product_name || '--'}
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    </Typography>

                    <div className={classes.componentsListDiv}>
                      <Typography className={classes.optionListPrice}>
                        {product?.retail_price
                          ? `${formatCurrency(product?.retail_price)}`
                          : '--'}
                      </Typography>
                      <Typography
                        className={clsx(
                          classes.optionListPrice,
                          classes.category
                        )}
                      >
                        {`(${product?.category?.name})` || '--'}
                      </Typography>
                    </div>
                    <Divider />
                  </div>
                </div>
              );
            }
          })}
        </Grid>
        <Button
          fullWidth
          onClick={pcbuildsToQuotation}
          color="primary"
          variant="outlined"
        >
          CREATE/CUSTOMIZE QUOTATION
        </Button>
      </Grid>
      <MobileScanner
        label="Scan Transaction No."
        isOpen={isScannerOpen}
        onHandleClose={() => setScannerOpen(false)}
        onScannedResult={(result) => getProductsFromTransaction(result || '')}
      />
    </CardContent>
  );
};

export const GalleryProductsCardContent = React.memo(component);
