import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  Paper,
  Typography,
  makeStyles
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import { downloadCSV, formatCurrency } from 'src/utils';
import { priceColumn } from 'src/utils/fast-moving-products';
import { SelectedColumn } from 'src/types';

const useStyles = makeStyles({
  flexBox: {
    display: 'flex',
    alignItems: 'center'
  }
});

interface Props {
  headerKeys?: string[];
  columnIndicesToHide?: number[];
  fastMovingProductToCsv: any[];
  columnToHide: (indices: number[]) => void;
  onApplyFilterColumn: (columnToShow?: SelectedColumn[]) => void;
}

const component: FC<Props> = ({
  headerKeys,
  fastMovingProductToCsv,
  onApplyFilterColumn
}) => {
  const classes = useStyles();
  const [isColumnFilter, setIsColumnFilter] = useState<boolean>(false);
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [selectedColumn, setSelectedColumn] = useState<SelectedColumn[]>([]);

  const userBranchOptionsKeys = useMemo(() => {
    const columnObj = headerKeys
      ?.map((keys) => ({
        name: keys,
        value: 0
      }))
      .filter((column) => column?.name !== 'product_name');

    return [...(columnObj || [])];
  }, [headerKeys]);

  const handleToggle = (item: string, selectedColumn: SelectedColumn[]) => {
    setCheckedItems((prev) =>
      prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item]
    );

    const isExist = selectedColumn?.find((column) => item === column?.name);

    if (isExist) {
      const filterSelectedColumn = selectedColumn?.filter(
        (column) => column?.name !== item
      );

      setSelectedColumn(filterSelectedColumn);
    } else {
      const findNewColumnVal = userBranchOptionsKeys?.find(
        (branch) => branch?.name === item
      );

      if (findNewColumnVal) {
        setSelectedColumn((prev) => [...prev, findNewColumnVal]);
      }
    }
  };

  const onExportToCSVFastMovingProducts = () => {
    const fastMovingProdCsv = fastMovingProductToCsv?.map((item) => {
      // TypeScript: define newItem as a Record for key-value pairs
      // eslint-disable-next-line no-undef
      const newItem: Record<string, number | string> = {};

      headerKeys?.forEach((key: string) => {
        if (priceColumn.includes(key)) {
          newItem[key] = item[key] ? formatCurrency(item[key]) : '₱0.00';
        } else {
          newItem[key] = item[key] ?? 0;
        }

        if (key === 'margin_percentage') {
          newItem[key] = item[key] ? item[key] + '%' : '0%';
        }
      });

      return newItem;
    });

    const keys = selectedColumn?.map((column) => column?.name);

    //transformed data that has computed total sold based on available props
    const csvFastMovingData = fastMovingProdCsv?.map((product) => {
      let total_sold = 0;
      let total_available = 0;

      // First, filter the object based on selected keys
      const filteredEntries = Object.entries(product).filter(
        ([key]) =>
          keys.includes(key) ||
          key === 'product_name' ||
          key === 'total_sold' ||
          key === 'total_available'
      );

      // Then, compute total_sold and total_available from the filtered keys
      const filtered = Object.fromEntries(
        filteredEntries.map(([key, value]) => {
          if (key.includes('sold') && !key.includes('total_sold')) {
            total_sold += Number(value) || 0;
          }
          if (key.includes('available') && !key.includes('total_available')) {
            total_available += Number(value) || 0;
          }
          return [key, value]; // Keep the filtered key-value pairs
        })
      );

      return {
        product_name: product?.product_name, // Always include product_name
        ...filtered,
        total_sold,
        total_available
      };
    });

    const csvName = 'FastMovingProduct_PC_Worth.csv';
    downloadCSV(csvFastMovingData, csvName);
  };

  const onHandleApplyFilter = (selectedCol?: SelectedColumn[]) => {
    if (selectedCol) {
      onApplyFilterColumn(selectedCol);
      setIsColumnFilter(false);
    }
  };

  useEffect(() => {
    const optionKeys = userBranchOptionsKeys?.map((branch) => branch?.name);
    setCheckedItems(optionKeys);
    setSelectedColumn(userBranchOptionsKeys);
    onApplyFilterColumn(userBranchOptionsKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userBranchOptionsKeys]);

  return (
    <Paper style={{ marginTop: '1rem' }}>
      <Box
        className={classes.flexBox}
        padding={'1rem'}
        justifyContent={'space-between'}
      >
        <Button
          variant="outlined"
          color="primary"
          startIcon={<GetAppIcon />}
          onClick={onExportToCSVFastMovingProducts}
        >
          Export CSV
        </Button>

        <Button
          variant="outlined"
          color="primary"
          startIcon={<ViewWeekIcon />}
          onClick={() => setIsColumnFilter(true)}
        >
          Columns Filter
        </Button>
      </Box>

      <Dialog
        onClose={() => setIsColumnFilter(false)}
        aria-labelledby="simple-dialog-title"
        open={isColumnFilter}
        fullWidth
        maxWidth={'sm'}
      >
        <DialogTitle id="simple-dialog-title">
          <Typography variant="h4">Fast Moving Column Filters</Typography>
        </DialogTitle>
        <Box sx={{ p: 1 }}>
          <List>
            {userBranchOptionsKeys?.map((item, index) => (
              <ListItem key={index}>
                <Checkbox
                  checked={checkedItems.includes(item?.name)}
                  onChange={() => handleToggle(item?.name, selectedColumn)}
                />
                <Typography variant={'h4'}> {item?.name}</Typography>
              </ListItem>
            ))}
          </List>
          <Button
            fullWidth
            onClick={() => onHandleApplyFilter(selectedColumn)}
            variant="contained"
            color="primary"
          >
            Apply Filter
          </Button>
        </Box>
      </Dialog>
    </Paper>
  );
};

export const FastMovingProductSubToolbar = memo(component);
