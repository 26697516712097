import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import {
  CheckQuotationAvailabilityRequest,
  CheckQuotationAvailabilityResponse,
  CommonAxiosResponse,
  ComputeSRPRequest,
  ComputeSRPResponse,
  CreateTemplateResponse,
  GetQuotationCategoriesResponse,
  GetQuotationProductsRequest,
  GetQuotationProductsResponse,
  GetTemplateDetailsResponse,
  GetTemplateListResponse,
  GetTemplateRequest,
  NewTemplateDetails
} from 'src/types';

export const getQuotationsCategoriesThunk = createAsyncThunk<
  CommonAxiosResponse<GetQuotationCategoriesResponse>,
  undefined,
  { state: RootState }
>('quotation/getQuotationsCategoriesThunk', async (data) => {
  const response = await Api.get('/quotations/getCategories', data);
  if (!response.success) {
    throw new Error('Failed at getQuotationsCategoriesThunk.');
  }
  return response;
});

export const getQuotationsProductsThunk = createAsyncThunk<
  CommonAxiosResponse<GetQuotationProductsResponse>,
  GetQuotationProductsRequest,
  { state: RootState }
>('quotation/getQuotationsProductsThunk', async (data) => {
  const response = await Api.get(
    // old ver
    // `/quotations/category/products/get/${data.category_id}`,
    `/quotations/category/products/get`,
    data
  );
  if (!response.success) {
    throw new Error('Failed at getQuotationsProductsThunk.');
  }
  return response;
});

export const checkQuotationAvailabilityThunk = createAsyncThunk<
  CommonAxiosResponse<CheckQuotationAvailabilityResponse>,
  CheckQuotationAvailabilityRequest,
  { state: RootState }
>('quotation/checkQuotationAvailabilityThunk', async (data) => {
  const response = await Api.post(
    `/quotations/checkQuotationAvailability`,
    data
  );
  if (!response.success) {
    throw new Error('Failed at checkQuotationAvailabilityThunk.');
  }
  return response;
});

/**
 * Template Thunks
 */
export const getTemplateListThunk = createAsyncThunk<
  CommonAxiosResponse<GetTemplateListResponse>,
  GetTemplateRequest,
  { state: RootState }
>('quotation/getTemplateListThunk', async (data) => {
  const response = await Api.get('/quotation/get', data);

  if (!response.success) {
    throw new Error('Failed at getTemplateListThunk.');
  }
  return response;
});

export const getTemplateDetailsThunk = createAsyncThunk<
  CommonAxiosResponse<GetTemplateDetailsResponse>,
  GetTemplateRequest,
  { state: RootState }
>('quotation/getTemplateDetailsThunk', async (data) => {
  const response = await Api.get(`/quotation/get/${data.template_id}`, data);

  if (!response.success) {
    throw new Error('Failed at getTemplateListThunk.');
  }
  return response;
});

export const createTemplateThunk = createAsyncThunk<
  CommonAxiosResponse<CreateTemplateResponse>,
  NewTemplateDetails,
  { state: RootState }
>('quotation/getTemplateDetailsThunk', async (template) => {
  const response = await Api.post(`/quotation/create`, template);

  if (!response.success) {
    throw new Error('Failed at createTemplateThunk.');
  }

  return response;
});

export const updateTemplateThunk = createAsyncThunk<
  CommonAxiosResponse<GetTemplateDetailsResponse>,
  NewTemplateDetails,
  { state: RootState }
>('quotation/updateTemplateDetailsThunk', async (template) => {
  const response = await Api.patch(
    `/quotation/update/${template.id}`,
    template
  );

  if (!response.originalData.success) {
    throw new Error('Failed at updateTemplateThunk.');
  }

  return response;
});

export const onDeleteTemplateThunk = createAsyncThunk<
  CommonAxiosResponse<GetTemplateDetailsResponse>,
  number | undefined,
  { state: RootState }
>('quotation/deleteTemplateDetailsThunk', async (template_id) => {
  const response = await Api.delete(`/quotation/delete/${template_id}`);

  if (!response.originalData.success) {
    throw new Error('Failed at deleteTemplateThunk.');
  }

  return response;
});

export const onComputeSrpThunk = createAsyncThunk<
  CommonAxiosResponse<ComputeSRPResponse>,
  ComputeSRPRequest,
  { state: RootState }
>('quotation/onComputeSrpThunk', async (data) => {
  const response = await Api.post('/quotations/srp_computation/get', data);

  if (!response.originalData.success) {
    throw new Error('Failed at onComputeSrpThunk.');
  }

  return response;
});
