import React, { useRef, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core';
import { QrReader } from 'react-qr-reader';
import CloseIcon from '@material-ui/icons/Close';

interface Props {
  label?: string;
  isOpen: boolean;
  onHandleClose: () => void;
  onScannedResult: (result?: string) => void;
}

const MobileScanner: React.FC<Props> = ({
  label = 'QR Scanner',
  isOpen,
  onHandleClose,
  onScannedResult
}) => {
  const lastResult = useRef<string | null>(null);

  const onScannedRes = (result?: string) => {
    if (result && result !== lastResult.current) {
      lastResult.current = result;
      onScannedResult(result);

      onHandleClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      lastResult.current = null;
    }
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={onHandleClose} fullWidth maxWidth="sm">
      <IconButton
        aria-label="close"
        onClick={onHandleClose}
        style={{
          position: 'absolute',
          right: 8,
          top: 8
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>
        <Typography variant="h4">{label}</Typography>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <div
            style={{ width: '100%', maxWidth: '500px', position: 'relative' }}
          >
            <QrReader
              onResult={(result: any) => {
                onScannedRes(result?.getText?.());
              }}
              constraints={{ facingMode: 'environment' }} // main camera
            />

            {/* Crosshair Overlay */}
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                width: '180px',
                height: '180px',
                backgroundImage: "url('/static/images/scanner.png')", // Make sure image is in public/static/images
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                transform: 'translate(-50%, -50%)',
                pointerEvents: 'none'
              }}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MobileScanner;
