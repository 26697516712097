export enum PCWorthBranchesEnum {
  Earnshaw = 1,
  LPC = 2,
  Experience = 4,
}

export enum PCWorthBranchNameEnum {
  EARNSHAW = 'EARNSHAW',
  LPC = 'LPC',
  EXPERIENCE = 'EXPERIENCE',
}