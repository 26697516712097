import { unwrapResult } from '@reduxjs/toolkit';
import { useCallback, useState } from 'react';
import { StockFilter } from 'src/enums/fast-moving-products';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import {
  CalendarParamsState,
  FastMovingProductsData,
  FastMovingProductsPayload,
  SalesTimelineData
} from 'src/redux/slices/fast-moving-products';

const {
  getFastMovingProductsThunk,
  getSoldTimeLineThunk,
  actions: fastMovingActions,
  selectors: fastMovingSelectors
} = slices.fastMovingProducts;

export const useFastMovingProducts = () => {
  const dispatch = useAppDispatch();

  const soldTimeLineParams = useAppSelector(
    fastMovingSelectors?.selectCalendarParams
  );

  const [fastMovingProductData, setFastMovingProductData] = useState<
    FastMovingProductsData[]
  >();
  const [soldTimeData, setIsSoldTimeData] = useState<SalesTimelineData>();
  const [dataCopy, setDataCopy] = useState<FastMovingProductsData[]>([]);
  const [fastMovingProdParams, setFastMovingProdParams] = useState<
    FastMovingProductsPayload
  >({ status_filter: StockFilter.All }); // Default value
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getFastMovingProducts = useCallback(
    async (params: FastMovingProductsPayload) => {
      //remove status filter
      // eslint-disable-next-line no-unused-vars
      const { status_filter, ...newParams } = params;
      try {
        setIsLoading(true);
        const response = unwrapResult(
          await dispatch(getFastMovingProductsThunk(newParams))
        );
        if (response?.success) {
          const { data } = response?.originalData;
          setIsLoading(false);
          setFastMovingProductData(data);
          setDataCopy(data || []); // TODO: original copy
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  const getFastMovingSoldTimeline = useCallback(
    async (params: CalendarParamsState) => {
      try {
        setIsLoading(true);
        const response = unwrapResult(
          await dispatch(getSoldTimeLineThunk(params))
        );
        if (response?.success) {
          const { data } = response?.originalData;
          setIsLoading(false);
          setIsSoldTimeData(data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  const getSoldTimelineParams = (params: CalendarParamsState) => {
    dispatch(fastMovingActions?.calendarParamsActions(params));
  };

  return {
    isLoading,
    fastMovingProductData,
    dataCopy,
    fastMovingProdParams,
    soldTimeData,
    soldTimeLineParams,

    setFastMovingProductData,
    getFastMovingProducts,
    getFastMovingSoldTimeline,
    setFastMovingProdParams,
    getSoldTimelineParams
  };
};
