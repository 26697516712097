import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef
} from 'react';
import { debounce } from 'lodash';
import { useAlertGlobal, useIsMount, useSnackBar } from 'src/hooks';
import clsx from 'clsx';
import {
  FormControl,
  Grid,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import html2canvas from 'html2canvas';
import TemplatesModules from '../hooks/TemplatesModule';

import TemplatesModule from '../hooks/TemplatesModule';

import { LoaderBar, Page } from 'src/components';
import {
  QuotationFooterButtons,
  QuotationFooterNote,
  QuotationHeader,
  QuotationMainTable,
  QuotationTemplates
} from '../components';
import { initialCategory, initialQuotationState } from '../constants';
import {
  CustomInputEvent,
  GetQuotationProductsRequest,
  NewTemplateDetails,
  ProductAndQuantity,
  ProductsWithSRP,
  Quotation,
  QuotationCategory,
  QuotationProduct,
  TemplateList
} from 'src/types';
import { cloneDeep, isEmpty } from 'lodash';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { batch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useLocation, useNavigate } from 'react-router-dom';
import TemplateForm from '../components/TemplatesForm';
import useUserInfo from 'src/hooks/user/use-user-info';
import { CategoriesEnum } from 'src/enums';
import { QuotationPrintModal } from '../components/QuotationPrintModal';
import { PCWorthBranchesEnum } from 'src/enums/branches';
import { srpPercentageToNumber, timeStampNow } from 'src/utils';
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import BookmarkOutlinedIcon from '@material-ui/icons/BookmarkOutlined';
import { useBranchInfo } from 'src/hooks/branch/use-branch-info';
import { srpOptions } from 'src/constants';
import { SRPOption } from 'src/types/srp-options';

type CheckAvailabilityOption = {
  returnItems?: boolean;
  bypassWarning?: boolean;
};

const width = 1600;

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    maxWidth: width,
    minWidth: width,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  canvas: {
    padding: theme.spacing(3),
    backgroundSize: '80px 70px',
    backgroundRepeat: 'repeat',
    background:
      'linear-gradient(rgba(255,255,255,.95), rgba(255,255,255,.95)), url("/static/images/avatars/pcworth_logo.png")'
  },
  customerInfoBox: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  printButton: {
    marginBottom: 14
  },
  clearAllBtn: {
    marginTop: 20
  },
  container: {
    width: width
  },
  branchContainer: {
    marginTop: '3px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  label: {
    fontFamily: 'Roboto',
    color: '#263238',
    fontWeight: 500,
    fontSize: 14
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const {
  actions: quotationActions,
  selectors: quotationSelectors
} = slices.quotations;

const pcBuildReq = [
  CategoriesEnum.Motherboard,
  CategoriesEnum.CPU,
  CategoriesEnum.PSU,
  CategoriesEnum.Casing,
  CategoriesEnum.RAM
];

const CreateQuotationView = () => {
  const isFirstMount = useIsMount();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const alertGlobal = useAlertGlobal();
  const location: any = useLocation();
  const navigate = useNavigate();
  const { onGetTemplateList } = TemplatesModules();
  const {
    getUserDetails,
    userDetails,
    favoriteBranch,
    userBranchOptions,
    userBranchIdDefaultOption,
    setFavoriteBranch
  } = useUserInfo();
  const { branchIDToName } = useBranchInfo();

  const [selectedBranchID, setSelectedBranchID] = useState<number | undefined>(
    favoriteBranch ?? userBranchIdDefaultOption
  );

  const [tempIdVal, setTempIdVal] = useState<string>('');

  const [quotationFromStateLocation, setQuotationFromStateLocation] = useState<
    Quotation[]
  >(location.state);
  const [openPrintModal, setOpenPrintModal] = useState<boolean>(false);

  const categoryOptions = useAppSelector(
    quotationSelectors.selectQuotationCategories
  );

  const [showSRP, setShowSRP] = useState<boolean>(false);
  const [hasCompatibility] = useState<boolean>(false);
  const [showCleanSrpQuote, setShowCleanSrpQuote] = useState<boolean>(false);
  const [items, setItems] = useState<Quotation[]>(
    quotationFromStateLocation || initialQuotationState || []
  );

  //TODO this is it the product option
  const [productOptions, setProductOptions] = useState<QuotationProduct[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [categoryProductLoading, setCategoryProductLoading] = useState<
    string
  >();
  const [loadedCategoryItems, setLoadedCategoryItems] = useState<
    QuotationCategory[]
  >(initialCategory);
  const [productsWithSRP, setproductsWithSRP] = useState<ProductsWithSRP>([]);
  const [newSrpValue, setNewSrpValue] = useState<SRPOption>(
    srpOptions.promoPrice
  );
  const [srpValue, setSrpValue] = useState<SRPOption>(srpOptions.promoPrice);
  const [totalSRP, setTotalSRP] = useState<number>(0);
  const [isSrpLoading, setIsSrpLoading] = useState<boolean>(false);

  const computeSrpPayload = useMemo(() => {
    const validProducts = items.filter(
      (i) => i?.product?.product_name && i?.quantity > 0
    );
    const payload: ProductAndQuantity[] = validProducts.map((i) => ({
      product_id: i?.product?.product_id || undefined,
      quantity: i?.quantity || 0,
      product_name: i?.product?.product_name || '',
      retail_price: i?.product?.retail_price || 0,
      custom: i?.custom || false
    }));

    return payload;
  }, [items]);

  const prevComputeSrpPayload = useRef<ProductAndQuantity[]>(computeSrpPayload);
  const prevSrpValue = useRef<SRPOption>(srpValue);

  // const productIds = useMemo(() => {
  //   const prodWithIds = items.filter((i) => i?.product?.product_id);
  //   return prodWithIds.map((i) => i.product.product_id);
  // }, [items]);

  // Coded this way for ts error fix
  // const formattedIds = useMemo(() => {
  //   let validIds: number[] = [];

  //   productIds.forEach((i) => {
  //     if (i !== undefined) {
  //       validIds.push(i);
  //     }
  //   });

  //   return validIds;
  // }, [productIds]);

  const hasValidProduct = useMemo(() => !!computeSrpPayload[0], [
    computeSrpPayload
  ]);

  const getSrpComputation = useCallback(async () => {
    setIsSrpLoading(true);

    if (items?.[0]) {
      const quotationSRPResponse = unwrapResult(
        await dispatch(
          quotationActions.onComputeSrpThunk({
            products: computeSrpPayload,
            srp_value: srpPercentageToNumber(newSrpValue.value)
          })
        )
      )?.originalData;

      if (quotationSRPResponse.success) {
        setTotalSRP(quotationSRPResponse?.total_amount_srp);
        setproductsWithSRP(quotationSRPResponse?.products_with_srp);
        setIsSrpLoading(false);
        return;
      }

      snackBar.show({
        useSound: true,
        severity: 'error',
        message: 'Error occured. Please try again later'
      });
      console.error(quotationSRPResponse?.errors);
      setIsSrpLoading(false);
      return;
    }

    snackBar.show({
      useSound: true,
      severity: 'error',
      message: 'No Products selected'
    });
    setIsSrpLoading(false);
  }, [computeSrpPayload, dispatch, items, newSrpValue, snackBar]);

  const branchInfo = useMemo(() => {
    const filteredValue = userDetails?.branches?.find(
      (branch) => branch.id === selectedBranchID
    );
    return filteredValue;
  }, [selectedBranchID, userDetails]);

  const getCategories = useCallback(async () => {
    const response = unwrapResult(
      await dispatch(quotationActions.getQuotationsCategoriesThunk())
    );
    return response;
  }, [dispatch]);

  // retain function and comment out instances for easy revert
  //   const getInitialProductOptions = async (branchId?: number) => {
  //     await getCategories();

  //     //TODO: we canj only use multibranch in production?
  //     // const branch_id = multiBranchFeat ? undefined : selectedBranchID;

  //     // quotationItems are priority
  //     for (const quotationItem of initialQuotationState) {
  //       // await sleep(500); // Assuming sleep is defined as above
  //       if (quotationItem?.product?.category_id) {
  //         setCategoryProductLoading(quotationItem?.product?.category_name);
  //         const response = await getProductOptions({
  //           branch_id: branchId,
  //           category_id: quotationItem.product.category_id,
  //           limit: 200
  //         });
  //         if (response?.originalData?.data) {
  //           setProductOptionsUniq(response?.originalData?.data);
  //         }
  //         setCategoryProductLoading('');
  //       }
  //     }
  //   };

  const getProductOptions = useCallback(
    async (payload: GetQuotationProductsRequest) => {
      const response = unwrapResult(
        await dispatch(
          quotationActions.getQuotationsProductsThunk({
            ...payload,
            ...(showSRP
              ? { srp_value: srpPercentageToNumber(srpValue.value) }
              : {})
          })
        )
      );
      return response;
    },
    [dispatch, showSRP, srpValue]
  );

  const getInitialProductOptionsV2 = useCallback(
    async (branchId?: number) => {
      await getCategories();
      let categIDs: number[] = [];
      for (const quotationItem of initialQuotationState) {
        if (quotationItem?.product?.category_id) {
          categIDs.push(quotationItem?.product?.category_id);
        }
      }

      const response = await getProductOptions({
        branch_id: branchId,
        category_ids: categIDs,
        limit: 200
      });

      if (response?.success && response?.originalData?.data?.[0]) {
        const totalPage = response.originalData.meta?.last_page ?? 0;

        let additionalData = [];
        if (totalPage > 1) {
          const responseV2 = await Promise.all(
            Array.from({ length: totalPage - 1 }, (_, i) =>
              getProductOptions({
                branch_id: branchId,
                category_ids: categIDs,
                limit: 200,
                page: i + 2 // i starts at 0, so page should be i + 2
              })
            )
          );

          additionalData = responseV2.reduce(
            (accumulator: any, current: any) => [
              ...accumulator,
              ...current.originalData.data
            ],
            []
          );
        }

        setProductOptions([...response?.originalData?.data, ...additionalData]);
      } else {
        console.error('Failed to get initial product options');
      }
    },
    [getCategories, getProductOptions]
  );

  const applySrp = useCallback(() => {
    getSrpComputation();
    setSrpValue(newSrpValue);
    newSrpValue.value === '0%'
      ? onToggleShowSRPSwitch(false)
      : onToggleShowSRPSwitch(true);
  }, [getSrpComputation, newSrpValue]);

  //TODO: delete on change selected branch
  const setProductOptionsUniq = useCallback(
    async (newProducts?: QuotationProduct[]) => {
      if (newProducts && newProducts?.length > 0) {
        setProductOptions((currentProducts) => {
          // Create a new Map, using product_id as the key for each product
          const productMap = new Map(
            currentProducts.map((product) => [product.product_id, product])
          );

          // Iterate over the new products and add them to the map if they don't exist already
          newProducts.forEach((product) => {
            if (!productMap.has(product.product_id)) {
              productMap.set(product.product_id, product);
            }
          });

          // Convert the map back to an array
          return Array.from(productMap.values());
        });
      }
    },
    [] // Add dependencies if needed
  );

  const onChangeQuantity = useCallback(
    (event: CustomInputEvent, index: number) => {
      const { value } = event.target;
      setItems((prev) =>
        prev?.map((item, i) =>
          index !== i ? item : { ...item, quantity: +value }
        )
      );
    },
    []
  );

  const onCustomProductInputChange = useCallback(
    (e: CustomInputEvent, index: number) => {
      const { value } = e.target;
      // Coded like this for performance.
      // Scene 1: If input on product is custom. remove product_id

      setItems((prev) =>
        prev?.map((item, i) =>
          index !== i
            ? item
            : {
                ...item,
                product: {
                  ...item?.product,
                  // refer to scene 1.
                  product_id: undefined,
                  product_name: value
                }
              }
        )
      );
    },
    []
  );

  const onChangeProduct = useCallback(
    (newProduct: QuotationCategory | null, index: number) => {
      // Coded like this for performance.
      // Scene 1: adjust existing category, category_id based on product.
      // If newProduct is null or undefined
      if (!newProduct) {
        setItems((prev) =>
          prev?.map((item, i) =>
            index !== i
              ? item
              : {
                  ...item,
                  product: {
                    ...item?.product,
                    product_id: undefined,
                    product_name: undefined,
                    retail_price: 0
                  }
                }
          )
        );
        return;
      }

      setItems((prev) =>
        prev?.map((item, i) =>
          index !== i
            ? item
            : {
                ...item,
                product: {
                  ...item?.product,
                  ...newProduct
                }
              }
        )
      );
    },
    []
  );

  const onCustomPriceInputChange = useCallback(
    (e: CustomInputEvent, index: number) => {
      const { value } = e.target;
      // Coded like this for performance.
      setItems((prev) =>
        prev?.map((item, i) =>
          index !== i
            ? item
            : {
                ...item,
                product: {
                  ...item?.product,
                  // refer to scene 1.
                  retail_price: +value
                }
              }
        )
      );
    },
    []
  );

  const onCustomCategInputChange = useCallback(
    (e: CustomInputEvent, index: number) => {
      const { value } = e.target;
      // Coded like this for performance.
      // Scene 1: If input on category is custom. remove product_id and category_id

      setItems((prev) =>
        prev?.map((item, i) =>
          index !== i
            ? item
            : {
                ...item,
                product: {
                  ...item?.product,
                  // refer to scene 1.
                  product_id: undefined,
                  category_id: undefined,
                  category_name: value
                }
              }
        )
      );
    },
    []
  );

  const onChangeCategory = useCallback(
    async (
      val: QuotationCategory | null,
      index: number,
      branch_id?: number
    ) => {
      // Coded like this for performance.
      // Scene 1: remove existing product_name, product_id, amount if existing not equal category_id
      const category_id = val?.category_id;
      const category_name = val?.category_name;
      //TODO: we can only use multibracnh in production?
      // const branch_id = multiBranchFeat ? undefined : selectedBranchID;

      const isCategoryExist = loadedCategoryItems.find(
        (x) => x.category_id === category_id
      );

      if (!isCategoryExist || isCategoryExist === undefined) {
        setCategoryProductLoading(category_name);
        //meaing category is already loaded
        setLoadedCategoryItems((prev) => [
          ...prev,
          {
            category_id: category_id,
            category_name: category_name
          }
        ]);

        if (category_id && branch_id) {
          const response = await getProductOptions({
            branch_id,
            category_ids: [category_id],
            limit: 200
          });

          if (response?.success && response?.originalData?.data?.[0]) {
            const totalPage = response.originalData.meta?.last_page ?? 0;

            let additionalData = [];
            if (totalPage > 1) {
              const responseV2 = await Promise.all(
                Array.from({ length: totalPage - 1 }, (_, i) =>
                  getProductOptions({
                    branch_id,
                    category_ids: [category_id],
                    limit: 200,
                    page: i + 2
                  })
                )
              );

              additionalData = responseV2.reduce(
                (accumulator: any, current: any) => {
                  let updatedAccumulator = accumulator;
                  updatedAccumulator = [
                    ...updatedAccumulator,
                    ...current.originalData.data
                  ];
                  return updatedAccumulator;
                },
                []
              );
            }

            // handle new category data response
            setProductOptionsUniq([
              ...response?.originalData?.data,
              ...additionalData
            ]);
            setCategoryProductLoading('');
          } else {
            console.error('Failed to get onChangeCategory');
            setCategoryProductLoading('');
          }
        }
      }

      setItems((prev) =>
        prev?.map((item, i) =>
          index !== i
            ? item
            : {
                ...item,
                product: {
                  ...item?.product,
                  // refer to scene 1.
                  product_id:
                    item?.product?.category_id === category_id
                      ? item?.product?.product_id
                      : undefined,
                  product_name:
                    item?.product?.category_id === category_id
                      ? item?.product?.product_name
                      : undefined,
                  retail_price:
                    item?.product?.category_id === category_id
                      ? item?.product?.retail_price
                      : 0,
                  category_id,
                  category_name
                }
              }
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadedCategoryItems]
  );

  const onRemoveItem = useCallback((index: number) => {
    setItems((prev) => {
      const clone = cloneDeep(prev);
      clone.splice(index, 1);
      return clone;
    });
  }, []);

  // returns proper stuff and adds warnings. on Items
  const itemsOrganizer: () => Quotation[] = () => {
    // clones to avoid mutation.
    const clonedItems = cloneDeep(items);
    // clear previous warnings
    clonedItems?.map((x) => (x.warning_note = undefined));
    // checks if category is empty
    const warningsSetter = clonedItems?.map((x) => {
      let item = x;

      if (!x?.product?.retail_price) {
        item.product.retail_price = 0;
      }
      if (!x?.product?.category_name) {
        item.warning_note = 'Category Needed.';
      }
      return item;
    });
    const nonEmptyProduct = warningsSetter?.filter(
      (x) => x?.product?.product_name
    );
    const nonDuplicate = nonEmptyProduct.reduce((acc: any, e: any) => {
      const found: any = acc.find(
        (x: any) =>
          e?.product?.product_id === x?.product?.product_id &&
          e?.product?.product_name === x?.product?.product_name &&
          e?.product?.retail_price === x?.product?.retail_price
      );
      found ? (found.quantity += e.quantity) : acc.push(e);
      return acc;
    }, []);
    return nonDuplicate;
  };

  const onCheckAvailability = async (
    checkAvailabilityOption?: CheckAvailabilityOption
  ) => {
    const newItems = itemsOrganizer();

    setIsLoading(true);
    const checkerRes = unwrapResult(
      await dispatch(
        quotationActions.checkQuotationAvailabilityThunk({
          items: newItems,
          branch_id: selectedBranchID || userBranchIdDefaultOption
        })
      ).finally(() => setIsLoading(false))
    );

    if (checkerRes?.success) {
      const resItems = checkerRes?.originalData?.quote_items || [];
      const hasWarning = resItems?.filter((x) => x.warning_note);

      if (hasWarning?.length) {
        snackBar.show({
          severity: 'error',
          message:
            'Some item(s) might be out of stock. Please check availability.'
        });
      } else {
        snackBar.show({
          severity: 'success',
          message: 'All items available'
        });
      }
      setItems(resItems);

      // If we are waiting for response from another function
      if (checkAvailabilityOption?.returnItems) {
        return resItems;
      }

      return;
    }

    snackBar.show({
      severity: 'error',
      message: 'Error checking availability. Please try again later.'
    });
    setItems(newItems);
  };

  const onAddCustomer = async () => {
    const quoteItems = await onCheckAvailability({
      returnItems: true,
      bypassWarning: true
    });

    if (!quoteItems || quoteItems?.length <= 0) {
      snackBar.show({
        severity: 'error',
        message: 'Error structuring quotation and checking of availability',
        useSound: true
      });
      return;
    }

    const hasWarning = quoteItems?.find((x) => x.warning_note);
    if (hasWarning) {
      snackBar.show({
        severity: 'error',
        message:
          'Some item(s) might be out of stock. Please check availability.',
        useSound: true
      });
      return;
    }

    const newTemplateProducts = quotationProductTemplateFormater(quoteItems);
    const isNewTemplatePCBuild = isQuotationPCBuild(newTemplateProducts);
    const finalNewTemplate: NewTemplateDetails = {
      products: newTemplateProducts,
      name: `AddCustomer-${timeStampNow()}`,
      pc_build: isNewTemplatePCBuild ? 1 : 0,
      boost: isBoost
    };

    // Finally save new template
    const createQuoteResponse = await createTemplate(
      finalNewTemplate,
      selectedBranchID
    );

    const quotationTemplateId =
      createQuoteResponse?.originalData?.data?.quotation_id;

    if (quotationTemplateId) {
      snackBar.show({
        severity: 'success',
        message: 'Template created successfully. Now you can add customer',
        useSound: true
      });
      navigate(
        `/app/customer-taptap/add?internal_quotation_id=${quotationTemplateId}`
      );
      return;
    }

    snackBar.show({
      severity: 'error',
      message: 'Error creating template',
      useSound: true
    });

    // createTemplate({})
    // TODO: Generate Template ID.
  };

  const onScreenShot = async () => {
    const templateQuotation: any = document.querySelector(
      '#quotation-template'
    );
    templateQuotation.style.setProperty('display', 'none');

    let actionButtons: any = document.querySelectorAll(
      '#quotation-action-cell'
    );
    actionButtons?.forEach((ab: any) =>
      ab.style.setProperty('display', 'none')
    );

    const documentQuotation: any = document.querySelector('#quotation-canvas');

    html2canvas(documentQuotation, { useCORS: true, allowTaint: true }).then(
      (canvas: any) => {
        canvas.toBlob(
          (blob: any) =>
            navigator.clipboard
              // @ts-ignore
              // eslint-disable-next-line no-undef
              ?.write([new ClipboardItem({ 'image/png': blob })])
              ?.then(() => {
                snackBar.show({
                  severity: 'success',
                  message: 'Image copied to clipboard'
                });
                templateQuotation.style.setproperty('display', 'block');
                actionButtons?.forEach((ab: any) =>
                  ab.style.setProperty('display', 'block')
                );
              })
              .catch(),
          'image/png',
          99
        );
      }
    );

    await onCheckAvailability();
  };

  const onAddInStockRow = () => {
    setItems((prev) => {
      const cloned = cloneDeep(prev);
      cloned.push({ quantity: 1, product: {} });
      return cloned;
    });
  };

  const onAddCustomRow = () => {
    setItems((prev) => {
      const cloned = cloneDeep(prev);
      cloned.push({ quantity: 1, product: {}, custom: true });
      return cloned;
    });
  };

  const onResetNew = (callbackOnYes?: () => void, subtitle?: string) => {
    alertGlobal.show({
      title: 'RESET TO NEW',
      subtitle: subtitle || 'Are you sure you want to reset to new?',
      buttons: [
        {
          text: 'Yes',
          onClick: () => {
            setItems(initialQuotationState);
            setSelectedTemplate(initialTemplate);
            alertGlobal.hide();
          },
          color: 'secondary'
        },
        {
          text: 'Cancel',
          onClick: () => alertGlobal.hide()
        }
      ]
    });
    return;
  };

  const onRemoveEmptyFields = () => {
    alertGlobal.show({
      title: 'REMOVE EMPTY FIELDS',
      subtitle: 'Are you sure you want to remove empty fields?',
      buttons: [
        {
          text: 'Yes',
          onClick: () => {
            setItems((prev) => prev?.filter((x) => x?.product?.product_name));
            alertGlobal.hide();
          },
          color: 'secondary'
        },
        {
          text: 'Cancel',
          onClick: () => alertGlobal.hide()
        }
      ]
    });
    return;
  };

  const onToggleShowSRPSwitch = (v: boolean) => {
    setShowSRP(v);
    if (!v) {
      setShowCleanSrpQuote(false);
    }
  };

  const onToggleShowCleanSRPSwitch = (v: boolean) => {
    setShowCleanSrpQuote(v);
  };

  // eslint-disable-next-line no-unused-vars
  const onToggleHasCompatibility = (_v: boolean) => {};

  /**
   * Templates
   */

  const {
    selectedTemplate,
    newTemplate,
    showTemplateForm,
    initialTemplate,
    onGetTemplateDetails,
    setSelectedTemplate,
    setNewTemplate,
    onSelectTemplate,
    setShowTemplateForm,
    createTemplate,
    updateTemplate,
    deleteTemplate,
    isPCBuild,
    setIsPCBuild,
    isBoost,
    setIsBoost
  } = TemplatesModule();

  const quotationProductTemplateFormater = (itemsArg: Quotation[]) => {
    const products = itemsArg?.filter((x) => {
      if (x?.product?.product_name) {
        return {
          custom: x.product.custom,
          quantity: x.quantity,
          product: {
            id: x.product.product_id,
            product_id: x.product.product_id,
            retail_price: x.product.retail_price,
            category_id: x.product.category_id,
            category_name: x.product.category_name
          }
        };
      }
    });

    return products;
  };

  const onSaveTemplate = () => {
    const products = quotationProductTemplateFormater(items);

    const templateHandler = selectedTemplate ?? { name: '' };
    setNewTemplate({ ...templateHandler, products });
    setShowTemplateForm(true);
  };

  const onHandleTemplateChange = async (template: TemplateList | null) => {
    setQuotationFromStateLocation([]);
    onSelectTemplate(template);
  };

  const onHandleTemplateFormClose = (action: string, template_name: string) => {
    setQuotationFromStateLocation([]);
    const finalTemplate = {
      ...newTemplate,
      name: template_name,
      pc_build: isPCBuild,
      boost: isBoost
    };
    switch (action) {
      case 'create':
        createTemplate(finalTemplate, selectedBranchID);
        break;
      case 'update':
        updateTemplate(finalTemplate, selectedBranchID);
        break;
    }
  };

  const onDeleteTemplate = () => {
    alertGlobal.show({
      title: 'RESET TO NEW',
      subtitle: 'Are you sure you want to delete this template?',
      buttons: [
        {
          text: 'Yes',
          onClick: () => {
            setQuotationFromStateLocation([]);
            deleteTemplate(selectedBranchID);
            alertGlobal.hide();
          },
          color: 'secondary'
        },
        {
          text: 'Cancel',
          onClick: () => alertGlobal.hide()
        }
      ]
    });
  };

  //If not priority category is not loaded in template fire this function
  const onLoadCategorySelectedTemplate = async () => {
    // Check if selectedTemplate or initialQuotationState is undefined
    if (!selectedTemplate || !initialQuotationState) {
      console.error('selectedTemplate or initialQuotationState is undefined');
      return;
    }
    const filterSelectedTemplate = selectedTemplate.products?.filter(
      (item, index) =>
        item?.product?.category_id !==
        initialQuotationState[index]?.product?.category_id
    );
    // Check if filterSelectedTemplate is undefined or empty
    if (!filterSelectedTemplate || filterSelectedTemplate.length === 0) {
      console.warn('No products to load categories for');
      return;
    }
    // Iterate over filtered products
    for (const filterItem of filterSelectedTemplate) {
      // Check if filterItem or its product property is undefined
      if (!filterItem || !filterItem.product) {
        console.error('Invalid filterItem or filterItem.product:', filterItem);
        continue; // Skip to the next iteration
      }
      setCategoryProductLoading(filterItem.product.category_name);
      setLoadedCategoryItems((prev) => [
        ...prev,
        {
          category_id: filterItem.product.category_id,
          category_name: filterItem.product.category_name
        }
      ]);
      try {
        const response = await getProductOptions({
          category_id: filterItem.product.category_id,
          branch_id: selectedBranchID,
          limit: 200
        });
        if (response?.originalData?.data) {
          // Update product options and loading state
          setProductOptionsUniq(response.originalData.data);
          setCategoryProductLoading('');
        }
      } catch (error) {
        console.error('Error fetching product options:', error);
      }
    }
  };

  const isQuotationPCBuild = (quotationItemsArg?: Quotation[]) => {
    // Check if pcBuildReq is fulfilled
    const itemsThatPassedPCBuild = quotationItemsArg?.filter(
      (x) =>
        x?.product?.category_id &&
        pcBuildReq.includes(x?.product?.category_id) &&
        x?.product?.product_id
    );

    // meaning 3 items was fulfilled. 3 only because sometimes casing has built in PSU
    if (itemsThatPassedPCBuild && itemsThatPassedPCBuild?.length > 3) {
      const hasMobo = itemsThatPassedPCBuild?.find(
        (item) => item?.product?.category_id === CategoriesEnum.Motherboard
      );
      const hasCPU = itemsThatPassedPCBuild?.find(
        (item) => item?.product?.category_id === CategoriesEnum.CPU
      );
      const hasCasing = itemsThatPassedPCBuild?.find(
        (item) => item?.product?.category_id === CategoriesEnum.Casing
      );
      const hasRAM = itemsThatPassedPCBuild?.find(
        (item) => item?.product?.category_id === CategoriesEnum.RAM
      );

      const hasPSUInCateg = itemsThatPassedPCBuild?.find(
        (item) => item?.product?.category_id === CategoriesEnum.PSU
      );
      const hasPSUInCasing = hasCasing?.product?.product_name?.includes('PSU');

      if (
        hasMobo &&
        hasCPU &&
        hasCasing &&
        hasRAM &&
        (hasPSUInCateg || hasPSUInCasing)
      ) {
        return true;
      }
      return false;
    }
  };

  const debouncedIsPCBuildTrigger = debounce(() => {
    const isPCBuildAsk = isQuotationPCBuild(items);

    if (isPCBuildAsk) {
      setIsPCBuild(1);
    }

    if (isPCBuild) {
      setIsPCBuild(0);
    }
  }, 2000);

  const onChangeBranch = (e: any) => {
    const branchIdVal = e.target.value;

    setSelectedBranchID(branchIdVal);

    getInitialProductOptionsV2(branchIdVal);
    setItems(initialQuotationState);
    onGetTemplateList('', branchIdVal);
    // lets empty some stuff to make a fresh quotation inputs
    onSelectTemplate(null);
    setProductOptions([]);
    setQuotationFromStateLocation([]);
    setLoadedCategoryItems([]);

    // Reset SRP
    onToggleShowSRPSwitch(false);
    setSrpValue(srpOptions.promoPrice);
    setNewSrpValue(srpOptions.promoPrice);
  };

  // TODO: Maybe this should be async and just return response of template create?
  // That way we can show a snackbar message if it was successful or not and lessen passing of props?

  /**
   * Fuck This shit. Shitty code. OPLAN PIGROLAC / FEEDING PROGRAM!!!
   */
  const onGenerateAddToCartLink = async () => {
    // Check Branch. Only allowed on Earnshaw
    if (selectedBranchID !== PCWorthBranchesEnum.Earnshaw) {
      snackBar.show({
        severity: 'error',
        message:
          'Generating "add to cart" link is only allowed on earnshaw branch',
        useSound: true
      });
      return;
    }

    // Fix structure of quotation and check availability of items
    const newArrangedItems = await onCheckAvailability({ returnItems: true });
    if (!newArrangedItems || newArrangedItems?.length <= 0) {
      snackBar.show({
        severity: 'error',
        message: 'Error structuring quotation and checking of availability',
        useSound: true
      });
      return;
    }

    // Clear quotation that is passed from state?
    setQuotationFromStateLocation([]);

    const newTemplateProducts = quotationProductTemplateFormater(
      newArrangedItems
    );

    const isNewTemplatePCBuild = isQuotationPCBuild(newTemplateProducts);

    const finalNewTemplate: NewTemplateDetails = {
      products: newTemplateProducts,
      name: `AddToCartLink-${timeStampNow()}`,
      pc_build: isNewTemplatePCBuild ? 1 : 0,
      boost: isBoost
    };

    // Finally save new template
    const createQuoteResponse = await createTemplate(
      finalNewTemplate,
      selectedBranchID
    );

    return createQuoteResponse;
  };

  useEffect(() => {
    if (!isEmpty(quotationFromStateLocation) && isEmpty(selectedTemplate)) {
      return;
    }
    if (selectedTemplate) {
      setItems(selectedTemplate?.products ?? []);
      onLoadCategorySelectedTemplate();
    } else {
      setItems(initialQuotationState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplate]);

  useEffect(() => {
    if (isFirstMount) {
      batch(() => {
        getInitialProductOptionsV2(selectedBranchID);
        getUserDetails();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstMount]);

  const onEnterTemplateID = useCallback((e: any) => {
    if (e.key === 'Enter') {
      onGetTemplateDetails(e.target.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Reflect template data on buttons
    if (selectedTemplate) {
      selectedTemplate.pc_build ? setIsPCBuild(1) : setIsPCBuild(0);
      selectedTemplate.boost ? setIsBoost(1) : setIsBoost(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplate]);

  useEffect(() => {
    if (items) {
      debouncedIsPCBuildTrigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    setTempIdVal(String(selectedTemplate?.id || ''));
  }, [selectedTemplate]);

  // Recompute SRP when quotation data changes and showSRP is enabled
  useEffect(() => {
    if (showSRP && computeSrpPayload !== prevComputeSrpPayload.current) {
      getSrpComputation();
      prevComputeSrpPayload.current = computeSrpPayload;
    }
  }, [computeSrpPayload, getSrpComputation, showSRP]);

  useEffect(() => {
    if (srpValue !== prevSrpValue.current) {
      getInitialProductOptionsV2(selectedBranchID);
      prevSrpValue.current = srpValue;
    }
  }, [
    computeSrpPayload,
    getInitialProductOptionsV2,
    getSrpComputation,
    selectedBranchID,
    showSRP,
    srpValue
  ]);

  return (
    <Page title="Quotation" className={clsx(classes.root)}>
      <LoaderBar isLoading={isLoading} />
      {/* <QuotationCustomerInfo /> */}
      <div className={classes.canvas} id="quotation-canvas">
        <QuotationHeader
          templateId={selectedTemplate?.id}
          hasTemplate={!!selectedTemplate}
          branchInfo={branchInfo}
        />
        <Grid
          container
          style={{ margin: '20px 0px' }}
          spacing={2}
          alignItems="center"
          direction="row"
        >
          <Grid item xs={2} md={2}>
            <FormControl fullWidth>
              <Typography
                style={{
                  fontFamily: 'Roboto',
                  color: '#263238',
                  fontWeight: 500,
                  fontSize: 14
                }}
              >
                Template ID
              </Typography>
              <TextField
                disabled={!isEmpty(categoryProductLoading)}
                value={tempIdVal}
                placeholder="Enter template ID"
                onChange={(e) => setTempIdVal(e.target.value)}
                onKeyDown={onEnterTemplateID}
              />
            </FormControl>
          </Grid>
          <Grid item id="quotation-template" xs={2} md={2}>
            <QuotationTemplates
              selectedTemplate={selectedTemplate ?? null}
              onTemplateChange={(template) => onHandleTemplateChange(template)}
              branchId={selectedBranchID}
              isCategoryLoading={!isEmpty(categoryProductLoading)}
            />
          </Grid>

          <Grid item>
            <label className={classes.label}>Branch</label>
            <div className={classes.branchContainer}>
              <FormControl required size="small" style={{ width: 200 }}>
                <Select
                  defaultValue={favoriteBranch ?? userBranchIdDefaultOption}
                  disabled={isLoading || !isEmpty(categoryProductLoading)}
                  value={selectedBranchID}
                  variant="standard"
                  label="Branch"
                  onChange={onChangeBranch}
                  renderValue={(value: any) => (
                    <Typography>{branchIDToName(value)}</Typography>
                  )}
                >
                  {userBranchOptions.map((b) => (
                    <MenuItem
                      key={b.value}
                      value={b.value}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      {b.name}
                      {b.value === favoriteBranch ? (
                        <BookmarkOutlinedIcon
                          // unfavorite branch
                          onClick={(e) => {
                            e.stopPropagation();
                            setFavoriteBranch(null);
                          }}
                        />
                      ) : (
                        <BookmarkBorderOutlinedIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            setFavoriteBranch(b.value);
                          }}
                        />
                      )}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {isLoading && <LinearProgress color="primary" />}
          </Grid>

          <Grid item xs={4}>
            <label className={classes.label}>Customer Name</label>

            <div className={classes.branchContainer}>
              <TextField fullWidth />
            </div>
          </Grid>
        </Grid>
        <QuotationMainTable
          items={items}
          srpValue={srpValue}
          isSrpLoading={isSrpLoading}
          branchId={selectedBranchID}
          showSRP={showSRP}
          totalSRP={totalSRP}
          productsWithSRP={productsWithSRP}
          hasCompatibility={hasCompatibility}
          categoryOptions={categoryOptions}
          productOptions={productOptions}
          showCleanSrpQuote={showCleanSrpQuote}
          onChangeQuantity={onChangeQuantity}
          onChangeCategory={(val, index) =>
            onChangeCategory(val, index, selectedBranchID)
          }
          onCustomCategInputChange={onCustomCategInputChange}
          onCustomPriceInputChange={onCustomPriceInputChange}
          onCustomProductInputChange={onCustomProductInputChange}
          onChangeProduct={onChangeProduct}
          onRemoveItem={onRemoveItem}
          isCategoryLoading={!isEmpty(categoryProductLoading)}
        />
        <QuotationFooterNote />
        {categoryProductLoading ? (
          <div>
            <Typography variant="subtitle2">
              {categoryProductLoading}(s) Loading....
            </Typography>
            <LoaderBar isLoading={true} />
          </div>
        ) : null}
      </div>
      <QuotationFooterButtons
        newSrpValue={newSrpValue}
        hasValidProduct={hasValidProduct}
        setNewSrpValue={setNewSrpValue}
        onAddCustomer={onAddCustomer}
        applySrp={applySrp}
        onCheckAvailability={onCheckAvailability}
        srpValue={srpValue}
        onScreenShot={onScreenShot}
        onAddInStockRow={onAddInStockRow}
        onAddCustomRow={onAddCustomRow}
        onResetNew={onResetNew}
        onRemoveEmptyFields={onRemoveEmptyFields}
        showSRP={showSRP}
        template={selectedTemplate}
        hasCompatibility={hasCompatibility}
        showCleanSrpQuote={showCleanSrpQuote}
        onToggleHasCompatibility={onToggleHasCompatibility}
        onToggleShowCleanSRPSwitch={onToggleShowCleanSRPSwitch}
        onSaveTemplate={onSaveTemplate}
        onDeleteTemplate={onDeleteTemplate}
        isPCBuild={isPCBuild}
        setIsPCBuild={setIsPCBuild}
        isBoost={isBoost}
        setIsBoost={setIsBoost}
        isCategoryLoading={!isEmpty(categoryProductLoading)}
        onHandlePrintQuotation={() => setOpenPrintModal(true)}
        isSelectedTemplate={isEmpty(selectedTemplate)}
        onGenerateAddToCartLink={onGenerateAddToCartLink}
      />
      <TemplateForm
        template={selectedTemplate}
        isVisible={showTemplateForm}
        handleClose={() => setShowTemplateForm(false)}
        onProceed={(action, template_name) =>
          onHandleTemplateFormClose(action, template_name)
        }
      />

      <QuotationPrintModal
        isOpen={openPrintModal}
        quotationTemplateItems={selectedTemplate || undefined}
        showSRP={showSRP}
        branchInfo={branchInfo}
        userBranchOptions={userBranchOptions}
        onHandleClose={() => setOpenPrintModal(false)}
      />
    </Page>
  );
};

export default CreateQuotationView;
