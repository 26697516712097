// src/utils/installmentOptions.ts

import { colors } from 'src/constants';

export interface InstallmentOption {
  value: string;
  color: string;
  label: string;
}

export const getInstallmentOptions = (installmentData: {
  three_months: string;
  six_months: string;
  twelve_months: string;
}): InstallmentOption[] => [
  {
    value: installmentData.three_months,
    color: colors.primary,
    label: '(3 mos)',
  },
  {
    value: installmentData.six_months,
    color: colors.warning,
    label: '(6 mos)',
  },
  {
    value: installmentData.twelve_months,
    color: colors.success,
    label: '(12 mos)',
  }
];
