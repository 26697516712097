import React, { FC, useCallback, useState } from 'react';
import PreviewImageDialog from 'src/components/dialogs/PreviewImage';
import { RMAColumn, RMAData } from 'src/redux/slices/rma/types';

interface Props {
  rmaData: RMAData;
  columnWithImgs: RMAColumn;
}

export const RMAImages: FC<Props> = ({ rmaData, columnWithImgs }) => {
  const columnImgs: any[] = rmaData[columnWithImgs.field] || [];
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const openPreview = useCallback((imgUrl: string) => {
    setSelectedImage(imgUrl);
    setIsPreviewOpen(true);
  }, []);

  return (
    <>
      <div className="image-grid">
        {columnImgs.slice(-3).map((img, index) => (
          <img
            key={index}
            src={img?.file_url}
            alt={`img_${index + 1}`}
            className="thumbnail"
            onClick={() => openPreview(img?.file_url)}
            style={{
              cursor: 'pointer',
              width: 47,
              height: 47,
              objectFit: 'cover',
              borderRadius: '5px',
              marginRight: '5px'
            }}
          />
        ))}

        {columnImgs.length > 3 && (
          <span
            className="show-all-text"
            onClick={() => setIsGalleryOpen(true)}
            style={{
              cursor: 'pointer',
              color: 'blue',
              textDecoration: 'underline',
              marginLeft: '10px'
            }}
          >
            Show All
          </span>
        )}
      </div>

      <PreviewImageDialog
        images={columnImgs}
        isPreviewOpen={isPreviewOpen}
        isGalleryOpen={isGalleryOpen}
        selectedImage={selectedImage}
        onClosePreview={() => setIsPreviewOpen(false)}
        onCloseGallery={() => setIsGalleryOpen(false)}
        openPreview={openPreview}
      />
    </>
  );
};
