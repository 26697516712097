/* eslint-disable no-unused-vars */

import React, { useEffect, useMemo, useState } from 'react';
import { pickBy, isEqual, isEmpty } from 'lodash';
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Button,
  LinearProgress
} from '@material-ui/core';
import {
  DecisionDialog,
  DeleteDialog,
  LinkComponent,
  LoaderBar,
  Page
} from 'src/components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router';
import { SupabaseClientService } from 'src/services';
import {
  TapTapCustomerAuditHistory,
  TapTapCustomerData,
  TapTapCustomerPayment,
  TapTapImageFieldJson
} from 'src/redux/slices/taptap-customer/types';
import { Alert } from '@material-ui/lab';
import {
  onTapTapCustomerPaymentAddFromTransactionPayments,
  onTapTapDeleteCustomerPaymentsViaCustomerId,
  taptapCustomerDetailsFields,
  taptapFetchCustomerAuditService,
  taptapGetCustomerDetailsService,
  taptapStatuses
} from 'src/redux/slices/taptap-customer';
import { formatCurrency, getRandomUUIDv4Short } from 'src/utils';
import TapTapForm from './components/TapTapForm';
import { usePermissions, useSnackBar } from 'src/hooks';
import TapTapQuotationCard from './components/TapTapQuotationCard';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  AuditBase,
  GetTransactionDetailResponse,
  GetTransactionsViaTransactionNoResponse,
  TemplateDetails
} from 'src/types';
import { slices, useAppDispatch } from 'src/redux';
import { TapTapTransactionsCard } from './components/TapTapTransactionsCard';
import { colors } from 'src/constants';
import { TapTapPaymentsCard } from './components/TapTapPaymentsCard';
import { taptapCustomerField } from 'src/redux/slices/taptap-customer/constants/taptap-fields';
import { AuditHistory } from 'src/components/audit/AuditHistory';

const { actions: quotationActions } = slices.quotations;
const { actions: transactionActions } = slices.transaction;
const { actions: transactionPaymentActions } = slices.transactionPayments;

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100%',
    paddingBottom: 20,
    paddingTop: 20
  },
  contactCard: {
    marginTop: 20
  }
}));

interface FormValues {
  [key: string]: any; // could string, boolean, number, etc
}

const initialValues: FormValues = {
  first_name: '',
  last_name: '',
  contact_no: '',
  address: '',
  source: '',
  branch_released: '',
  courier: '',
  service_type: '',
  total_amount: 0,
  shipping_fee: 0,
  is_shipping_fee_paid: false,
  is_agree_marketing: false,
  is_agree_tnc: false,
  trial_os: '',
  warranty_slip_no: '',
  type_of_build: '',
  with_upgrade: '',
  kind_of_sales_transfer: '',
  status: '',
  build_tag: ''
};

const TaptapCustomerDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const snackbar = useSnackBar();
  const { canDeleteTaptapCustomer } = usePermissions();

  const [isEcommUser, setIsEcommUser] = useState<Number>(0);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAuditsLoading, setIsAuditsLoading] = useState<boolean>(false);

  const [amountChangedDialog, setAmountChangedDialog] = useState<boolean>(
    false
  );

  const [transactionInfoResponse, setTransactionInfoResponse] = useState<
    GetTransactionsViaTransactionNoResponse
  >();
  const [transactionDetailsResponse, setTransactionDetailsResponse] = useState<
    GetTransactionDetailResponse
  >();
  const [transactionLoading, setTransactionLoading] = useState<boolean>(false);

  const [quotationResponse, setQuotationResponse] = useState<TemplateDetails>();
  const [quotationProductsLoading, setQuotationProductsLoading] = useState<
    boolean
  >(false);

  // INTENDED 'any'
  const [cleanTapTapCustomerData, setCleanTapTapCustomerData] = useState<any>(
    {}
  );
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState<boolean>(
    false
  );
  const [customerPayments, setCustomerPayments] = useState<
    TapTapCustomerPayment[]
  >([]);
  const [auditsHistory, setAuditsHistory] = useState<AuditBase[]>([]);

  const fetchTapTapCustomerDetails = async () => {
    setIsLoading(true);

    if (id) {
      // Save this clean data to compare later if the server has made changes
      const { data, error } = await taptapGetCustomerDetailsService(Number(id));

      if (error) {
        console.error('errData', data);
        setIsLoading(false);

        return;
      }

      if (data?.length === 0) {
        console.error('No data found');
        setIsLoading(false);
        return;
      }

      if (data?.length > 0) {
        const response: TapTapCustomerData = data[0];

        if (response?.internal_quotation_id) {
          await fetchQuotationProducts(response.internal_quotation_id);
        }

        if (response?.internal_transaction_no) {
          fetchTransaction({
            internal_transaction_no: response.internal_transaction_no
          });
        }

        // to populate payments
        if (
          response?.customers_payments &&
          response?.customers_payments?.length
        ) {
          setCustomerPayments(response?.customers_payments);
          delete response.customers_payments;
        }

        formik.setValues(response);
        setCleanTapTapCustomerData(response);

        setIsLoading(false);
      }
    }
  };

  const fetchTaptapCustomerAudit = async () => {
    setIsAuditsLoading(true);
    if (id) {
      // Save this clean data to compare later if the server has made changes
      // const { data, error } = await taptapFetchCustomerAuditService(Number(id));
      const { data, error } = await taptapFetchCustomerAuditService(Number(id));

      if (error) {
        console.error('errData', data);
        console.error('errData', error);
        setIsLoading(false);
        return;
      }

      if (data?.length === 0) {
        console.error('No data found');
        setIsLoading(false);
        return;
      }

      const formattedDataArr = data.map((i: TapTapCustomerAuditHistory) => {
        // Transform data for audithistory component compatibility
        const newData: AuditBase = {
          audit_id: i.id,
          event: i.op,
          performed_by: i.user_display_name,
          updated_at: i.ts, // format this to readable
          old_values: i.old_record,
          new_values: i.record
        };

        return newData;
      });

      setAuditsHistory(formattedDataArr);
      setIsAuditsLoading(false);

      // if (data?.length > 0) {
      //   const response: TapTapCustomerData = data[0];

      //   if (response?.internal_quotation_id) {
      //     fetchQuotationProducts(response.internal_quotation_id);
      //   }

      //   if (response?.internal_transaction_no) {
      //     fetchTransaction({
      //       internal_transaction_no: response.internal_transaction_no
      //     });
      //   }

      //   // to populate payments
      //   if (
      //     response?.customers_payments &&
      //     response?.customers_payments?.length
      //   ) {
      //     setCustomerPayments(response?.customers_payments);
      //     delete response.customers_payments;
      //   }

      //   formik.setValues(response);
      //   setCleanTapTapCustomerData(response);

      //   setIsLoading(false);
      // }
    }
  };

  const fetchTransaction = async ({
    internal_transaction_no,
    resyncData
  }: {
    internal_transaction_no: string;
    resyncData?: boolean;
  }) => {
    setTransactionLoading(true);

    dispatch(
      transactionActions.getTransactionDetailThunk(internal_transaction_no)
    ).then(async (response: any) => {
      const transactionDetailsApiResponse: GetTransactionDetailResponse =
        response?.payload?.originalData;

      if (transactionDetailsApiResponse) {
        const transactionInfoApiResponse: any = unwrapResult(
          await dispatch(
            transactionActions.getTransactionViaTransactionNo(
              internal_transaction_no
            )
          ).finally(() => setTransactionLoading(false))
        );

        if (transactionInfoApiResponse?.originalData?.success) {
          setTransactionDetailsResponse(transactionDetailsApiResponse);
          setTransactionInfoResponse(transactionInfoApiResponse?.originalData);

          const ecommUser =
            transactionInfoApiResponse?.originalData?.is_ecomm_user;

          setIsEcommUser(ecommUser === true ? 1 : 0);

          // Resync Data Was Clicked
          if (resyncData) {
            const formTotalAmount = formik?.values?.total_amount;
            const transactionTotalAmount =
              transactionInfoApiResponse?.originalData?.total_price;

            if (formTotalAmount !== transactionTotalAmount) {
              setAmountChangedDialog(true);
            }
          }
        }
      }
    });

    setTransactionLoading(false);
  };

  const onSyncPayments = async () => {
    // labas n lng to para magamit din ng parent component.
    // TODO: Fetch Payments of transaction.
    // TODO: Add loader
    // getTransactionPaymentsThunk

    const currentFormikValues = formik.values;

    if (!currentFormikValues[taptapCustomerField.INTERNAL_TRANSACTION_NO]) {
      alert('No transaction no found.');
      return;
    }

    const transactionPaymentsApiResponse = unwrapResult(
      await dispatch(
        transactionPaymentActions.getTransactionPaymentsThunk(
          currentFormikValues[taptapCustomerField.INTERNAL_TRANSACTION_NO]
        )
      )
    );

    // delete muna lahat ng payments sa taptap... then add new payments from internal transactions

    if (
      !transactionPaymentsApiResponse ||
      !transactionPaymentsApiResponse?.success
    ) {
      alert('Failed to fetch transaction payments.');
      throw new Error('Failed at getTransactionPaymentsThunk.');
    }

    if (!transactionPaymentsApiResponse?.originalData?.data) {
      alert('No payments found in transaction 0.');
      return;
    }

    if (transactionPaymentsApiResponse?.originalData?.data?.length === 0) {
      alert('No payments found in transaction 1.');
      return;
    }

    const response = await onTapTapDeleteCustomerPaymentsViaCustomerId(
      currentFormikValues[taptapCustomerField.ID]
    );

    if (response?.error) {
      alert('Failed to delete payments.');
      throw new Error('Failed at deleteTransactionPaymentThunk.');
    }

    // FINAL STEP
    await onTapTapCustomerPaymentAddFromTransactionPayments(
      transactionPaymentsApiResponse?.originalData?.data,
      {
        transaction_no:
          currentFormikValues[taptapCustomerField.INTERNAL_TRANSACTION_NO],
        taptap_customer_id: currentFormikValues[taptapCustomerField.ID]
      }
    );

    fetchTapTapCustomerDetails();
  };

  const theTotalAmountHasChangedThusUpdateIt = () => {
    formik.setFieldValue('total_amount', transactionInfoResponse?.total_price);
    handleFormSubmit({
      ...formik.values,
      total_amount: transactionInfoResponse?.sub_total_price
    });
  };

  const unlinkTransactionNo = () => {
    formik.setFieldValue('internal_transaction_no', null);
    handleFormSubmit({
      ...formik.values,
      internal_transaction_no: null
    });
  };

  const fetchQuotationProducts = async (internal_quotation_id: number) => {
    setQuotationProductsLoading(true);
    const response = unwrapResult(
      await dispatch(
        quotationActions.getTemplateDetailsThunk({
          template_id: Number(internal_quotation_id)
        })
      )
    );

    if (!response?.success) {
      snackbar.show({
        severity: 'error',
        message: 'Error Fetching Quotation Data'
      });
      setQuotationProductsLoading(false);
      return;
    }
    if (response?.originalData?.data) {
      setQuotationResponse(response?.originalData?.data);
    }

    setQuotationProductsLoading(false);
  };

  const uploadMultipleImages = async (
    path: string,
    images: TapTapImageFieldJson[]
  ) => {
    let uploadedImgs: TapTapImageFieldJson[] = [];
    const userId = (await SupabaseClientService?.auth?.getUser()).data.user?.id;
    // const currentQuoteImgs = formik.values.quotation;

    try {
      for (const image of images) {
        if (image.file) {
          const randomFileName = `${getRandomUUIDv4Short()}-${Date.now()}`;

          const { error } = await SupabaseClientService.storage
            .from('taptap-images')
            .upload(`${path}${randomFileName}`, image.file); // TODO: Change the path based on field key

          if (error) {
            console.error(`Error uploading image for field ${path}:`, error);
            throw new Error(`Failed to upload images for field ${path}.`);
          }

          // IMPORTANT
          image.file = undefined; // Remove the file object

          // Get the public URL
          const imgUrlResp = SupabaseClientService.storage
            .from('taptap-images')
            .getPublicUrl(`${path}${randomFileName}`);

          uploadedImgs.push({
            ...image,
            img_url: imgUrlResp.data.publicUrl,
            created_by: userId,
            created_at: new Date().toISOString()
          });
        } else {
          uploadedImgs.push(image); // Already uploaded images remain unchanged
        }
      }

      return uploadedImgs;
    } catch (err) {
      console.error('Upload process terminated:', err);
      return null; // Or handle the error as needed
    }
  };

  const handleFormSubmit = async (newValues: any) => {
    setIsLoading(true);

    if (cleanTapTapCustomerData?.is_deleted) {
      setIsLoading(false);

      console.error('This customer has been deleted');
      alert('This customer has been deleted already');
      return;
    }

    // const userId = (await SupabaseClientService?.auth?.getUser()).data.user?.id;

    const imageFields = Object.keys(newValues).filter(
      (key) => Array.isArray(newValues[key]) && newValues[key][0]?.img_url
    );

    // Step 2: Handle image uploads for each "image" field
    for (const fieldKey of imageFields) {
      const fieldImages: TapTapImageFieldJson[] = newValues[fieldKey];

      // Upload only unuploaded images
      const uploadedImages = await uploadMultipleImages(
        `${fieldKey}/`,
        fieldImages
      );

      if (!uploadedImages) {
        setIsLoading(false);
        console.error('Failed to upload images for field:', fieldKey);
        return;
      }

      newValues[fieldKey] = uploadedImages;
    }

    const changedValues = pickBy(
      newValues,
      (value, key) => !isEqual(value, cleanTapTapCustomerData[key])
    );

    if (isEmpty(changedValues)) {
      setIsLoading(false);

      console.error('No changes detected');
      snackbar.show({
        severity: 'error',
        message: JSON.stringify('No changes detected')
      });
      return;
    }

    if (
      changedValues[taptapCustomerField.STATUS] === taptapStatuses.CANCELLED &&
      newValues?.internal_transaction_no
    ) {
      const transactionInfoApiResponse: any = unwrapResult(
        await dispatch(
          transactionActions.getTransactionViaTransactionNo(
            newValues?.internal_transaction_no
          )
        )
      );

      const oneItemIsNotVoided = transactionInfoApiResponse?.originalData?.items?.find(
        (x: any) => x.isDeleted === 0
      );

      if (oneItemIsNotVoided) {
        setIsLoading(false);
        alert(
          'Please void all items first before cancelling the customer. If you do not have access to Void Items, please ask your supervisor to do it for you.'
        );
        return;
      }
    }

    // Transform the data to be saved. Make this a util someday where data is transformed based on the field if needed
    // changedValues[taptapCustomerField.SHIPPING_FEE] =
    //   changedValues[taptapCustomerField.SHIPPING_FEE] || 0;
    // changedValues[taptapCustomerField.TOTAL_AMOUNT] =
    //   changedValues[taptapCustomerField.TOTAL_AMOUNT] || 0;

    // JUST UPDATE PROPERTIES THAT HAVE CHANGED
    const { data, error } = await SupabaseClientService.from('customers')
      .update(changedValues)
      .eq('id', id)
      .select();

    if (error) {
      setIsLoading(false);

      console.error('errData', error);
      snackbar.show({
        severity: 'error',
        message: JSON.stringify(error)
      });
      return;
    }

    if (data?.length === 0) {
      setIsLoading(false);

      console.error('No data found');
      snackbar.show({
        severity: 'error',
        message: 'Failed to update customer data length 0'
      });
      return;
    }

    if (data?.length > 0) {
      setIsLoading(false);

      const response: TapTapCustomerData = data[0];
      formik.setValues(response);
      setCleanTapTapCustomerData(response);
      snackbar.show({
        severity: 'success',
        message: 'Customer updated successfully'
      });
      return;
    }
  };

  // Transfer this to thunk
  const onDeleteCustomer = async () => {
    setIsDeleteDialogVisible(false);

    if (formik?.values?.internal_transaction_no) {
      const transactionInfoApiResponse: any = unwrapResult(
        await dispatch(
          transactionActions.getTransactionViaTransactionNo(
            formik?.values?.internal_transaction_no
          )
        )
      );

      const oneItemIsNotVoided = transactionInfoApiResponse?.originalData?.items?.find(
        (x: any) => x.isDeleted === 0
      );

      if (oneItemIsNotVoided) {
        alert(
          'Please void all items first before deleting this taptap customer. If you do not have access to Void Items, please ask your supervisor to do it for you.'
        );
        return;
      }
    }

    const user = await SupabaseClientService?.auth?.getUser();

    const dataForDeletion = {
      deleted_at: new Date().toISOString(),
      deleted_by: user.data.user?.id,
      is_deleted: true
    };

    const { data, error } = await SupabaseClientService.from('customers')
      .update(dataForDeletion)
      .eq('id', id)
      .select();

    if (error) {
      console.error('errData', data);
      return;
    }

    if (data?.length === 0) {
      console.error('No data found');
      return;
    }

    if (data?.length > 0) {
      const response: TapTapCustomerData = data[0];
      formik.setValues(response);
      setCleanTapTapCustomerData(response);
      return;
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleFormSubmit,
    validationSchema: formSchema
  });

  const deletionDialogCopy = useMemo(() => {
    if (formik?.values?.internal_transaction_no) {
      return `TRANSACTION NO IS ATTACHED. IF ITS A TRANSACTION FROM ECOMM, DO NOT VOID IT. ELSE VOID IT PLEASE. ASK SUPERVISOR IF NOT SURE WHAT TO DO `;
    }
    return `Are you sure you want to delete this customer?`;
  }, [formik.values.internal_transaction_no]);

  const totalChangedDialogCopy = useMemo(() => {
    const previousAmount = formatCurrency(formik?.values?.total_amount);
    const newAmount = formatCurrency(transactionInfoResponse?.total_price);

    return `The total amount has changed. Do you want to update the total amount from ${previousAmount} to ${newAmount}?`;
  }, [formik.values.total_amount, transactionInfoResponse]);

  useEffect(() => {
    fetchTapTapCustomerDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page className={classes.root} title="Taptap Customer Details">
      <Container maxWidth={false} style={{ opacity: isLoading ? 0.3 : 1 }}>
        {formik?.values?.is_deleted && (
          <Alert
            hidden
            severity="error"
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <Typography variant="h3">
              CUSTOMER IS ALREADY DELETED. YOU CANNOT EDIT THIS CUSTOMER
              ANYMORE.
            </Typography>
          </Alert>
        )}
        <LoaderBar isLoading={isLoading} />
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          {/* HUWAG MUNA IUNCOMMENT IF DI PAFIXED YUNG MINSAN ECOMM USER SYA MINSAN USER LANG NA NORMAL */}
          {/* <LinkComponent
            openInNewTab
            href={`/app/customers/${formik?.values?.internal_customer_id}`}
            title={
              <Typography variant="h3" style={{ color: colors.link }}>
                Customer Details -{' '}
                {`${formik?.values?.first_name} ${formik?.values?.last_name}`}
              </Typography>
            }
          /> */}

          <Typography variant="h3">
            Customer Details -{' '}
            <LinkComponent
              openInNewTab
              title={
                <Typography variant="inherit" style={{ color: colors.link }}>
                  {`${formik?.values?.first_name} ${formik?.values?.last_name}`}
                </Typography>
              }
              href={`/app/customers/${formik?.values?.internal_customer_id}?is_ecomm_user=${isEcommUser}`}
              onClick={() =>
                navigate(
                  `/customers/taptap/${formik?.values?.internal_customer_id}?is_ecomm_user=${isEcommUser}`
                )
              }
            />
            {/* {`${formik?.values?.first_name} ${formik?.values?.last_name}`} */}
          </Typography>
        </Box>

        {formik?.values?.internal_transaction_no &&
        transactionInfoResponse &&
        transactionDetailsResponse ? (
          <TapTapTransactionsCard
            onRefreshTransaction={() =>
              fetchTransaction({
                internal_transaction_no:
                  formik?.values?.internal_transaction_no,
                resyncData: true
              })
            }
            isLoading={transactionLoading}
            transactionInfo={transactionInfoResponse}
            transactionDetails={transactionDetailsResponse}
            onHandleUnlinkTransaction={unlinkTransactionNo}
          />
        ) : null}

        {customerPayments && customerPayments?.length > 0 ? (
          <TapTapPaymentsCard
            isLoading={false}
            data={customerPayments}
            onFetchPayments={() => fetchTapTapCustomerDetails()}
            onSyncPayments={onSyncPayments}
            onClickEditPayment={() => {}} // TODO:
            transactionNo={formik?.values?.internal_transaction_no}
            currentFormikValues={formik.values}
          />
        ) : null}

        {formik?.values?.internal_transaction_no &&
        !customerPayments?.length ? (
          <Box mt={3} mb={3}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              disabled={formik?.values?.is_deleted || isLoading}
              onClick={() => {}}
            >
              <LinkComponent
                openInNewTab
                href={`/app/transaction/${formik?.values?.internal_transaction_no}`}
                title={
                  <Typography
                    variant="h4"
                    style={{ color: colors.common.white, padding: 10 }}
                  >
                    Add Payment On Transaction
                  </Typography>
                }
              />
            </Button>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              disabled={formik?.values?.is_deleted || isLoading}
              onClick={onSyncPayments}
              style={{ marginTop: 10 }}
            >
              Sync Payments
            </Button>
          </Box>
        ) : null}

        {formik?.values?.internal_quotation_id && quotationResponse ? (
          <TapTapQuotationCard
            initialCollapse={false}
            quotationResponse={quotationResponse}
            internalQuotationId={formik?.values?.internal_quotation_id}
            quotationProductsLoading={quotationProductsLoading}
            fetchQuotationProducts={() =>
              fetchQuotationProducts(formik?.values?.internal_quotation_id)
            }
          />
        ) : null}

        <Box
          mt={2}
          style={{ display: 'flex', flexDirection: 'column', rowGap: '1em' }}
        >
          <TapTapForm
            formFields={taptapCustomerDetailsFields}
            formikHookRef={formik}
          />
          {isAuditsLoading ? <LinearProgress /> : null}
          {!isAuditsLoading && auditsHistory[0] ? (
            <AuditHistory
              summary={`View Update/Edit History For Customer: ${formik?.values?.first_name} ${formik?.values?.last_name}`}
              auditData={auditsHistory}
              moduleName={'taptap'}
              isLoading={isLoading}
            />
          ) : null}
          {!isAuditsLoading && !auditsHistory[0] ? (
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={fetchTaptapCustomerAudit}
              >
                Get Audits History
              </Button>
            </Box>
          ) : null}
        </Box>

        <Button
          fullWidth
          onClick={async () => {
            const validateForm = await formik.validateForm();
            if (validateForm && Object.keys(validateForm).length > 0) {
              const errorFields = Object.keys(validateForm).join(', ');
              const message = `Please check the following fields: ${errorFields}`;
              snackbar.show({ severity: 'error', message, useSound: true });
              return;
            }

            formik.handleSubmit();
          }}
          color="primary"
          variant="contained"
          style={{ padding: 30, marginTop: 50 }}
          disabled={formik?.values?.is_deleted || isLoading}
        >
          Save Customer
        </Button>

        {canDeleteTaptapCustomer ? (
          <Button
            fullWidth
            variant="text"
            color="secondary"
            style={{ marginTop: 20 }}
            disabled={formik?.values?.is_deleted || isLoading}
            onClick={() => setIsDeleteDialogVisible(true)}
          >
            Delete Customer
          </Button>
        ) : null}
      </Container>
      <DeleteDialog
        title="Delete Customer"
        subTitle={deletionDialogCopy}
        isVisible={isDeleteDialogVisible}
        handleClose={() => setIsDeleteDialogVisible(false)}
        onDelete={onDeleteCustomer}
      />
      <DecisionDialog
        isOpen={amountChangedDialog}
        title="Total Amount Has Changed"
        subTitle={totalChangedDialogCopy}
        onHandleConfirmAction={() => theTotalAmountHasChangedThusUpdateIt()}
        onHandleClose={() => setAmountChangedDialog(false)}
      />
    </Page>
  );
};

export default TaptapCustomerDetails;

const formSchema = yup.object().shape({
  [taptapCustomerField.FIRST_NAME]: yup
    .string()
    .required('First Name is required'),
  [taptapCustomerField.LAST_NAME]: yup
    .string()
    .required('Last Name is required'),
  [taptapCustomerField.BRANCH_RELEASED]: yup
    .string()
    .required('Branch is required'),
  [taptapCustomerField.SOURCE]: yup.string().required('Source is required'),
  [taptapCustomerField.COURIER]: yup.string().required('Courier is required'),
  [taptapCustomerField.CONTACT_NO]: yup
    .string()
    .nullable()
    .matches(/\d+$/, 'Contact number must be numeric'),
  [taptapCustomerField.IS_AGREE_TNC]: yup
    .boolean()
    .required('You must accept the terms and conditions')
    .oneOf([true], 'You must accept the terms and conditions')
});
