import React, { useMemo, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  InputAdornment,
  SvgIcon,
  Typography,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  LinearProgress,
  debounce
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { CustomInputEvent, CustomKeyboardEvent } from 'src/types';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { ecomOrderStatus } from 'src/constants';
import { BranchListDropDown } from 'src/components/dropdown';

const {
  actions: ecomOrderActions,
  selectors: ecomOrderSelectors
} = slices.ecomOrder;

interface Props {
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  cardContent: {
    flexDirection: 'row'
  }
}));

const Component = ({ className, ...rest }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [branchId, setBranchId] = useState<number>();

  const ecomOrdersListRequest = useAppSelector(
    ecomOrderSelectors.getEcomOrderListRequest
  );
  const isLoading = useAppSelector(ecomOrderSelectors.selectEcomOrdersLoading);

  const hasStatusFilter = useMemo(
    () => (ecomOrdersListRequest?.status ? true : false),
    [ecomOrdersListRequest]
  );

  const onSearchOrders = debounce(() => {
    dispatch(
      ecomOrderActions.updateGetEcomOrdersListRequest({
        page: 1,
        branch_id: branchId
      })
    );
    dispatch(ecomOrderActions.getEcomOrderListThunk({}));
  }, 500);

  const handleChange = (event: CustomInputEvent) => {
    dispatch(
      ecomOrderActions.updateGetEcomOrdersListRequest({
        keyword: event?.target?.value
      })
    );
  };

  const onSearchClick = () => {
    onSearchOrders();
  };

  const onKeyPress = (e: CustomKeyboardEvent) => {
    if (e.key === 'Enter') {
      onSearchOrders();
    }
  };

  const handleFilterChange = async (orderStat: string) => {
    dispatch(
      ecomOrderActions.updateGetEcomOrdersListRequest({
        status: orderStat
      })
    );
  };

  const handleSortChange = async (sortBy: string) => {
    dispatch(
      ecomOrderActions.updateGetEcomOrdersListRequest({
        field: sortBy,
        sort: 'desc'
      })
    );
  };

  const onHandleBranchChange = async (branch_id?: number) => {
    setBranchId(branch_id);
    dispatch(
      ecomOrderActions.updateGetEcomOrdersListRequest({
        branch_id
      })
    );
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography color="textPrimary" gutterBottom variant="h3">
          E-commerce Orders List
        </Typography>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item md={3} xs={12} sm={6}>
                <Box>
                  <TextField
                    fullWidth
                    value={ecomOrdersListRequest?.keyword || ''}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Search Customer Name / Order Ref No."
                    variant="outlined"
                    onKeyPress={onKeyPress}
                  />
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={2}
                style={{
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <FormControl fullWidth>
                  <InputLabel id="order-status" variant="outlined">
                    Status Filter
                  </InputLabel>
                  <Select
                    labelId="order-status"
                    value={ecomOrdersListRequest?.status}
                    onChange={(e: any) => {
                      handleFilterChange(e.target.value);
                    }}
                    input={<OutlinedInput label="order-status" />}
                  >
                    {ecomOrderStatus.map((status) => (
                      <MenuItem key={status} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={2}
                style={{
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <Tooltip
                  disableFocusListener={hasStatusFilter}
                  disableHoverListener={hasStatusFilter}
                  title="please set status filter first"
                  placement="top-start"
                >
                  <FormControl fullWidth>
                    <InputLabel variant="outlined">Sort By</InputLabel>
                    <Select
                      disabled={!hasStatusFilter}
                      value={ecomOrdersListRequest?.sort}
                      onChange={(e: any) => {
                        handleSortChange(e.target.value);
                      }}
                      input={<OutlinedInput />}
                    >
                      <MenuItem key={'paid_at'} value={'paid_at'}>
                        Recently Paid
                      </MenuItem>
                      <MenuItem key={'recent-ordered'} value={'created_at'}>
                        Recently Ordered
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={2}>
                <BranchListDropDown
                  allowNoSelectedBranch={true}
                  isElevated={false}
                  paperSpace={0}
                  onHandleBranchChange={(branchIds?: number[]) =>
                    onHandleBranchChange(branchIds && branchIds[0])
                  }
                />
              </Grid>
              <Grid alignItems="center" item xs={12} md={2}>
                <Button
                  endIcon={<SearchIcon />}
                  disabled={isLoading}
                  onClick={onSearchClick}
                  color="primary"
                  variant="contained"
                >
                  Apply Filter
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      {isLoading ? <LinearProgress /> : null}
    </div>
  );
};

export const EcomOrdersListToolbar = React.memo(Component);
