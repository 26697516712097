import React, { useCallback, useRef } from 'react';
import {
  Chip,
  IconButton,
  makeStyles,
  TableCell,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';

import {
  CustomInputEvent,
  CustomKeyboardEvent,
  TransactionPayment
} from 'src/types';

interface Props {
  data: TransactionPayment;
  index: number;
  isDeleted?: boolean;
  onSave: (e: any, item: TransactionPayment) => void;
  onChangeText: (e: CustomInputEvent, index: number) => void;
}

const useStyles = makeStyles(
  {
    saveIcon: {
      padding: 0
    },
    input: {
      paddingTop: 0,
      height: 25
    }
  },
  { index: 999 }
);

const ARCell = ({
  data,
  index,
  isDeleted = false,
  onSave,
  onChangeText
}: Props) => {
  const inputRef = useRef([]);
  const classes = useStyles();

  const onKeyDown = useCallback(
    (e: CustomKeyboardEvent, item: TransactionPayment) => {
      if (e.key === 'Enter') {
        onSave(e, item);
      }
    },
    [onSave]
  );

  return (
    <TableCell onClick={(e) => e.stopPropagation()}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          ref={inputRef.current[index]}
          className={classes.input}
          onFocus={(e) => {
            e.stopPropagation();
          }}
          size="small"
          variant="outlined"
          onKeyDown={(e) => onKeyDown(e, data)}
          name="acknowledgement_receipt_no"
          onChange={(e) => onChangeText(e, index)}
          value={data?.acknowledgement_receipt_no ?? ''}
          InputProps={{
            className: classes.input,
            endAdornment: (
              <IconButton
                className={classes.saveIcon}
                onClick={(e) => onSave(e, data)}
              >
                <SaveIcon />
              </IconButton>
            )
          }}
        />
        {isDeleted ? (
          <Tooltip
            title={
              <Typography
                style={{ fontSize: '1.5em' }}
              >{`Void reason: ${data?.remarks}`}</Typography>
            }
          >
            <Chip
              label={
                <Typography style={{ color: 'rgba(0,0,0,0.5)' }}>
                  Deleted
                </Typography>
              }
              style={{ height: '100%', marginLeft: 10 }}
              size="small"
              variant="outlined"
              color="default"
            />
          </Tooltip>
        ) : null}
      </div>
    </TableCell>
  );
};

export const TransactionDetailsARCell = React.memo(ARCell);
