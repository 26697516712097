import { colors } from 'src/constants';
import { PriceList, PriceListColumn } from 'src/types';

export interface TableCellProps {
  id?: string;
  data: PriceList;
  index: number;
  isLoading?: boolean;
  priceList?: PriceList[];
}
export const priceListColumns: PriceListColumn[] = [
  { id: 1, label: 'PRODUCT NAME', minWidth: 170, code: 'name' },
  { id: 2, label: 'BRANCH', minWidth: 80, code: 'branch' },
  { id: 3, label: 'QTY', minWidth: 80, code: 'quantity' },
  { id: 10, label: ' TS QTY', minWidth: 80, code: 'quantity' },
  {
    id: 4,
    label: 'SRP', //changes remove percentage
    minWidth: 170,
    textColor: colors.grey[300],
    code: 'srp'
  },
  {
    id: 5,
    label: 'INSTALLMENT',
    minWidth: 170,
    textColor: colors.grey[300],
    code: 'installment'
  },
  {
    id: 6,
    label: 'PROMO PRICE',
    minWidth: 170,
    textColor: colors.error,
    code: 'promo_price'
  },
  {
    id: 7,
    label: 'DP',
    minWidth: 170,
    textColor: colors.error,
    permissions: ['can_see_pricelist_delears_price'],
    code: 'dealer_price'
  },
  {
    id: 8,
    label: 'GROSS',
    minWidth: 80,
    permissions: ['can_see_pricelist_gross_price'],
    code: 'gross'
  },
  {
    id: 9,
    label: 'MARGIN %',
    minWidth: 80,
    permissions: ['can_see_pricelist_margin_percentage'],
    code: 'margin'
  }
];

export interface queryParamsInterface {
  sort_by?: number;
  out_of_stock_only?: boolean | number;
  hide_no_stock?: boolean | number;
  keyword?: string;
  category_id?: number;
  page?: number;
  branch_ids?: number[];
}
