import React, { useCallback, useEffect, useState } from 'react';
import { Container, makeStyles } from '@material-ui/core';
import { Page } from 'src/components';
import { PaginationLinks, Transaction } from 'src/types';
import { unwrapResult } from '@reduxjs/toolkit';
import { slices, useAppDispatch } from 'src/redux';
import TransactionsTable from './components/TransactionsTable';
import ToolBar from './components/ToolBar';
import { Pagination } from '@material-ui/lab';
import { useBranchInfo } from 'src/hooks/branch/use-branch-info';
import { useLocation, useNavigate } from 'react-router';
import useUserInfo from 'src/hooks/user/use-user-info';

const { actions: transactionActions } = slices.transaction;

interface Query {
  page: string;
  keyword?: string;
  branches?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));

const TransactionView = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userBranchOptions } = useUserInfo();
  const { search } = useLocation();

  const { selectedBranchIDs, setSelectedBranchIDs } = useBranchInfo();

  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [queryParams, setQueryParams] = useState<Query>({ page: '1' });
  const [paginationLinks, setPaaginationLinks] = useState<PaginationLinks>();

  const branchIDToName = useCallback(
    (val: number) => {
      const branchName =
        userBranchOptions.find((i) => i.value === val)?.name || '';
      return branchName;
    },
    [userBranchOptions]
  );

  const branchNameToID = useCallback(
    (val: string) => {
      return userBranchOptions.find((i) => i.name === val)?.value || -1;
    },
    [userBranchOptions]
  );

  const getTransactions = useCallback(
    async (keyword?: string, branch_ids?: number[], page: number = 1) => {
      const isOrderNo = /^\d+$/.test(keyword ? keyword : '');

      setIsLoading(true);
      const response = unwrapResult(
        await dispatch(
          transactionActions.getTransactionsThunk({
            keyword: !isOrderNo ? keyword : undefined,
            branch_ids,
            page,
            order_no: isOrderNo ? keyword : undefined
          }) // we might put limit props here in the future
        ).finally(() => setIsLoading(false))
      );

      const transactions = response.originalData?.data;
      const paginationData = response?.originalData?.links;

      if (response.success && transactions) {
        setTransactions(transactions);
        setPageNumber(page);
        setSearchText(keyword || '');
        setSelectedBranchIDs(branch_ids || []);
        setPaaginationLinks(paginationData);
      }
    },
    [dispatch, setSelectedBranchIDs]
  );

  const onSearchTransactions = (keyword?: string, branch_ids?: number[]) => {
    const newQueryParams = {
      keyword,
      branches: branch_ids?.map((i) => branchIDToName(i)).join(',') || '',
      page: '1'
    };

    let entries = Object.entries(newQueryParams);

    if (!newQueryParams.branches) {
      entries.splice(1, 1); // remove branches props from slug if value is empty
    }

    if (!newQueryParams.keyword) {
      entries.splice(0, 1); // remove keyword props from slug if value is empty
    }

    const slug = entries.map((i) => `${i[0]}=${i[1]}`).join('&');

    setQueryParams(newQueryParams);
    navigate({ search: String(slug) });
  };

  const onChangePage = useCallback(
    (_: React.ChangeEvent<any>, value: number) => {
      const newQueryParams = { ...queryParams, page: String(value) }; // get from statue
      const slug = Object.entries(newQueryParams)
        .map((i) => `${i[0]}=${i[1]}`)
        .join('&');

      navigate({ search: String(slug) });
      setQueryParams(newQueryParams);
      window.scrollTo(0, 0);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, searchText, selectedBranchIDs]
  );

  // manual parser for location search value because the built in parser "new URLSearchParams()" does not work
  const parseSearch = (search: string): { [key: string]: string } => {
    if (!search) return {};

    // response of this function is intended to mimic new URLSearchParams()
    return search
      .substring(1)
      .split('&')
      .reduce((acc: { [key: string]: string }, param) => {
        const [key, value] = param.split('=');
        acc[key] = decodeURIComponent(value || ''); // Handle undefined cases
        return acc;
      }, {});
  };

  useEffect(() => {
    if (!search) {
      getTransactions();
    } else {
      const { page, keyword, branches } = parseSearch(search);

      const branchIds = !branches
        ? []
        : branches.split(',').map((i: string) => branchNameToID(i));

      getTransactions(keyword, branchIds, +page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Page className={classes.root} title="Transaction">
      <Container maxWidth={false}>
        <ToolBar
          selectedBranchIDs={selectedBranchIDs}
          setSelectedBranchIDs={setSelectedBranchIDs}
          onSearchTransaction={onSearchTransactions}
          isLoading={isLoading}
          searchText={searchText}
          setSearchText={(val) => setSearchText(val)}
        />
        <div
          style={{
            marginTop: '1em',
            display: 'flex',
            justifyContent: 'end'
          }}
        >
          <Pagination
            page={pageNumber}
            onChange={onChangePage}
            count={pageNumber + (paginationLinks?.next ? 1 : 0)}
            siblingCount={1}
            boundaryCount={1}
            showFirstButton={false}
            showLastButton={false}
            hidePrevButton={!paginationLinks?.prev} // Hide if no previous page
            hideNextButton={!paginationLinks?.next}
          />
        </div>
        <TransactionsTable transactions={transactions} loading={isLoading} />
        <div
          style={{
            marginTop: '1em',
            display: 'flex',
            justifyContent: 'end'
          }}
        >
          <Pagination
            page={pageNumber}
            onChange={onChangePage}
            count={pageNumber + (paginationLinks?.next ? 1 : 0)}
            siblingCount={1}
            boundaryCount={1}
            showFirstButton={false}
            showLastButton={false}
            hidePrevButton={!paginationLinks?.prev} // Hide if no previous page
            hideNextButton={!paginationLinks?.next}
          />
        </div>
      </Container>
    </Page>
  );
};

export default TransactionView;
