/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Popper,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Category, CustomInputEvent, Manufacturer } from 'src/types';
import {
  findSelectedQuickFilterViaParam,
  quickFilterBranchesChoices,
  quickFilterViewsChoices,
  taptapCustomerInitialPayload,
  updateColumnVisibilityUtil
} from 'src/redux/slices/taptap-customer';
import {
  TapTapColumn,
  TapTapQuickFilterView,
  TapTapFiltersV2,
  TapTapGetCustomersCondition
} from 'src/redux/slices/taptap-customer/types';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { cloneDeep, isEqual } from 'lodash';
import { initialTaptapTableColumns } from 'src/redux/slices/taptap-customer/constants/taptap-table-columns';
import { CheckBoxLabel, DatePickerRangeComponent } from 'src/components';
import { snakeCaseToTitleCase } from 'src/utils';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { useNavigate } from 'react-router';
import { taptapCustomerField } from 'src/redux/slices/taptap-customer/constants/taptap-fields';
import AddIcon from '@material-ui/icons/Add';
import { Autocomplete } from '@material-ui/lab';
import {
  rmaOptionsStatuses,
  rmaLocationOptions
} from 'src/redux/slices/rma/constants/rma-form-fields';
import { usePermissions } from 'src/hooks';
import { dateToday, transformerDateField } from 'src/utils';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Accordion } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import useResolution from 'src/hooks/useResolution';
import { unwrapResult } from '@reduxjs/toolkit';
import { Supplier } from 'src/types/supplier';

interface Props {
  className?: string;
  input?: string;
  onSearchCustomer?: (text: string) => void;
  onToolbarInputChange?: (e: CustomInputEvent) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2)
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const {
  actions: taptapCustomerActions,
  selectors: taptapCustomerSelectors
} = slices.taptapCustomer;

const { actions: rmaActions, selectors: rmaSelectors } = slices.rma;
const { actions: categoryActions } = slices.category;
const { actions: manufacturerActions } = slices.manufacturer;

const Toolbar = ({ className, ...rest }: Props) => {
  const navigate = useNavigate();
  const { isMobile } = useResolution();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { actions: supplierActions } = slices.supplier;

  // Create RMA Permission
  const { canCreateRMA } = usePermissions();

  const selectedRmaGetPayload = useAppSelector(
    rmaSelectors.selectRmaGetPayload
  );

  const selectRmaGetPayload = useAppSelector(rmaSelectors.selectRmaGetPayload);

  const selectedSearchValue = useAppSelector(
    taptapCustomerSelectors.selectTaptapCustomerSearchValue
  );
  const selectedFilter = useAppSelector(
    taptapCustomerSelectors.selectTaptapCustomerFilter
  );
  const selectedColumns = useAppSelector(
    taptapCustomerSelectors.selectTaptapCustomerColumns
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElColumns, setAnchorElColumns] = useState(null);

  const [isFilterPopEverOpen, setIsFilterPopEverOpen] = useState<boolean>(
    false
  );
  const [tempPopOverFilter, setTempPopOverFilter] = useState<TapTapFiltersV2>(
    taptapCustomerInitialPayload.filters
  );

  const [isColumnsPopOverOpen, setIsColumnsPopOverOpen] = useState<boolean>(
    false
  );
  const [tempPopOverColumns, setTempPopOverColumns] = useState<TapTapColumn[]>(
    []
  );

  const [categories, setCategories] = useState<Category[]>([]);
  const [manufacturers, setManufacturers] = useState<Manufacturer[]>([]);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);

  const getCategories = useCallback(async () => {
    const getCategoriesResponse = unwrapResult(
      await dispatch(categoryActions.getCategoriesThunk({}))
    );
    if (getCategoriesResponse?.originalData?.data) {
      setCategories(getCategoriesResponse.originalData.data);
    }
  }, [dispatch, setCategories]);

  const getManufacturers = useCallback(async () => {
    const getManufacturersResponse = unwrapResult(
      await dispatch(manufacturerActions.getManufacturersThunk({}))
    );

    if (getManufacturersResponse?.originalData?.manufacturers) {
      setManufacturers(getManufacturersResponse.originalData.manufacturers);
    }
  }, [dispatch, setManufacturers]);

  const getSuppliers = useCallback(async () => {
    const getSuppliersResponse = unwrapResult(
      await dispatch(supplierActions.getSuppliersThunk({ keyword: '' }))
    );

    if (getSuppliersResponse?.success) {
      setSuppliers(getSuppliersResponse?.originalData.suppliers);
    }
  }, [dispatch, supplierActions]);

  useEffect(() => {
    getManufacturers();
    getCategories();
    getSuppliers();
  }, [getCategories, getManufacturers, getSuppliers]);

  const onResetAllTapTapState = () => {
    dispatch(taptapCustomerActions.resetAll());
  };

  const onColumnsPopOverOpen = (event: any) => {
    const sortedColumns = cloneDeep(selectedColumns)
      .map((column, i) => ({ ...column, origIndex: i }))
      .sort((a, b) => {
        if (a.field < b.field) {
          return -1;
        }
        if (a.field > b.field) {
          return 1;
        }
        return 0;
      });

    setTempPopOverColumns(sortedColumns); // Set state filter from redux
    setAnchorElColumns(event.currentTarget);
    setIsColumnsPopOverOpen((prev) => !prev);
  };

  const onColumnsPopOverClose = () => {
    setIsColumnsPopOverOpen(false);
    setTempPopOverColumns([]); // reset state filter to initial
  };

  const onColumnsPopOverReset = () => {
    setTempPopOverColumns(initialTaptapTableColumns); // reset state filter to initial
  };

  const onApplyColumns = () => {
    const clonedColumns = cloneDeep(tempPopOverColumns).sort(
      (a, b) => (a.origIndex || 0) - (b.origIndex || 0)
    );
    dispatch(taptapCustomerActions.updateTaptapColumns(clonedColumns));
    onColumnsPopOverClose();
  };

  const onFilterPopOverOpen = (event: any) => {
    setTempPopOverFilter(selectedFilter); // Set state filter from redux
    setAnchorEl(event.currentTarget);
    setIsFilterPopEverOpen((prev) => !prev);
  };

  const onFilterPopOverClose = () => {
    setIsFilterPopEverOpen(false);
    setTempPopOverFilter(taptapCustomerInitialPayload.filters); // reset state filter to initial
  };

  const onFilterPopOverClear = () => {
    setTempPopOverFilter(taptapCustomerInitialPayload.filters); // reset state filter to initial
  };

  const onApplyFilter = () => {
    const selectedQuickFilter = findSelectedQuickFilterViaParam(
      tempPopOverFilter
    );

    // If quick filter is selected. Apply its default column sorting and visible
    if (
      selectedQuickFilter &&
      selectedQuickFilter.defaultColumns &&
      selectedQuickFilter.defaultColumns?.length > 0
    ) {
      const newColumnArrangement = updateColumnVisibilityUtil(
        selectedColumns,
        selectedQuickFilter.defaultColumns
      );

      dispatch(taptapCustomerActions.updateTaptapColumns(newColumnArrangement));
    } else {
      dispatch(taptapCustomerActions.resetTaptapColumns());
    }

    dispatch(
      taptapCustomerActions.updateTaptapCustomerFilter(tempPopOverFilter)
    );
    dispatch(taptapCustomerActions.taptapFetchCustomersThunk());
    onFilterPopOverClose();
  };

  const selectedBranchIndicator = useMemo(() => {
    const selectedBranchCopy = selectedFilter?.conditions.find(
      (condition) => condition.field === 'branch_released'
    )?.value;

    return selectedBranchCopy || 'ALL';
  }, [selectedFilter]);

  const selectedFilterIndicator = useMemo(() => {
    const selectedQuickFilter = findSelectedQuickFilterViaParam(selectedFilter);
    return selectedQuickFilter?.label || 'ALL ?';
  }, [selectedFilter]);

  const isBranchSelected = useCallback(
    (params: TapTapGetCustomersCondition) => {
      if (tempPopOverFilter) {
        const fieldInConditions = tempPopOverFilter?.conditions.find(
          (condition) => condition.field === taptapCustomerField.BRANCH_RELEASED
        );
        if (fieldInConditions) {
          return isEqual(fieldInConditions.value, params.value);
        }
      }

      return false;
    },
    [tempPopOverFilter]
  );

  const onQuickFilterSelectBranch = (
    newCondition: TapTapGetCustomersCondition
  ) => {
    const clonedTempPopOverFilter = cloneDeep(tempPopOverFilter);
    const index = clonedTempPopOverFilter?.conditions?.findIndex(
      (condition) => condition.field === newCondition.field
    );

    if (index !== -1) {
      // If it exists, replace the existing condition or unselect
      if (isEqual(newCondition, clonedTempPopOverFilter.conditions[index])) {
        // Pag selected na yung same quick filter, unselect
        clonedTempPopOverFilter.conditions.splice(index, 1);
      } else {
        clonedTempPopOverFilter.conditions[index] = newCondition;
      }
    } else {
      // If it doesn't exist, push the new condition
      clonedTempPopOverFilter.conditions.push(newCondition);
    }

    setTempPopOverFilter(clonedTempPopOverFilter);
  };

  const isQuickViewFilterSelected = useCallback(
    (quickFilterViewParam: TapTapQuickFilterView) => {
      let clonedTempPopOverFilter = cloneDeep(tempPopOverFilter);
      clonedTempPopOverFilter.conditions = clonedTempPopOverFilter.conditions.filter(
        (x) => x.field !== taptapCustomerField.BRANCH_RELEASED
      );

      if (isEqual(clonedTempPopOverFilter, quickFilterViewParam.filters)) {
        return true;
      }

      return false;
    },
    [tempPopOverFilter]
  );

  const onQuickFilterViewSelect = (quickFilterView: TapTapQuickFilterView) => {
    // Deep clone the tempPopOverFilter
    const clonedTempPopOverFilter = cloneDeep(tempPopOverFilter);

    // Find the selected branch condition
    const selectedBranchCondition = clonedTempPopOverFilter?.conditions?.find(
      (condition) => condition?.field === taptapCustomerField.BRANCH_RELEASED
    );
    if (selectedBranchCondition) {
      // Remove branch condition temporarily
      clonedTempPopOverFilter.conditions = clonedTempPopOverFilter.conditions.filter(
        (x) => x.field !== taptapCustomerField.BRANCH_RELEASED
      );
    }
    // Deep clone quickFilterView.filters to avoid mutation
    const newFilter = cloneDeep(quickFilterView.filters);
    if (isQuickViewFilterSelected(quickFilterView)) {
      // If the quick filter is already selected, unselect it
      newFilter.conditions = [];
    }

    // Add back the branch condition if present
    if (selectedBranchCondition && selectedBranchCondition?.value?.length > 0) {
      newFilter.conditions.push(selectedBranchCondition);
    }
    // Update the state with the new filter
    setTempPopOverFilter(newFilter);
  };

  const onToolbarInputChange = useCallback(
    (e: any) => {
      dispatch(
        rmaActions.updateGetRmaPayload({ [e.target.name]: e.target.value })
      );
    },
    [dispatch]
  );

  // Date Change Handler using useCallback
  const onChangedDate = useCallback(
    (date: MaterialUiPickersDate | undefined, field: 'from' | 'to') => {
      const transformedDate = date ? transformerDateField(date) : undefined;

      if (field === 'to') {
        onToolbarInputChange({
          target: { name: 'date_created_to', value: transformedDate }
        });
      }
      if (field === 'from') {
        onToolbarInputChange({
          target: { name: 'date_created_from', value: transformedDate }
        });
      }
    },
    [onToolbarInputChange]
  );

  const onSearchClick = () => {
    dispatch(rmaActions.getRmasThunk({ page: 1 }));
  };

  const onClearSearch = async () => {
    await dispatch(rmaActions.resetGetRmaPayload());
    dispatch(rmaActions.getRmasThunk({ page: 1 }));
  };

  const onKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      dispatch(rmaActions.getRmasThunk({ page: 1 }));
    }
  };

  const onClickAddRMA = () => {
    navigate(`/app/rma/add`);
  };

  const selectedStatusFilter = useMemo(() => {
    return (
      rmaOptionsStatuses.find(
        (status) => status.value === selectedRmaGetPayload?.status
      ) || null
    );
  }, [selectedRmaGetPayload]);

  const selectedLocationFilter = useMemo(() => {
    return rmaLocationOptions.find(
      (location) => location.value === selectedRmaGetPayload?.location
    );
  }, [selectedRmaGetPayload]);

  const findSuppliers = useMemo(() => {
    return (
      suppliers?.find(
        (supplier) => supplier.id === selectedRmaGetPayload?.supplier_id
      ) || null
    );
  }, [selectedRmaGetPayload, suppliers]);

  const findManufacturer = useMemo(() => {
    return (
      manufacturers.find(
        (manufacturer) =>
          manufacturer.id === selectedRmaGetPayload?.manufacturer_id
      ) || null
    );
  }, [manufacturers, selectedRmaGetPayload]);

  const findCategory = useMemo(() => {
    return (
      categories.find(
        (category) => category.id === selectedRmaGetPayload?.category_id
      ) || null
    );
  }, [categories, selectedRmaGetPayload]);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Grid
        container
        spacing={2}
        style={{ marginTop: '1rem', marginBottom: '1rem' }}
      >
        <Grid item xs={6}>
          <Typography color="textPrimary" variant="h3">
            RMA
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {canCreateRMA ? (
            <Button
              onClick={onClickAddRMA}
              color="primary"
              variant="contained"
              style={{
                marginLeft: 20,
                float: 'right',
                width: isMobile ? 'auto' : '50%'
              }}
              startIcon={<AddIcon />}
            >
              Add RMA
            </Button>
          ) : null}
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        style={{ marginTop: '1rem', marginBottom: '1rem' }}
      >
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            value={selectedRmaGetPayload?.product_name}
            onChange={onToolbarInputChange}
            fullWidth
            name="product_name"
            label="Product Name"
            placeholder="Product Name"
            variant="outlined"
            onKeyPress={onKeyPress}
            style={{ height: '1.1876em', marginBottom: isMobile ? 30 : 0 }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <DatePickerRangeComponent
            fromDateMax={dateToday()}
            fromDateValue={selectRmaGetPayload.date_created_from || null}
            toDateValue={selectRmaGetPayload.date_created_to || null}
            dateToLabel="End Date"
            dateFromLabel="Start Date"
            toDateMin={selectRmaGetPayload.date_created_from || null}
            onChangeToDate={(date) => onChangedDate(date, 'to')}
            onChangeFromDate={(date) => onChangedDate(date, 'from')}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Button
                onClick={onSearchClick}
                color="primary"
                variant="contained"
                startIcon={<SearchIcon />}
                fullWidth
                style={{ height: '4em' }}
              >
                Search RMA
              </Button>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Button
                onClick={onClearSearch}
                color="secondary"
                variant="text"
                startIcon={<ClearIcon />}
                fullWidth
                style={{ height: '4em' }}
              >
                Clear Filter
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {/* ACCORDION */}
        <Grid item xs={12}>
          <Accordion
            style={{
              border: '1px solid rgba(0, 0, 0, 0.12)',
              borderRadius: 8,
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box width="100%" display="flex" justifyContent="center">
                <Typography style={{ marginLeft: 40 }}>
                  ADVANCE FILTER
                </Typography>
                <SettingsIcon style={{ marginLeft: 10 }} />
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Autocomplete
                    id="status"
                    options={rmaOptionsStatuses}
                    getOptionLabel={(option) => option?.label || ''}
                    renderOption={(option) => (
                      <Typography
                        style={{ color: option?.bgColor || undefined }}
                      >
                        {option.label}
                      </Typography>
                    )}
                    value={selectedStatusFilter || null}
                    onChange={(e: any, val) =>
                      onToolbarInputChange({
                        target: { name: 'status', value: val?.value || '' }
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        name="status"
                        label="Status"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    value={selectedRmaGetPayload?.serial_no}
                    onChange={onToolbarInputChange}
                    fullWidth
                    size="small"
                    placeholder="Serial No."
                    label="Serial No."
                    variant="outlined"
                    name="serial_no"
                    onKeyPress={onKeyPress}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    value={selectedRmaGetPayload?.customer_name}
                    onChange={onToolbarInputChange}
                    fullWidth
                    size="small"
                    name="customer_name"
                    placeholder="Customer Name"
                    label="Customer Name"
                    variant="outlined"
                    onKeyPress={onKeyPress}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="location"
                    options={rmaLocationOptions}
                    getOptionLabel={(option) => option?.label || ''}
                    value={selectedLocationFilter || null}
                    onChange={(e: any, val) =>
                      onToolbarInputChange({
                        target: { name: 'location', value: val?.value || '' }
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        name="location"
                        label="Location"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="supplier"
                    options={suppliers || []}
                    getOptionLabel={(option) => option?.name || ''}
                    value={findSuppliers}
                    onChange={(e: any, val) =>
                      onToolbarInputChange({
                        target: { name: 'supplier_id', value: val?.id || '' }
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        name="supplier"
                        label="Supplier"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="category"
                    options={categories}
                    getOptionLabel={(option) => option.name}
                    value={findCategory}
                    onChange={(e: any, val) =>
                      onToolbarInputChange({
                        target: { name: 'category_id', value: val?.id || '' }
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        name="category"
                        label="Category"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="manufacturer"
                    options={manufacturers}
                    getOptionLabel={(option) => option.name}
                    value={findManufacturer}
                    onChange={(e: any, val) =>
                      onToolbarInputChange({
                        target: {
                          name: 'manufacturer_id',
                          value: val?.id || ''
                        }
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        name="manufacturer"
                        label="Manufacturer"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        {/* END OF ACCORDION */}
      </Grid>
      <Divider />

      {/* TODO: Make a component */}
      <Popper
        open={isFilterPopEverOpen}
        anchorEl={anchorEl}
        placement={'bottom'}
        style={{ zIndex: 9999 }}
      >
        <Paper elevation={10} style={{ padding: 12 }}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
            mr={1}
          >
            <Typography variant="h4">Quick Filters</Typography>
            <div>
              <Button
                style={{ marginLeft: 10 }}
                size="small"
                variant="text"
                color="secondary"
                onClick={onFilterPopOverClear}
              >
                Clear
              </Button>
              <Button
                style={{ marginLeft: 10 }}
                size="small"
                variant="text"
                color="primary"
                onClick={onFilterPopOverClose}
              >
                Close
              </Button>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={onApplyFilter}
                style={{ marginLeft: 10 }}
              >
                Apply
              </Button>
            </div>
          </Box>
          <Divider />

          <Typography style={{ marginTop: 20 }} variant="h5">
            Branch
          </Typography>
          <Box mt={1}>
            {quickFilterBranchesChoices?.map((choice) => (
              <Button
                key={choice?.field}
                color="primary"
                style={{ marginRight: 10 }}
                onClick={() => onQuickFilterSelectBranch(choice)}
                variant={isBranchSelected(choice) ? 'contained' : 'outlined'}
              >
                {choice?.value}
              </Button>
            ))}
          </Box>

          <Typography style={{ marginTop: 20 }} variant="h5">
            Quick Views
          </Typography>
          <Box mt={1}>
            {quickFilterViewsChoices?.map((choice) => (
              <Button
                key={choice.id}
                style={{ marginRight: 10 }}
                color="primary"
                onClick={() => onQuickFilterViewSelect(choice)}
                variant={
                  isQuickViewFilterSelected(choice) ? 'contained' : 'outlined'
                }
              >
                {choice.label}
              </Button>
            ))}
          </Box>
        </Paper>
      </Popper>

      {/* TODO: Make a component */}
      <Popper
        open={isColumnsPopOverOpen}
        anchorEl={anchorElColumns}
        placement={'bottom'}
        style={{ zIndex: 9999, width: 500 }}
      >
        <Paper elevation={10} style={{ padding: 12 }}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
            mr={1}
          >
            <Typography variant="h4">Hide / Unhide Columns</Typography>
            <div>
              <Button
                style={{ marginLeft: 10 }}
                size="small"
                variant="text"
                color="secondary"
                onClick={onColumnsPopOverReset}
              >
                Clear
              </Button>
              <Button
                style={{ marginLeft: 10 }}
                size="small"
                variant="text"
                color="primary"
                onClick={onColumnsPopOverClose}
              >
                Close
              </Button>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={onApplyColumns}
                style={{ marginLeft: 10 }}
              >
                Apply
              </Button>
            </div>
          </Box>
          <Divider />

          <Typography style={{ marginTop: 20 }} variant="h5">
            Quick Views
          </Typography>
          <Box mt={1}>
            {tempPopOverColumns?.map((column) => (
              <CheckBoxLabel
                key={column.field}
                label={snakeCaseToTitleCase(column.field)}
                checked={column.visible}
                onChange={(value) => {
                  setTempPopOverColumns((prev) => {
                    const index = prev.findIndex(
                      (item) => item.field === column.field
                    );

                    const newColumns = [...prev];
                    newColumns[index] = {
                      ...newColumns[index],
                      visible: value
                    };

                    return newColumns;
                  });
                }}
              />
            ))}
          </Box>
        </Paper>
      </Popper>
    </div>
  );
};

export default Toolbar;
