import React, { FC } from 'react';
import { GetTransactionsViaTransactionNoResponse, TransactionPrintPageData } from 'src/types';
import {
  Page as PDFPage,
  View,
  StyleSheet,
} from '@react-pdf/renderer';
import { RenderCompanyInfo } from './RenderCompanyInfo';
import { RenderCustomerInfo } from './RenderCustomerInfo';
import { RenderEmployeeInfo } from './RenderEmployeeInfo';
import { RenderFooter } from './RenderFooter';
import { RenderItemsTable } from './RenderItems';
import { RenderPageNoAndTotal } from './RenderPageNoAndTotal';
import { RenderWarrantySlipTitle } from './RenderWarrantySlipTitle';

interface Props {
  pagesData?: TransactionPrintPageData[],
  transactionStateData?: GetTransactionsViaTransactionNoResponse | null,
  dateSold?: string,
  isSIPricing?: boolean,
  totalAmount?: number,
  releasedBy?: string
}

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white'
  },
  container: {
    margin: 15,
    flex: 1
  },
});

export const RenderPages: FC<Props> = ({ pagesData, transactionStateData, dateSold, isSIPricing, totalAmount, releasedBy }) => {
  return (<>
    {pagesData?.map((pageData, i) => (
      <PDFPage key={i} size={[421, 612]} style={styles.page}>
        <View style={styles.container}>
          <RenderCompanyInfo
            storeLocation={transactionStateData?.store_address}
          />
          <RenderWarrantySlipTitle
            orderNo={transactionStateData?.order_no}
            transactionNo={transactionStateData?.transaction_no}
          />
          <RenderCustomerInfo
            dateSold={dateSold}
            customerInfo={transactionStateData?.customer}
            paymentMode={transactionStateData?.payment_mode}
          />
          <RenderItemsTable
            items={pageData?.page?.items}
            kachiPoints={transactionStateData?.kachi_points}
            isLastPage={i === pagesData.length - 1}
          />
          <RenderPageNoAndTotal
            pageNo={pageData?.pageNo}
            pageTotal={pagesData?.length}
            isSIPricing={isSIPricing}
            totalAmount={totalAmount}
          />
          <RenderEmployeeInfo releasedBy={releasedBy} />
          <RenderFooter customerInfo={transactionStateData?.customer} />
        </View>
      </PDFPage>
    ))}
  </>);
};
