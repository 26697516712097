import {
  Box,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  makeStyles
} from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import { useDrawerItemsWithPerms } from 'src/hooks/use-drawer-items-with-perms';
import { DrawerItemLabelEnum } from 'src/layouts/DashboardLayout/types';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';

const { actions: addModulesToListAction } = slices.recentView;
const { actions: addModuleToFavoritesAction } = slices.favorites;

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '0px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    color: theme.palette.text.secondary,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 5px',
    textTransform: 'none',
    width: '100%'
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  nested: {
    paddingLeft: theme.spacing(2)
  }
}));

interface Props {
  open: boolean;
  onClose: () => void;
}

export const DashboardDrawer: FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();
  const { drawerItemsWithPermissions } = useDrawerItemsWithPerms();
  const dispatch = useAppDispatch();

  const favoriteModules = useAppSelector(
    (state) => state.favoriteModules.favoriteModules
  );

  const isFavorite = (href: string) => {
    return favoriteModules.some(
      (module) => module.url === href && module.isFavorite
    );
  };

  const toggleFavorite = (href: string, title: string) => {
    const favoriteExists = isFavorite(href);
    if (favoriteExists) {
      dispatch(
        addModuleToFavoritesAction.removeModulesToFavorite({
          url: href,
          title,
          isFavorite: false
        })
      );
    } else {
      dispatch(
        addModuleToFavoritesAction.addModulesToFavorite({
          url: href,
          title,
          isFavorite: true
        })
      );
    }
  };
  // eslint-disable-next-line no-unused-vars
  // const handleClick = (href: string, title: DrawerItemLabelEnum) => {
  //   dispatch(
  //     addModulesToListAction.addModulesToList({ url: href, title: title })
  //   );
  // };

  const handleClick = (
    event: React.MouseEvent<HTMLLIElement>,
    href: string,
    title: DrawerItemLabelEnum
  ) => {
    event.preventDefault(); // Prevent default navigation

    if (event.ctrlKey || event.metaKey || event.button === 2) {
      dispatch(
        addModulesToListAction.addModulesToList({
          url: href,
          title: title
        })
      );
      window.open(href);
      window.location.href = href;
    } else {
      dispatch(
        addModulesToListAction.addModulesToList({
          url: href,
          title: title
        })
      );
      window.location.href = href;
    }
  };

  // UseEffect for checking if the url is opened or not
  useEffect(() => {
    const currentPath = window.location.pathname;
    const currentDrawerItem = drawerItemsWithPermissions
      ?.flatMap((x) => x.sectionItems)
      .find((x) => x.href === currentPath);

    if (currentDrawerItem) {
      dispatch(
        addModulesToListAction.addModulesToList({
          url: currentDrawerItem.href,
          title: currentDrawerItem.title
        })
      );
    }
  }, [drawerItemsWithPermissions, dispatch]);

  return (
    <Drawer open={open} onClose={onClose}>
      <Box width={'280px'}>
        <List>
          {drawerItemsWithPermissions[0] &&
            drawerItemsWithPermissions.map((i) => (
              <>
                <div key={i?.sectionTitle}>
                  <ListItem className={classes.title}>
                    {i.sectionTitle}
                  </ListItem>
                  {i.sectionItems[0] &&
                    i.sectionItems.map((j, ind) => (
                      <ListItem
                        className={classes.nested}
                        key={ind}
                        onClick={(event) => handleClick(event, j.href, j.title)}
                      >
                        {/* Toggle Favorites */}
                        <Button
                          style={{
                            width: 'fit-content',
                            minWidth: 'auto',
                            padding: '4px',
                            fontSize: '20px'
                          }}
                          onClick={(event) => {
                            event.stopPropagation();
                            toggleFavorite(j.href, j.title);
                          }}
                        >
                          {isFavorite(j.href) ? (
                            <StarIcon
                              color="primary"
                              style={{ fontSize: '20px' }}
                            />
                          ) : (
                            <StarBorderIcon style={{ fontSize: '20px' }} />
                          )}
                        </Button>

                        <Button
                          disableRipple
                          className={classes.button}
                          href={j.href}
                        >
                          {j.icon ? (
                            <j.icon className={classes.icon} fontSize="small" />
                          ) : null}
                          {j.title}
                        </Button>
                      </ListItem>
                    ))}
                </div>
                <Divider style={{ margin: '1em 0' }} />
              </>
            ))}
        </List>
      </Box>
    </Drawer>
  );
};
