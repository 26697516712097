import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  colors,
  Tooltip,
  Button
} from '@material-ui/core';
import { AlternatingColorTableRow, LinkComponent } from 'src/components';
import {
  copyToClipboard,
  formatCurrency,
  downloadCSV,
  formatCurrencyCSV
} from 'src/utils';
import {
  GetOutOfStocksProdPayload,
  OutOfStockProducts
} from 'src/redux/slices/out-of-stocks-product/types';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import GetAppIcon from '@material-ui/icons/GetApp';
import { PaginationV2 } from 'src/types';

interface Props {
  className?: string;
  meta?: PaginationV2;
  params?: GetOutOfStocksProdPayload;
  outOfStockProducts: any[];
  getOutOfStockForCSV: (requestParams?: GetOutOfStocksProdPayload) => any; // any ts bandaid
}

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  tableRow: {
    height: 35,
    padding: 10
  },
  priceInput: {
    height: 30
  },
  lightGrey: {
    backgroundColor: colors.blueGrey[50]
  },
  retailPriceCell: {
    width: 200
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const component = ({
  className,
  outOfStockProducts = [],
  meta = {},
  params,
  getOutOfStockForCSV,
  ...rest
}: Props) => {
  const classes = useStyles();

  const hasOutOfStocks = useMemo(() => outOfStockProducts[0], [
    outOfStockProducts
  ]);

  const onHandleCopyProductName = (productName?: string) => {
    copyToClipboard(productName || '');
  };

  const onExportToCSV = useCallback(async () => {
    if (!hasOutOfStocks) {
      console.log('No data to export');
      return;
    }
    let i = meta?.last_page ? meta.last_page : 0; // last page reference
    let j = 1; // counter
    let updatedCSV: OutOfStockProducts[] = [];

    for (; i >= j; j++) {
      const res = await getOutOfStockForCSV({ ...params, page: j });
      res ? (updatedCSV = [...updatedCSV, ...res]) : null;
    }

    // Set for efficient unique name check
    const allBranches = new Set<string>();
    updatedCSV.forEach((p) => {
      const { recently_out_of_stocks } = p;
      if (!recently_out_of_stocks) {
        return;
      }
      recently_out_of_stocks.forEach((b: any) =>
        allBranches.add(b.branch_name)
      );
    });

    const csvFile = updatedCSV.map(
      ({
        category_name: Category,
        product_name: Product,
        retail_price,
        dealers_price,
        recently_out_of_stocks
      }: OutOfStockProducts) => {
        const productBranches: { [key: string]: string } = {};
        allBranches.forEach(
          (b) =>
            (productBranches[b] =
              recently_out_of_stocks?.find((i) => i.branch_name === b)
                ?.out_of_stock_date_human_format ?? 'n/a')
        );

        return {
          Category,
          Product,
          ...productBranches,
          Retail_Price: formatCurrencyCSV(retail_price),
          Dealers_Price: formatCurrencyCSV(dealers_price)
        };
      }
    );
    downloadCSV(csvFile, 'Out_Of_Stock_Products.csv');
  }, [getOutOfStockForCSV, hasOutOfStocks, meta.last_page, params]);

  return (
    <>
      {hasOutOfStocks ? (
        <Card style={{ margin: '1em 0' }}>
          <Box
            className={classes.flexBox}
            padding={'1rem'}
            justifyContent={'space-between'}
          >
            <Button
              disabled={!hasOutOfStocks}
              variant="outlined"
              color="primary"
              startIcon={<GetAppIcon />}
              onClick={onExportToCSV}
            >
              Export CSV
            </Button>
          </Box>
        </Card>
      ) : null}
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table size="small">
              <TableHead>
                <TableRow className={classes.tableRow}>
                  <TableCell>Category</TableCell>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Branches</TableCell>
                  <TableCell>Out Of Stock At</TableCell>
                  <TableCell>Retail Price</TableCell>
                  <TableCell>Dealers Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {outOfStockProducts.map((item: OutOfStockProducts) => (
                  <AlternatingColorTableRow hover key={item.id}>
                    <TableCell>{item?.category_name ?? '--'}</TableCell>
                    <TableCell>
                      <Button
                        style={{ padding: 0, margin: 0 }}
                        startIcon={<FileCopyIcon />}
                        onClick={() =>
                          onHandleCopyProductName(item?.product_name)
                        }
                      />
                      <LinkComponent
                        href={`/app/products/${item.id}`}
                        title={`${item?.product_name ?? '--'}`}
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        padding: 0
                      }}
                    >
                      {item.recently_out_of_stocks?.map((prod, index) => (
                        <TableRow key={index}>
                          <TableCell>{prod?.branch_name}</TableCell>
                        </TableRow>
                      ))}
                    </TableCell>
                    <TableCell>
                      {item.recently_out_of_stocks?.map((prod, index) => (
                        <Tooltip
                          title={`${prod?.out_of_stock_date}` || ''}
                          key={index}
                        >
                          <TableRow key={index}>
                            <TableCell className={classes.tableRow}>
                              {prod?.out_of_stock_date_human_format}
                            </TableCell>
                          </TableRow>
                        </Tooltip>
                      ))}
                    </TableCell>
                    <TableCell>
                      {formatCurrency(item?.retail_price) || '--'}
                    </TableCell>
                    <TableCell>
                      {formatCurrency(item?.dealers_price) || '--'}
                    </TableCell>
                  </AlternatingColorTableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
    </>
  );
};

export const OutOfStockProduct = React.memo(component);
