import React, { useCallback, useEffect } from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField
} from '@material-ui/core';
import { TapTapCustomerFormField } from 'src/redux/slices/taptap-customer/types';
import { DeletableImg, DragAndDropImgUpload } from 'src/components';
import { Autocomplete } from '@material-ui/lab';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { taptapGetProfilesThunk } from 'src/redux/slices/taptap-customer';
import { getBranchColor } from 'src/utils';

interface Props {
  formikHookRef: any;
  formFields: TapTapCustomerFormField[];
}

const { selectors: taptapCustomerSelectors } = slices.taptapCustomer;

const TapTapForm = ({ formikHookRef, formFields }: Props) => {
  const dispatch = useAppDispatch();
  const profiles = useAppSelector(taptapCustomerSelectors.selectProfiles);

  const fetchTapTapProfiles = async () => {
    dispatch(taptapGetProfilesThunk());
  };

  const autocompleteFieldOptions = useCallback(
    (fieldKey: string) => {
      const foundField = formFields.find((field) => field.key === fieldKey);

      if (!foundField) {
        return [];
      }

      if (foundField?.type === 'profile') {
        return profiles.map((profile) => ({
          value: profile.display_name || '',
          label: profile.display_name || ''
        }));
      }

      return foundField?.options || [];
    },
    [formFields, profiles]
  );

  useEffect(() => {
    fetchTapTapProfiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <form autoComplete="off">
        <Grid container spacing={3}>
          {formFields?.map((field) => {
            if (field?.conditional) {
              const dependentValue =
                formikHookRef?.values[field?.conditional?.dependsOn];
              if (!field?.conditional?.value?.includes(dependentValue)) {
                // Skip rendering this field if the condition is not met
                return null;
              }
            }

            if (field?.hidden) {
              return null;
            }

            return (
              <Grid
                item
                xs={12}
                key={field?.key}
                md={field?.grid === 'half' ? 6 : undefined}
              >
                {field?.type === 'text' ||
                field?.type === 'number' ||
                field?.type === 'longText' ? (
                  <TextField
                    disabled={field?.disabled}
                    id={field?.key}
                    fullWidth
                    label={field?.label}
                    name={field?.key}
                    type={field?.type}
                    multiline={field?.type === 'longText'}
                    minRows={field?.type === 'longText' ? 3 : undefined}
                    onChange={formikHookRef.handleChange}
                    required={field?.required || false}
                    value={formikHookRef.values[field?.key] || ''}
                    onBlur={formikHookRef.handleBlur}
                    variant="outlined"
                    error={formikHookRef.errors[field?.key] ? true : false}
                    helperText={formikHookRef.errors[field?.key] || undefined}
                  />
                ) : null}

                {field?.type === 'autocomplete' || field?.type === 'profile' ? (
                  <Autocomplete
                    id={field?.key}
                    disabled={field?.disabled}
                    options={autocompleteFieldOptions(field?.key)}
                    getOptionLabel={(option) => option?.label || option || ''}
                    getOptionSelected={(option, value) =>
                      option.value === value
                    }
                    value={formikHookRef.values[field?.key] || ''} // Ensure formikHookRef's value is valid
                    onChange={(event, value) => {
                      formikHookRef.setFieldValue(
                        field?.key,
                        value?.value || ''
                      ); // Store only the primitive value
                    }}
                    onBlur={() =>
                      formikHookRef.setFieldTouched(field?.key, true)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={field?.label}
                        variant="outlined"
                        error={
                          formikHookRef.touched[field?.key] &&
                          Boolean(formikHookRef.errors[field?.key])
                        }
                        disabled={field?.disabled}
                        helperText={
                          formikHookRef.touched[field?.key] &&
                          formikHookRef.errors[field?.key]
                        }
                      />
                    )}
                  />
                ) : null}

                {field?.type === 'boolean' && (
                  <>
                    <FormControlLabel
                      disabled={field?.disabled}
                      control={
                        <Checkbox
                          id={field?.key}
                          name={field?.key}
                          checked={
                            formikHookRef.values[field?.key] ? true : false
                          }
                          onChange={formikHookRef.handleChange}
                          onBlur={formikHookRef.handleBlur}
                        />
                      }
                      label={field?.label}
                    />
                    {formikHookRef.errors[field?.key] ? (
                      <FormHelperText error>
                        {formikHookRef.errors[field?.key]}
                      </FormHelperText>
                    ) : null}
                  </>
                )}

                {field?.type === 'radio' && (
                  <>
                    <FormLabel id={field?.key}>{field?.label}</FormLabel>
                    <RadioGroup
                      row
                      id={field?.key}
                      name={field?.key}
                      value={formikHookRef.values[field?.key] || ''}
                      defaultValue={formikHookRef.values[field?.key]}
                      onChange={formikHookRef.handleChange}
                    >
                      {field?.options?.map((option: any) => (
                        <FormControlLabel
                          disabled={field?.disabled}
                          id={option?.value}
                          key={option?.value}
                          value={option?.value}
                          control={
                            <Radio
                              onClick={() => {
                                // Check if the current option is already selected
                                if (
                                  formikHookRef.values[field?.key] ===
                                  option.value
                                ) {
                                  formikHookRef.setFieldValue(field?.key, ''); // Unselect by setting to an empty string
                                } else {
                                  formikHookRef.setFieldValue(
                                    field?.key,
                                    option.value
                                  ); // Select the new value
                                }
                              }}
                            />
                          }
                          label={
                            <span style={{ color: getBranchColor(option?.value) }}>
                              {option?.label}
                            </span>}
                        />
                      ))}
                    </RadioGroup>

                    {formikHookRef?.errors[field?.key] && (
                      <FormHelperText error>
                        {formikHookRef?.errors[field?.key]}
                      </FormHelperText>
                    )}
                  </>
                )}

                {field?.type === 'image' && (
                  <>
                    <Grid container spacing={2}>
                      {formikHookRef.values[field?.key]?.map(
                        (img: any, i: any) => (
                          <Grid key={img?.img_url} item>
                            <DeletableImg
                              index={i}
                              imgSrc={img?.img_url}
                              onDelete={() => {
                                const newImages = formikHookRef.values[
                                  field?.key
                                ].filter(
                                  (image: any, index: any) => index !== i
                                );
                                formikHookRef.setFieldValue(
                                  field?.key,
                                  newImages
                                );
                              }}
                            />
                          </Grid>
                        )
                      )}
                    </Grid>
                    <DragAndDropImgUpload
                      title="Drag or select photos"
                      onImageSelected={(newImgs: any) => {
                        const newImages = newImgs?.map((img: File) => ({
                          file: img,
                          img_url: URL.createObjectURL(img),
                          created_by: undefined,
                          created_at: new Date().toISOString()
                        }));

                        if (!formikHookRef.values[field?.key]) {
                          //  Minsan kasi nag na null eh :(
                          formikHookRef.setFieldValue(field?.key, newImages);
                        } else {
                          formikHookRef.setFieldValue(field?.key, [
                            ...formikHookRef.values[field?.key],
                            ...newImages
                          ]);
                        }
                      }}
                    />
                  </>
                )}
              </Grid>
            );
          })}
        </Grid>
      </form>
    </React.Fragment>
  );
};

export default TapTapForm;
