import React, { useCallback, useMemo } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { colors, srpOptions } from 'src/constants';
import { Quotation } from 'src/types';
import { formatCurrency, promoPriceToSRP } from 'src/utils';
import { forEach, isEmpty } from 'lodash';
import clsx from 'clsx';
import { SRPOption } from 'src/types/srp-options';

interface Props {
  showCleanSrpQuote: boolean;
  srpValue?: SRPOption;
  isSrpLoading?: boolean;
  totalAmount?: number;
  items: Quotation[];
  showSRP: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  extraInfo: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.default,

    backgroundSize: '80px 70px',
    backgroundRepeat: 'repeat',
    background:
      'linear-gradient(rgba(255,255,255,.95), rgba(255,255,255,.95)), url("/static/images/avatars/pcworth_logo.png")'
  },
  disclaimerContainer: {
    fontWeight: 500,
    letterSpacing: 0.75,
    fontSize: 12,
    color: colors.error
  },
  scammers: {
    fontWeight: 700,
    fontSize: 14
  },
  discountedPriceTxt: {
    color: colors.success
  }
}));

export const TotalPrice = ({
  items,
  showSRP,
  showCleanSrpQuote,
  srpValue,
  isSrpLoading,
  totalAmount = 0
}: Props) => {
  const classes = useStyles();

  const itemPrice = useCallback(
    (param: number | undefined, type: 'srp' | 'pp') => {
      if (type === 'pp') {
        return param || 0;
      }

      if (type === 'srp') {
        return promoPriceToSRP(param);
      }

      return 0;
    },
    []
  );

  const onComputeItemPrice = useCallback(
    (price_key: 'srp' | 'pp') => {
      let total = 0;

      if (isEmpty(items)) {
        return total;
      }

      forEach(items, (item) => {
        const item_price = itemPrice(item?.product?.retail_price, price_key);
        total += item.quantity * item_price;
      });

      return total;
    },
    [itemPrice, items]
  );

  const srpLabel = useMemo(() => srpValue?.name, [srpValue]);

  const totalPromoPriceNum = useMemo(() => {
    return onComputeItemPrice('pp');
  }, [onComputeItemPrice]);

  const totalPromoPriceLabel = useMemo(() => {
    const label = showSRP ? 'TOTAL PROMO PRICE' : 'TOTAL PRICE';
    const totalStr = formatCurrency(totalPromoPriceNum) ?? `0.00`;
    return `${totalStr} ${label}`;
  }, [showSRP, totalPromoPriceNum]);

  const totalStandardPriceLabel = useMemo(() => {
    let label = '';

    if (showSRP) {
      label = `TOTAL PRICE (${srpLabel})`;
      // eslint-disable-next-line no-dupe-else-if
    } else if (showSRP && !showCleanSrpQuote) {
      label = 'TOTAL STANDARD PRICE';
    } else {
      label = 'TOTAL PRICE';
    }
    const totalStr = formatCurrency(totalAmount);
    return `${totalStr} ${label}`;
  }, [showCleanSrpQuote, showSRP, srpLabel, totalAmount]);

  const totalDiscountedPrice = useMemo(() => {
    const totalStr = totalAmount - totalPromoPriceNum;
    const label = 'TOTAL DISCOUNTED PRICE';
    return `${formatCurrency(totalStr)} ${label}`;
  }, [totalPromoPriceNum, totalAmount]);

  return (
    <div className={classes.extraInfo}>
      <div>
        <Typography
          className={clsx(classes.disclaimerContainer, classes.scammers)}
        >
          BEWARE OF SCAMMERS!!! ONLY TRANSACT THROUGH OUR OFFICIAL FACEBOOK PAGE
          AND LEGITIMATE CONTACT NUMBERS PROVIDED ABOVE
        </Typography>
      </div>

      {!isSrpLoading ? (
        <div>
          {showSRP ? (
            <Typography variant="h6">{totalStandardPriceLabel}</Typography>
          ) : null}

          {!showCleanSrpQuote ? (
            <Typography variant="h6">{totalPromoPriceLabel}</Typography>
          ) : null}

          {showSRP &&
          !showCleanSrpQuote &&
          srpLabel !== srpOptions?.with2307?.name ? (
            <Typography className={classes.discountedPriceTxt} variant="h6">
              {totalDiscountedPrice}
            </Typography>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export const QuotationTotalPrice = React.memo(TotalPrice);
