import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import { format, parseISO } from 'date-fns';

import { Calendar } from 'react-big-calendar';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import { downloadCSV, localizer, monthNames } from 'src/utils';
import { useFastMovingProducts } from 'src/hooks/fast-moving-products';
import { SalesTimelineData } from 'src/redux/slices/fast-moving-products';
import useResolution from 'src/hooks/useResolution';
import { Alert } from '@material-ui/lab';

interface Props {
  isOpen: boolean;
  onHandleClose: () => void;
}

const Component: FC<Props> = ({ isOpen, onHandleClose }) => {
  const {
    isLoading,
    soldTimeData,
    soldTimeLineParams,
    getFastMovingSoldTimeline
  } = useFastMovingProducts();
  const { xs } = useResolution();

  const [currentDate, setCurrentDate] = useState(new Date());
  const [monthFilter, setMonthFilter] = useState<any>('');

  const goToMonthYear = (monthYear: string) => {
    const [month, year] = monthYear.split('-').map(Number);
    setCurrentDate(new Date(year, month - 1, 1));
    setMonthFilter(monthYear);
  };

  const onChangeMonthFilter = (e: any) => {
    goToMonthYear(e?.target?.value);
  };

  const events = useMemo(() => {
    const eventsData = soldTimeData?.sales?.map((sale) => ({
      title: `Sold: ${sale?.sold_quantity}`,
      start: new Date(sale?.sale_date || ''),
      end: new Date(sale?.sale_date || ''),
      allDay: true
    }));

    return eventsData;
  }, [soldTimeData]);

  const uniqueMonthsOption = useMemo(() => {
    const uniqueMonthsYears = soldTimeData?.sales?.reduce(
      (acc: string[], { sale_date }) => {
        if (sale_date && /^\d{4}-\d{2}-\d{2}$/.test(sale_date)) {
          const [year, month] = sale_date.split('-');
          const formattedDate = `${month}-${year}`;
          if (!acc.includes(formattedDate)) {
            acc.push(formattedDate);
          }
        }
        return acc;
      },

      []
    );

    return uniqueMonthsYears;
  }, [soldTimeData]);

  const noResult = useMemo(
    () => uniqueMonthsOption && uniqueMonthsOption?.length <= 0,
    [uniqueMonthsOption]
  );

  const filteredRangeLabel = useMemo(() => {
    return `Filtered  by date from ${format(
      parseISO(soldTimeLineParams?.from_date || ''),
      'MMM d, yyyy'
    )} to ${format(
      parseISO(soldTimeLineParams?.to_date || ''),
      'MMM d, yyyy'
    )}`;
  }, [soldTimeLineParams]);

  const onExportToCSVFastMovingSoldDateTimeline = (
    sold_data?: SalesTimelineData
  ) => {
    const csvName = 'FastMovingProduct_Sold_Timeline.csv';

    if (sold_data?.sales) {
      // Extract headers (sale dates)
      const saleDates = sold_data.sales.map(({ sale_date }) => {
        const [year, month, day] = sale_date
          ? sale_date.split('-')
          : ['', '', ''];
        return `${monthNames[Number(month) - 1]}-${day}-${year}`;
      });

      // Build CSV content
      let csvContent = `Product Name,${saleDates.join(',')}\n`;
      csvContent += `${sold_data.product_name},${sold_data.sales
        .map(({ sold_quantity }) => sold_quantity)
        .join(',')}\n`;

      // Trigger CSV download
      const csvArray = csvContent.split('\n').map((row) => row.split(','));
      downloadCSV(csvArray, csvName);
    }
  };

  useEffect(() => {
    if (isOpen && soldTimeLineParams) {
      getFastMovingSoldTimeline(soldTimeLineParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, soldTimeLineParams]);

  //initialize first view of calendar
  useEffect(() => {
    if (isOpen && soldTimeData && uniqueMonthsOption && uniqueMonthsOption[0]) {
      goToMonthYear(uniqueMonthsOption[0] || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, soldTimeData, uniqueMonthsOption]);

  if (isLoading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }

  return (
    <div>
      <Dialog fullWidth maxWidth={'lg'} open={isOpen} onClose={onHandleClose}>
        <Grid
          container
          style={{
            padding: '1em',
            position: 'relative'
          }}
        >
          <IconButton
            onClick={onHandleClose}
            style={{ position: 'absolute', top: 10, right: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <Grid item md={12} lg={12} style={{ padding: '1rem' }}>
            <Typography variant={xs ? 'h4' : 'h1'}>
              {soldTimeData?.product_name}
            </Typography>
            <Typography variant="h5" style={{ marginTop: 5 }}>
              {filteredRangeLabel}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            lg={12}
            style={{
              padding: '1rem',
              display: 'flex',
              flexDirection: xs ? 'column' : 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              startIcon={<GetAppIcon />}
              onClick={() =>
                onExportToCSVFastMovingSoldDateTimeline(soldTimeData)
              }
              fullWidth={xs ? true : false}
              disabled={noResult}
            >
              Export CSV
            </Button>
            <FormControl
              variant="outlined"
              style={{ minWidth: xs ? '100%' : 200, marginTop: 10 }}
            >
              <InputLabel>Months with sold qty</InputLabel>
              <Select
                value={monthFilter}
                onChange={(e) => onChangeMonthFilter(e)}
                label="Months with sold qty"
                disabled={noResult}
              >
                {uniqueMonthsOption?.map((option, index) => {
                  const [month, year] = option.split('-');
                  const date = new Date(Number(year), Number(month) - 1);
                  const formattedLabel = date.toLocaleString('en-US', {
                    month: 'long',
                    year: 'numeric'
                  });

                  return (
                    <MenuItem key={index} value={option}>
                      {formattedLabel}
                    </MenuItem>
                  );
                })}
                {noResult ? (
                  <MenuItem value={''}>No Available Options</MenuItem>
                ) : null}
              </Select>
            </FormControl>
          </Grid>
          {soldTimeData &&
          soldTimeData?.sales &&
          soldTimeData?.sales?.length <= 0 ? (
            <Grid item xs={12} lg={12} style={{ marginBottom: 5 }}>
              <Alert severity="warning">{`No sold products found for ${filteredRangeLabel}`}</Alert>
            </Grid>
          ) : null}
          <Grid item lg={12} style={{ height: '50vh', overflow: 'scroll' }}>
            <Calendar
              style={{
                border: '1px solid gray',
                padding: '1em',
                width: '100%',
                minWidth: '700px'
              }}
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              views={['month']}
              defaultView="month"
              date={currentDate}
              onNavigate={(newDate) => setCurrentDate(newDate)}
              components={{
                toolbar: ({ label, onNavigate }) => {
                  const fromDate = new Date(
                    `${soldTimeLineParams?.from_date}T00:00:00`
                  );
                  const toDate = new Date(
                    `${soldTimeLineParams?.to_date}T23:59:59`
                  );

                  return (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '10px',
                        fontSize: '1.2rem',
                        fontWeight: 'bold'
                      }}
                    >
                      {/* Back Button */}
                      <Button
                        variant="outlined"
                        onClick={() => onNavigate('PREV')}
                        disabled={currentDate <= fromDate || noResult}
                        style={{
                          padding: '5px 10px',
                          cursor:
                            currentDate <= fromDate ? 'not-allowed' : 'pointer',
                          opacity: currentDate <= fromDate ? 0.5 : 1
                        }}
                      >
                        ← Back
                      </Button>

                      {/* Month Label */}
                      <span>{label}</span>

                      {/* Next Button */}
                      <Button
                        variant="outlined"
                        onClick={() => onNavigate('NEXT')}
                        disabled={currentDate >= toDate || noResult} //trust me the calculation here is weird XD
                        style={{
                          padding: '5px 10px',
                          cursor:
                            currentDate >= toDate ? 'not-allowed' : 'pointer',
                          opacity: currentDate >= toDate ? 0.5 : 1
                        }}
                      >
                        Next →
                      </Button>
                    </div>
                  );
                }
              }}
              dayPropGetter={(date) => {
                const fromDate = new Date(
                  `${soldTimeLineParams?.from_date}T00:00:00`
                );
                const toDate = new Date(
                  `${soldTimeLineParams?.to_date}T23:59:59`
                );

                if (date < fromDate || date > toDate) {
                  return {
                    style: {
                      backgroundColor: '#f0f0f0',
                      color: '#bbb'
                    }
                  };
                } else {
                  return {
                    style: {
                      backgroundColor: '#fff8b3'
                    }
                  };
                }
              }}
            />
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
};

export const BigCalendar = memo(Component);
