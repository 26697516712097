import React, { useCallback, useMemo, useState } from 'react';
import {
  IconButton,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Typography,
  withStyles
} from '@material-ui/core';
import {
  TapTapColumn,
  TapTapCustomerData,
  TapTapCustomerPayment
} from 'src/redux/slices/taptap-customer/types';
import { LinkComponent } from 'src/components';
import { useNavigate } from 'react-router';
import { formatCurrency, formatDate, getBranchColor } from 'src/utils';
import { taptapCustomerField } from 'src/redux/slices/taptap-customer/constants/taptap-fields';
import { colors } from 'src/constants';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import TapTapCustomerPaymentRow from './TapTapCustomerPaymentRow';
import { slices, useAppSelector } from 'src/redux';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import {
  taptapOptionsStatuses,
  taptapStatuses,
  taptapUpdateCustomerViaId
} from 'src/redux/slices/taptap-customer';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

interface Props {
  customer: TapTapCustomerData;
  onClickEditPayment: (payment: TapTapCustomerPayment) => void;
}

const { selectors: taptapCustomerSelectors } = slices.taptapCustomer;

const StyledSelect = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(1),
    paddingLeft: 20,
    marginRight: 0,
    textTransform: 'uppercase',
    width: 170
  }
}))(Select);

const TapTapCustomerRow = ({ customer, onClickEditPayment }: Props) => {
  const navigate = useNavigate();
  const taptapColumns = useAppSelector(
    taptapCustomerSelectors.selectTaptapCustomerColumns
  );

  const [isPaymentsCollapsibleOpen, setIsPaymentsCollapsibleOpen] = useState<
    boolean
  >(false);

  const updatedCustomerDataOnSupabase = useCallback(
    async (columnName: string, value: any) => {
      const updatedData = { [columnName]: value };

      if (customer?.id) {
        await taptapUpdateCustomerViaId(customer?.id, updatedData);
      }
    },
    [customer.id]
  );

  const onChangeStatus = useCallback(
    (value: string) => {
      updatedCustomerDataOnSupabase(taptapCustomerField.STATUS, value);
    },
    [updatedCustomerDataOnSupabase]
  );

  const forBuildAllowedStatuses = useMemo(() => {
    // Allowed statues are only. BUT Later on it will change depending on the board.
    return taptapOptionsStatuses?.filter(
      (status) =>
        status.value === taptapStatuses.BUILDING ||
        status.value === taptapStatuses.TESTING ||
        status.value === taptapStatuses.COMPLETED ||
        status.value === taptapStatuses.ON_QUEUE ||
        status.value === taptapStatuses.FOR_FULLPAYMENT ||
        status.value === taptapStatuses.FOR_PACK ||
        status.value === taptapStatuses.READY_FOR_PICKUP ||
        status.value === taptapStatuses.DONE_PAYMENT ||
        status.value === taptapStatuses.ONGOING_DELIVERY ||
        status.value === taptapStatuses.FOR_PAYMENT ||
        status.value === taptapStatuses.HOLD ||
        status.value === taptapStatuses.FOR_CHECKING ||
        status.value === taptapStatuses.PREPARING_ITEMS ||
        status.value === taptapStatuses.CANCELLED ||
        status.value === taptapStatuses.READY_FOR_DELIVERY
    );
  }, []);

  const customBgCellColor = useCallback(
    (column: TapTapColumn) => {
      if (column.sticky) {
        return colors.grey[300];
      }
      if (column.field === taptapCustomerField.STATUS) {
        const statusBgColor = taptapOptionsStatuses?.find(
          (status) => status.value === customer?.status
        )?.bgColor;

        return statusBgColor || 'inherit';
      }
      if (column.field === taptapCustomerField.BRANCH_RELEASED) {
        const branchColor = getBranchColor(customer?.branch_released);
        return branchColor;
      }
      return 'inherit';
    },
    [customer.branch_released, customer.status]
  );

  const RenderCustomRow = useCallback(
    (fieldName: string) => {
      if (fieldName === taptapCustomerField.INTERNAL_TRANSACTION_NO) {
        return (
          <LinkComponent
            openInNewTab
            title={
              <Typography variant="inherit" style={{ color: colors.link }}>
                {customer?.internal_transaction_no}
              </Typography>
            }
            href={`/app/transaction/${customer?.internal_transaction_no}`}
            onClick={() =>
              navigate(`/app/transaction/${customer?.internal_transaction_no}`)
            }
          />
        );
      }
      if (fieldName === taptapCustomerField.FIRST_NAME) {
        return (
          <LinkComponent
            openInNewTab
            title={
              <Typography variant="inherit" style={{ color: colors.link }}>
                {customer?.first_name}
              </Typography>
            }
            href={`/app/customer-taptap/${customer?.id}`}
            onClick={() => navigate(`/customer-app/taptap/${customer?.id}`)}
          />
        );
      }
      if (fieldName === taptapCustomerField.BALANCE) {
        const dbBalance = customer?.balance || 0;
        const hasBalance = Math.round(dbBalance) !== 0;
        return (
          <Typography
            style={{
              color: hasBalance ? colors.error : colors.success,
              fontWeight: hasBalance ? 'bold' : 'inherit',
              fontSize: hasBalance ? 18 : 'inherit'
            }}
          >
            {formatCurrency(dbBalance)}
          </Typography>
        );
      }
      if (fieldName === taptapCustomerField.STATUS) {
        return (
          <StyledSelect
            variant="standard"
            id={taptapCustomerField.STATUS}
            name={taptapCustomerField.STATUS}
            value={customer?.status}
            disableUnderline
            onChange={(e) => {
              if (
                e.target.value &&
                e.target.value !== customer?.status &&
                typeof e.target.value === 'string'
              ) {
                onChangeStatus(e.target.value);
              }
            }}
            inputProps={{
              IconComponent: (props: any) => (
                <ArrowDropDownIcon
                  {...props}
                  style={{ position: 'absolute' }}
                />
              )
            }}
          >
            {forBuildAllowedStatuses.map((status) => (
              <MenuItem
                dense
                value={status.value}
                key={status.value}
                style={{ backgroundColor: status.bgColor || 'inherit' }}
              >
                {status.label}
              </MenuItem>
            ))}
          </StyledSelect>
        );
      }
    },
    [
      customer.balance,
      customer.first_name,
      customer.id,
      customer.internal_transaction_no,
      customer.status,
      forBuildAllowedStatuses,
      navigate,
      onChangeStatus
    ]
  );

  // eslint-disable-next-line no-unused-vars
  const RenderRows = useCallback(
    () =>
      taptapColumns?.map((column) => {
        if (!column?.visible) {
          return null;
        }

        return (
          <TableCell
            style={{
              position: column.sticky ? 'sticky' : 'static',
              left: column.sticky ? 80 : 'auto',
              backgroundColor: customBgCellColor(column),
              zIndex: column.sticky ? 1 : 'auto',
              borderRight: `1px solid ${colors.grey[300]}`,
              minWidth: 200,
              minHeight: 20
            }}
            key={column.field}
          >
            {column?.type === 'custom' && RenderCustomRow(column.field)}

            {column?.type === 'text' && customer[column.field]}

            {column?.type === 'date' &&
              formatDate(customer[column.field], 'MMM d, yyyy h:mm a')}

            {column?.type === 'currency' &&
              formatCurrency(customer[column.field])}

            {column?.type === 'boolean' && (
              <div>
                {customer[column.field] === true ? (
                  <CheckIcon style={{ color: colors.success }} />
                ) : (
                  <ClearIcon style={{ color: colors.error }} />
                )}
              </div>
            )}
          </TableCell>
        );
      }, []),
    [RenderCustomRow, customBgCellColor, customer, taptapColumns]
  );

  return (
    <React.Fragment key={customer?.id}>
      <TableRow hover>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            position: 'sticky',
            left: 0,
            backgroundColor: colors.grey[300],
            zIndex: 1,
            borderRight: `1px solid ${colors.grey[300]}`
          }}
        >
          <IconButton
            aria-label="expand row"
            size="medium"
            onClick={() =>
              setIsPaymentsCollapsibleOpen(!isPaymentsCollapsibleOpen)
            }
          >
            <LocalAtmIcon />
          </IconButton>
        </TableCell>
        {RenderRows()}
      </TableRow>

      {/* Payments Table Rows */}
      <TapTapCustomerPaymentRow
        transactionNo={customer?.internal_transaction_no}
        customerPayments={customer?.customers_payments}
        isPaymentsCollapsibleOpen={isPaymentsCollapsibleOpen}
        onClickEditPayment={onClickEditPayment}
      />
    </React.Fragment>
  );
};

export default TapTapCustomerRow;
