import { SupabaseClientService } from 'src/services';
import { taptapCustomerField } from './constants/taptap-fields';

export const taptapFetchCustomers = () => {
  return SupabaseClientService.from('customers')
    .select(`*, customers_payments(*)`, { count: 'exact' })
    .filter('is_deleted', 'eq', false)
    .filter('customers_payments.is_deleted', 'eq', false);
};

export const taptapGetProfiles = async () =>
  SupabaseClientService.from('profiles')
    .select('*')
    .order('display_name', { ascending: true });

export const taptapGetCustomerDetailsService = async (id: number) =>
  SupabaseClientService.from('customers')
    .select(`*, customers_payments(*)`)
    .eq('id', id)
    .filter('customers_payments.is_deleted', 'eq', false)
    .order('created_at', {
      ascending: true,
      referencedTable: 'customers_payments'
    });

export const taptapGetCustomerDetailsViaTransactionNo = async (
  transactionNo: string
) =>
  SupabaseClientService.from('customers')
    .select()
    .filter('is_deleted', 'eq', false)
    .filter(taptapCustomerField.INTERNAL_TRANSACTION_NO, 'eq', transactionNo); // INTENDED TO BE NOT SINGLE()

export const taptapUpdateCustomerViaId = (id: number, newData: any) => {
  return SupabaseClientService.from('customers')
    .update(newData)
    .eq('id', id)
    .select();
};

export const taptapFetchCustomerAuditService = (id: number) => {
  return SupabaseClientService.schema('audit')
    .from('record_version')
    .select(`*`)
    .eq('table_schema', 'public')
    .or(`table_name.eq.customers,table_name.eq.customers_payments`)
    .or(`record->>id.eq.${id},old_record->>id.eq.${id}`)
    .order('ts', { ascending: false });
};
