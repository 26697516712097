import Api from 'src/services/ApiService';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import { CommonAxiosResponse } from 'src/types';
import {
  CalendarParamsState,
  FastMovingProductsPayload,
  FastMovingProductsResponse,
  SalesTimeLineResponse
} from './types';

export const getFastMovingProductsThunk = createAsyncThunk<
  CommonAxiosResponse<FastMovingProductsResponse>,
  FastMovingProductsPayload,
  { state: RootState }
>('fast-moving/getFastMovingProductsThunk', async (data) => {
  const response = await Api.get(`/products/fast_moving/get`, data);
  return response;
});

export const getSoldTimeLineThunk = createAsyncThunk<
  CommonAxiosResponse<SalesTimeLineResponse>,
  CalendarParamsState,
  { state: RootState }
>('fast-moving/getSoldTimeLineThunk', async (data) => {
  const response = await Api.get(`/products/transactions/get`, data);
  return response;
});
