import React, { useCallback, useRef } from 'react';
import { Input, makeStyles, TableCell } from '@material-ui/core';

import { CustomInputEvent, Quotation } from 'src/types';
import { cloneDeep } from 'lodash';
import { useSnackBar } from 'src/hooks';

interface QuotationQuantityCellProps {
  data: Quotation;
  index: number;
  hasCompatibility?: boolean;
  onChangeQuantity: (e: CustomInputEvent, index: number) => void;
}

const width = 50;

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: width,
      padding: theme.spacing(1)
    },
    icon: {
      padding: 0
    },
    priceInput: {
      textAlign: 'center',
      height: 25,
      width: width
    }
  }),
  { index: 999 }
);

const QuantityCell = ({
  data,
  index,
  hasCompatibility,
  onChangeQuantity
}: QuotationQuantityCellProps) => {
  const inputRef: any = useRef(null);
  const classes = useStyles();
  const snackBar = useSnackBar();

  const onKeyDown = useCallback((e: any) => {
    if (e.key === 'Enter') {
      inputRef.current?.blur();
    }
  }, []);

  const onBlur = useCallback(
    (e: CustomInputEvent) => {
      const clonedEvent = cloneDeep(e);
      let errMsg: string = '';

      if (!clonedEvent?.target?.value) {
        clonedEvent.target.value = '1';
        errMsg = 'Undefined quantity';
      }
      if (+clonedEvent?.target?.value > 99) {
        clonedEvent.target.value = '99';
        errMsg = 'Quantity cannot be more than 99';
      }
      if (+clonedEvent?.target?.value < 0) {
        clonedEvent.target.value = '1';
        errMsg = 'Quantity cannot be less than 1';
      }
      if (errMsg) {
        snackBar.show({
          severity: 'error',
          message: errMsg
        });
      }
      clonedEvent.target.value = parseInt(clonedEvent.target.value)?.toString();
      onChangeQuantity(clonedEvent, index);
    },
    [index, onChangeQuantity, snackBar]
  );

  return (
    <TableCell
      className={classes.root}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Input
        disabled={hasCompatibility}
        disableUnderline
        inputRef={inputRef}
        className={classes.priceInput}
        onFocus={(e) => {
          e.stopPropagation();
        }}
        onKeyDown={(e) => onKeyDown(e)}
        type="number"
        name="quantity"
        onChange={(e) => onChangeQuantity(e, index)}
        onBlur={(e) => onBlur(e)}
        value={data?.quantity || ''}
        inputProps={{
          className: classes.priceInput,
          max: 99,
          min: 1
        }}
      />
    </TableCell>
  );
};

export const QuotationQuantityCell = React.memo(QuantityCell);
