import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { format, isValid, parse, startOfWeek, getDay } from 'date-fns';
import { warranties } from 'src/types';
import { enUS } from 'date-fns/locale';
import { dateFnsLocalizer } from 'react-big-calendar';

export const currentYear = new Date().getFullYear();
//this will give options from 2023 and so on
export const yearOptions = Array.from(
  new Array(currentYear - 2023 + 1),
  (val, index) => 2023 + index
);

export const isDateValid = (dateOrString: any) => {
  if (dateOrString) {
    return false;
  }
  // if (dateOrString instanceof Date) {
  //   return true;
  // }
  return isValid(dateOrString);
};

export const dateToday = (formatStr: string = 'PPpp') => {
  const today = format(new Date(), formatStr);
  return today;
};

export const dateTodayVanilla = () => {
  const today = new Date();
  return today;
};

export const isDateMidnight = (dateOrStrParam: Date | string) => {
  if (dateOrStrParam instanceof Date) {
    const time = dateOrStrParam.toISOString().split('T')[1];
    if (time === '00:00:00.000Z') {
      return true;
    }
  }
  if (
    String(dateOrStrParam).match(
      /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/
    ) !== null
  ) {
    return true;
  }
  const dateTimeISO = new Date(dateOrStrParam).toISOString().split('T')[1];
  if (dateTimeISO === '00:00:00.000Z') {
    return true;
  }

  return false;
};

export const formatDate = (
  dateOrStr?: Date | string | null,
  pattern = 'PP'
) => {
  if (!dateOrStr) {
    return 'Invalid Date';
  }
  if (dateOrStr === '0000-00-00 00:00:00') {
    return 'Invalid Date';
  }
  if (isDateMidnight(dateOrStr)) {
    const utcLocal = convertUTCtoLocal(dateOrStr);
    return `${format(utcLocal, pattern)}`;
  }
  return format(toDate(dateOrStr), pattern);
};

export const formatingDate = (dateString: string): string => {
  const date = new Date(dateString);
  return date
    .toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    })
    .replace(' at', '');
};

export const toUtcEquivalentDate = (date: Date | null) => {
  if (!date) {
    return undefined;
  }
  return new Date(format(date, "yyyy-MM-dd'T'00:00:00.000") + 'Z');
};

export const getWarrantyInDays = (val: string) => {
  if (val) {
    const daysValue = warranties.find((x) => x.name === val)?.value;
    // accept zero days as legit value
    if (daysValue !== undefined && daysValue >= 0) {
      return daysValue;
    }
    if (!isNaN(+val) && +val > 0) {
      return Math.round(+val);
    }
  }
  return undefined;
};

export const toDate = (date: Date | string): Date => {
  if (date instanceof Date) {
    return date;
  } else {
    return new Date(date);
  }
};

export const convertUTCtoLocal = (dateOrString: Date | string) => {
  const date = toDate(dateOrString);
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};

export const muiDatePickerToDateStr = (date: MaterialUiPickersDate) => {
  return toUtcEquivalentDate(date)?.toISOString();
};

export const timeStampNow = () => +new Date();

export const transformerDateField = (date: MaterialUiPickersDate) => {
  const dateUtc = formatDate(date || '', 'yyyy-MM-dd');
  return dateUtc;
};

const locales = {
  'en-US': enUS
};

export const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales
});

export const monthNames = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec'
];

export const convertToLocalISO = (
  selectedDate: Date | string | null,
  endOfDay = false
): string | null => {
  if (!selectedDate) return null; // Handle null case

  let date =
    selectedDate instanceof Date ? selectedDate : new Date(selectedDate);

  if (endOfDay) {
    date.setHours(23, 59, 59, 999); // Set to end of day
  } else {
    date.setHours(0, 0, 0, 0); // Explicitly set to 12:00 AM
  }

  let tzOffsetMinutes = -date.getTimezoneOffset(); // Get correct timezone offset in minutes
  let tzOffsetHours = Math.floor(Math.abs(tzOffsetMinutes) / 60);
  let tzOffsetMins = Math.abs(tzOffsetMinutes) % 60;
  let tzSign = tzOffsetMinutes >= 0 ? '+' : '-';

  let tzFormatted = `${tzSign}${String(tzOffsetHours).padStart(
    2,
    '0'
  )}:${String(tzOffsetMins).padStart(2, '0')}`;

  // Convert manually to ISO format with local timezone
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    '0'
  )}-${String(date.getDate()).padStart(2, '0')}T${String(
    date.getHours()
  ).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(
    date.getSeconds()
  ).padStart(2, '0')}.${String(date.getMilliseconds()).padStart(
    3,
    '0'
  )}${tzFormatted}`;
};
