import React, { useMemo, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  MenuItem,
  Select,
  Typography,
  withStyles
} from '@material-ui/core';
import { TapTapCustomerData } from 'src/redux/slices/taptap-customer/types';
import { LinkComponent } from 'src/components';
import { useNavigate } from 'react-router';
import { formatCurrency, formatDate, getBranchColor } from 'src/utils';
import { taptapCustomerField } from 'src/redux/slices/taptap-customer/constants/taptap-fields';
import { colors } from 'src/constants';
import {
  taptapOptionsStatuses,
  taptapUpdateCustomerViaId
} from 'src/redux/slices/taptap-customer';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

interface Props {
  customer: TapTapCustomerData;
}

const StyledSelect = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(1),
    paddingLeft: 20,
    marginRight: 0,
    textTransform: 'uppercase'
  },
  icon: {
    color: theme.palette.primary.contrastText // Style the dropdown arrow
  }
}))(Select);

const TapTapSalesCustomerItem = ({ customer }: Props) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore((prev) => !prev);
  };
  const customStatusColor = useMemo(() => {
    const statusBgColor = taptapOptionsStatuses?.find(
      (status) => status.value === customer?.status
    )?.bgColor;

    return statusBgColor || 'inherit';
  }, [customer.status]);

  const updatedCustomerDataOnSupabase = async (
    columnName: string,
    value: any
  ) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    const updatedData = { [columnName]: value };

    if (customer?.id) {
      await taptapUpdateCustomerViaId(customer?.id, updatedData);
    }

    setIsLoading(false);
  };

  // eslint-disable-next-line no-unused-vars
  const onChangeStatus = (value: string) => {
    updatedCustomerDataOnSupabase(taptapCustomerField.STATUS, value);
  };

  const forBuildAllowedStatuses = useMemo(() => {
    // Allowed statues are only. BUT Later on it will change depending on the board.
    // return taptapOptionsStatuses?.filter(
    //   (status) =>
    //     status.value === taptapStatuses.PREPARING_ITEMS ||
    //     status.value === taptapStatuses.ON_QUEUE ||
    //     status.value === taptapStatuses.FOR_PACK ||
    //     status.value === taptapStatuses.FOR_CHECKING ||
    //     status.value === taptapStatuses.READY_FOR_PICKUP ||
    //     status.value === taptapStatuses.READY_FOR_DELIVERY ||
    //     status.value === taptapStatuses.DONE_PAYMENT
    // );
    return taptapOptionsStatuses;
  }, []);

  const taptapInfoTagsString = useMemo(() => {
    const tags = [
      customer?.courier,
      customer?.source,
      customer?.fb_transaction_type,
      customer?.ecomm_transaction_type,
      customer?.viber_transaction_type
    ]
      .filter(Boolean) // Filter out falsy values
      .join(' | ');
    return tags;
  }, [customer]);

  const customerPaymentsStr = useMemo(
    () =>
      customer?.customers_payments
        ?.map(
          (payment) =>
            `${payment.payment_type}: ${formatCurrency(payment.amount)}`
        )
        .join(' | '),
    [customer]
  );

  return (
    <Grid key={customer?.id} item xs={12}>
      <Card elevation={5}>
        <CardHeader
          style={{ backgroundColor: colors.grey[100] }}
          action={
            <StyledSelect
              variant="standard"
              id={taptapCustomerField.STATUS}
              name={taptapCustomerField.STATUS}
              value={customer?.status}
              style={{ backgroundColor: customStatusColor, borderRadius: 6 }}
              disableUnderline
              onChange={(e) => {
                if (
                  e.target.value &&
                  e.target.value !== customer?.status &&
                  typeof e.target.value === 'string'
                ) {
                  onChangeStatus(e.target.value);
                }
              }}
              inputProps={{
                IconComponent: (props: any) => (
                  <ArrowDropDownIcon
                    {...props}
                    style={{ position: 'absolute' }}
                  />
                )
              }}
            >
              {forBuildAllowedStatuses?.map((status) => (
                <MenuItem
                  dense
                  selected
                  key={status.value}
                  value={status.value}
                  style={{ backgroundColor: status.bgColor || 'inherit' }}
                >
                  {status.label}
                </MenuItem>
              ))}
            </StyledSelect>
          }
          title={
            <div
              style={{
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              {/* <Typography variant="h4">{customer?.id}: </Typography> */}
              <LinkComponent
                openInNewTab
                title={
                  <Typography variant="inherit" style={{ color: colors.link }}>
                    {customer?.first_name} {customer?.last_name}
                  </Typography>
                }
                href={`/app/customer-taptap/${customer?.id}`}
                onClick={() => navigate(`/customer-app/taptap/${customer?.id}`)}
              />
              <LinkComponent
                openInNewTab
                title={
                  <Typography
                    variant="inherit"
                    style={{ marginLeft: 20, color: colors.link }}
                  >
                    {customer?.internal_transaction_no}
                  </Typography>
                }
                href={`/app/transaction/${customer?.internal_transaction_no}`}
                onClick={() =>
                  navigate(
                    `/app/transaction/${customer?.internal_transaction_no}`
                  )
                }
              />
            </div>
          }
          subheader={
            <Typography>
              <span
                style={{
                  color: getBranchColor(customer.branch_released),
                  fontWeight: 'bold',
                  fontSize: 16
                }}
              >
                {customer.branch_released}
              </span>
              {' - '}
              {` ${formatDate(customer?.created_at, 'MMM d, yyyy h:mm a')}`}
            </Typography>
          }
        />
        <Divider />
        <CardContent>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            {customer?.service_type && (
              <Typography variant="h5">🏷️ {customer?.service_type}</Typography>
            )}
            {customer?.kind_of_sales_transfer && (
              <Typography variant="h5">
                🔁 {customer?.kind_of_sales_transfer}
              </Typography>
            )}
            <Typography variant="h5">
              🏢 {customer?.type_of_build} | {customer?.build_tag} |{' '}
              {customer?.trial_os}
            </Typography>
          </div>
          <Divider style={{ marginTop: 4, marginBottom: 4 }} />
          <Typography variant="h5">
            {customer?.online_sales_representative
              ? `🧑🏻‍💻 Online Sales Rep: ${customer?.online_sales_representative}`
              : `🧑🏻‍💻 No Assigned Online Sales Rep`}{' ----- '}
            {customer?.sales_rep
              ? `👨‍💼 Sales Rep: ${customer?.sales_rep}`
              : `👨‍💼 No Assigned Sales Rep`}{' ----- '}
            {customer?.tech ? `⚙️ Tech: ${customer?.tech}` : `⚙️ No Assigned Tech`}{' '}
          </Typography>
          <Divider style={{ marginTop: 4, marginBottom: 4 }} />
          <Typography variant="h5">ℹ️ {taptapInfoTagsString}</Typography>
          <Divider style={{ marginTop: 4, marginBottom: 4 }} />
          {customer?.contact_no && (
            <Typography color="textSecondary">
              📞{customer?.contact_no || 'N/A'}
            </Typography>
          )}
          {customer?.address && (
            <Typography
              display="inline"
              onClick={toggleShowMore}
              style={{
                display: '-webkit-box',
                WebkitLineClamp: showMore ? 'none' : 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              📍{customer?.address}
            </Typography>
          )}
          {customer?.remarks && (
            <Typography
              display="inline"
              variant="h5"
              onClick={toggleShowMore}
              style={{
                display: '-webkit-box',
                WebkitLineClamp: showMore ? 'none' : 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              📝 {customer?.remarks || 'N/A'}
            </Typography>
          )}
          <Divider style={{ marginTop: 4, marginBottom: 4 }} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <div>
              <Typography variant="body1">
                TOTAL: {formatCurrency(customer?.total_amount)}
              </Typography>
              <Typography
                variant="body1"
                style={{
                  color:
                    customer?.balance && customer?.balance > 0
                      ? colors.error
                      : colors.success
                }}
              >
                BALANCE: {formatCurrency(customer?.balance)}
              </Typography>
              <Typography variant="body1">{customerPaymentsStr}</Typography>
            </div>

            {(customer?.shipping_fee || 0) > 0 ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end'
                }}
              >
                <Typography variant="h5">
                  SF: {formatCurrency(customer?.shipping_fee)}
                </Typography>
                <Typography variant="h5">
                  SF Paid:{' '}
                  {customer?.is_shipping_fee_paid ? (
                    <CheckIcon
                      style={{ color: colors.success, verticalAlign: 'middle' }}
                    />
                  ) : (
                    <ClearIcon
                      style={{ color: colors.error, verticalAlign: 'middle' }}
                    />
                  )}
                </Typography>
              </div>
            ) : null}
          </div>
        </CardContent>
        <Divider />
      </Card>
    </Grid>
  );
};

export default TapTapSalesCustomerItem;
