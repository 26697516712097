import {
  GetTapTapCustomersPayload,
  TapTapFiltersV2,
  TapTapGetCustomersCondition,
  TapTapQuickFilterView
} from '../types';
import { taptapBranchReleased } from './taptap-branch-released';
import { taptapCustomerField } from './taptap-fields';
import { taptapStatuses } from './taptap-form-fields';
import { taptapServiceType } from './taptap-service-type';

export const taptapCustomerInitialPayload: GetTapTapCustomersPayload = {
  search: '',
  pagination: {
    page: 1,
    pageSize: 20
  },
  filters: {
    logic: 'and',
    conditions: []
  },
  sort: [
    {
      field: 'created_at',
      direction: 'DESC'
    }
  ]
};

export const quickFilterBranchesChoices: TapTapGetCustomersCondition[] = [
  {
    field: taptapCustomerField.BRANCH_RELEASED,
    operator: 'in',
    value: [taptapBranchReleased.EARNSHAW]
  },
  {
    field: taptapCustomerField.BRANCH_RELEASED,
    operator: 'in',
    value: [taptapBranchReleased.LPC]
  },
  {
    field: taptapCustomerField.BRANCH_RELEASED,
    operator: 'in',
    value: [taptapBranchReleased.EXPERIENCE]
  }
];

const quickFilterSalesViewConditions: TapTapFiltersV2 = {
  logic: 'and',
  conditions: [
    {
      field: taptapCustomerField.STATUS,
      operator: 'not-in',
      value: [taptapStatuses.CANCELLED, taptapStatuses.COMPLETED]
    }
  ]
};

const quickFilterForBuildConditions: TapTapFiltersV2 = {
  logic: 'and',
  conditions: [
    {
      field: taptapCustomerField.STATUS,
      operator: 'in',
      value: [
        taptapStatuses.BUILDING,
        taptapStatuses.ON_QUEUE,
        taptapStatuses.TESTING
      ]
    },
    {
      field: taptapCustomerField.SERVICE_TYPE,
      operator: 'in',
      value: [taptapServiceType.FOR_BUILD]
    }
  ]
};

// eslint-disable-next-line no-unused-vars
const quickFilterForPartsOut: TapTapFiltersV2 = {
  logic: 'and',
  conditions: [
    {
      field: taptapCustomerField.SERVICE_TYPE,
      operator: 'in',
      value: [taptapServiceType.PARTS_OUT]
    }
  ]
};

const quickFilterForCashier: TapTapFiltersV2 = {
  logic: 'or',
  conditions: [
    {
      field: taptapCustomerField.STATUS,
      operator: 'in',
      value: [taptapStatuses.FOR_PAYMENT, taptapStatuses.FOR_FULLPAYMENT]
    },
    {
      field: taptapCustomerField.BALANCE,
      operator: 'gt',
      value: 0
    }
  ]
};

// const quickFilterForLogistic: TapTapFiltersV2 = {
//   logic: 'and',
//   conditions: [
//     {
//       field: taptapCustomerField.STATUS,
//       operator: 'in',
//       value: [
//         taptapStatuses.READY_FOR_PICKUP,
//         taptapStatuses.READY_FOR_DELIVERY,
//         taptapStatuses.ONGOING_DELIVERY
//       ]
//     }
//   ]
// };

const quickFilterForReleasing: TapTapFiltersV2 = {
  logic: 'and',
  conditions: [
    {
      field: taptapCustomerField.STATUS,
      operator: 'in',
      value: [
        taptapStatuses.FOR_PACK,
        taptapStatuses.PREPARING_ITEMS,
        taptapStatuses.DONE_PAYMENT,
        taptapStatuses.FOR_CHECKING
      ]
    }
  ]
};

const quickFilterCashierDefaultColumns = [
  taptapCustomerField.FIRST_NAME,
  taptapCustomerField.LAST_NAME,
  taptapCustomerField.INTERNAL_TRANSACTION_NO,
  taptapCustomerField.BRANCH_RELEASED,
  taptapCustomerField.STATUS,
  taptapCustomerField.TOTAL_AMOUNT,
  taptapCustomerField.PAID_AMOUNT,
  taptapCustomerField.BALANCE,
  taptapCustomerField.SOURCE,
  taptapCustomerField.SHIPPING_FEE,
  taptapCustomerField.IS_SHIPPING_FEE_PAID,
  taptapCustomerField.REMARKS,
  taptapCustomerField.CREATED_AT
];

const quickFilterForBuildDefaultColumns = [
  taptapCustomerField.FIRST_NAME,
  taptapCustomerField.LAST_NAME,
  taptapCustomerField.INTERNAL_TRANSACTION_NO,
  taptapCustomerField.BRANCH_RELEASED,
  taptapCustomerField.TECH,
  taptapCustomerField.STATUS,
  taptapCustomerField.SOURCE,
  taptapCustomerField.COURIER,
  taptapCustomerField.TRIAL_OS,
  taptapCustomerField.REMARKS,
  taptapCustomerField.CREATED_AT,
  taptapCustomerField.BUILD_TAG,
  taptapCustomerField.SALES_REP,
  taptapCustomerField.VIBER_TRANSACTION_TYPE,
  taptapCustomerField.ECOMM_TRANSACTION_TYPE,
  taptapCustomerField.FB_TRANSACTION_TYPE,
  taptapCustomerField.ONLINE_SALES_REPRESENTATIVE,
  taptapCustomerField.TYPE_OF_BUILD,
  taptapCustomerField.WITH_UPGRADE,
  taptapCustomerField.KIND_OF_SALES_TRANSFER
];

// eslint-disable-next-line no-unused-vars
const quickFilterForPartsOutDefaultColumns = quickFilterForBuildDefaultColumns;

// const quickFilterLogisticsDefaultColumns = [
//   taptapCustomerField.FIRST_NAME,
//   taptapCustomerField.INTERNAL_TRANSACTION_NO,
//   taptapCustomerField.BRANCH_RELEASED,
//   taptapCustomerField.STATUS,
//   taptapCustomerField.COURIER,
//   taptapCustomerField.BALANCE,
//   taptapCustomerField.CONTACT_NO,
//   taptapCustomerField.ADDRESS,
//   taptapCustomerField.SHIPPING_FEE,
//   taptapCustomerField.IS_SHIPPING_FEE_PAID,
//   taptapCustomerField.TRIAL_OS,
//   taptapCustomerField.CREATED_AT,
//   taptapCustomerField.VIBER_TRANSACTION_TYPE,
//   taptapCustomerField.ECOMM_TRANSACTION_TYPE,
//   taptapCustomerField.FB_TRANSACTION_TYPE,
//   taptapCustomerField.ONLINE_SALES_REPRESENTATIVE,
//   taptapCustomerField.BUILD_TAG,
//   taptapCustomerField.TYPE_OF_BUILD,
//   taptapCustomerField.WITH_UPGRADE,
//   taptapCustomerField.KIND_OF_SALES_TRANSFER,
//   taptapCustomerField.REMARKS
// ];

const quickFilterReleasingDefaultColumns = [
  taptapCustomerField.FIRST_NAME,
  taptapCustomerField.LAST_NAME,
  taptapCustomerField.INTERNAL_TRANSACTION_NO,
  taptapCustomerField.BRANCH_RELEASED,
  taptapCustomerField.STATUS,
  taptapCustomerField.REMARKS,
  taptapCustomerField.CONTACT_NO,
  taptapCustomerField.ADDRESS,
  taptapCustomerField.CREATED_AT,
  taptapCustomerField.SOURCE,
  taptapCustomerField.FB_TRANSACTION_TYPE,
  taptapCustomerField.COURIER,
  taptapCustomerField.SERVICE_TYPE,
  taptapCustomerField.TOTAL_AMOUNT,
  taptapCustomerField.PAID_AMOUNT,
  taptapCustomerField.BALANCE,
  taptapCustomerField.SHIPPING_FEE,
  taptapCustomerField.IS_SHIPPING_FEE_PAID,
  taptapCustomerField.KIND_OF_SALES_TRANSFER,
  taptapCustomerField.ONLINE_SALES_REPRESENTATIVE,
  taptapCustomerField.WITH_UPGRADE
];

// const quickFilterNotCompleted: TapTapFiltersV2 = {
//   logic: 'and',
//   conditions: [
//     {
//       field: taptapCustomerField.STATUS,
//       operator: 'neq',
//       value: taptapStatuses.COMPLETED
//     }
//   ]
// };

export const quickFilterViewsLabels = {
  ALL: 'ALL',
  SALES_VIEW: 'SALES VIEW',
  FOR_BUILD: 'FOR BUILD',
  FOR_PARTS_OUT: 'FOR PARTS OUT',
  CASHIER: 'CASHIER',
  LOGISTICS: 'LOGISTICS',
  RELEASING: 'RELEASING',
  NOT_COMPLETED: 'NOT COMPLETED'
};

export const quickFilterViewsChoices: TapTapQuickFilterView[] = [

  {
    id: 'sales_view',
    label: quickFilterViewsLabels.SALES_VIEW,
    filters: quickFilterSalesViewConditions,
    permission: 'can_filter_taptap_sales_view',
    sort: [
      {
        field: 'created_at',
        direction: 'ASC'
      }
    ]
  },
  {
    id: 'for_build',
    label: quickFilterViewsLabels.FOR_BUILD,
    filters: quickFilterForBuildConditions,
    defaultColumns: quickFilterForBuildDefaultColumns
    // permission: 'can_filter_taptap_forbuild_view',
  },
  {
    id: 'cashier',
    label: quickFilterViewsLabels.CASHIER,
    filters: quickFilterForCashier,
    defaultColumns: quickFilterCashierDefaultColumns
    // permission: 'can_filter_taptap_cashier_view',
  },
  // {
  //   id: 'logistics',
  //   label: quickFilterViewsLabels.LOGISTICS,
  //   filters: quickFilterForLogistic,
  //   defaultColumns: quickFilterLogisticsDefaultColumns
  // },
  {
    id: 'releasing',
    label: quickFilterViewsLabels.RELEASING,
    filters: quickFilterForReleasing,
    defaultColumns: quickFilterReleasingDefaultColumns
    // permission: 'can_filter_taptap_releasing_view',
  },
    {
    id: 'all',
    label: quickFilterViewsLabels.ALL,
    filters: taptapCustomerInitialPayload.filters
    // permission: 'can_filter_taptap_all_view',// TODO: This is an admin type of permission. Dapat certain people lang to. pero for now lahat may access
  },

  // {
  //   id: 'not_completed',
  //   label: quickFilterViewsLabels.NOT_COMPLETED,
  //   filters: quickFilterNotCompleted
  // }
];
