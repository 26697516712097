import { colors } from 'src/constants';
import { RMAFormField } from '../types';
import { RMAField, RMAFieldLabel } from './rma-fields';
import { PCWorthBranchesEnum } from 'src/enums/branches';

export const rmaDefaultValues = {
  defaultCustomerNameStockUnit: 'STOCK UNIT'
};

export const rmaStatuses = {
  RMA_STORE_INQUIRING: 'RMA STORE INQUIRING',
  RMA_STORE_PENDING: 'RMA STORE PENDING',
  RMA_STORE_FOR_CHECKING: 'RMA STORE FOR CHECKING',
  RMA_STORE_ONGOING_DIAGNOSIS: 'RMA STORE ONGOING DIAGNOSIS',
  RMA_STORE_REPORTED_TO_SERVICE_CENTER: 'RMA STORE REPORTED TO SERVICE CENTER',
  RMA_STORE_COMPLETED: 'RMA STORE COMPLETED',
  RMA_STORE_CLOSED_TICKET: 'RMA STORE CLOSED TICKET',
  RMA_STORE_FOR_PICKUP: 'RMA STORE FOR PICKUP',
  RMA_STORE_FOR_PICKUP_VOID: 'RMA STORE FOR PICKUP VOID',

  RMA_STORE_FOR_RETURN_TO_EU: 'RMA STORE FOR RETURN TO EU',
  RMA_STORE_REPLACED: 'RMA STORE REPLACED',
  RMA_STORE_REFUNDED: 'RMA STORE REFUNDED',
  RMA_STORE_VOIDED_WARRANTY: 'RMA STORE VOIDED WARRANTY',
  RMA_STORE_WARRANTY_EXPIRED: 'RMA STORE WARRANTY EXPIRED',
  RMA_STORE_ESCALATED_TO_MANAGEMENT: 'RMA STORE ESCALATED TO MANAGEMENT',
  RMA_STORE_ESCALATED_TO_SUPPLIER: 'RMA STORE ESCALATED TO SUPPLIER',
  RMA_STORE_ESCALATED_TO_VENDOR: 'RMA STORE ESCALATED TO VENDOR',

  // Service Center Statuses
  RMA_SC_PULL_OUT: 'RMA SC PULL OUT',
  RMA_SC_FOR_RETURN_GOOD: 'RMA SC FOR RETURN GOOD',
  RMA_SC_FOR_RETURN_VOID: 'RMA SC FOR RETURN VOID',
  RMA_SC_FOR_RETURN_REPAIRED: 'RMA SC FOR RETURN REPAIRED',
  RMA_SC_FOR_RETURN_REPLACED: 'RMA SC FOR RETURN REPLACED',
  RMA_SC_FOR_RETURN_REPLACED_SAME_UNIT: 'RMA SC FOR RETURN REPLACED SAME UNIT',
  RMA_SC_FOR_RETURN_REPLACED_OTHER_MODEL:
    'RMA SC FOR RETURN REPLACED OTHER MODEL',
  RMA_SC_FOR_CREDIT_MEMO: 'RMA SC CREDIT MEMO',
  RMA_SC_ONGOING_TESTING: 'RMA SC ONGOING TESTING',
  RMA_SC_LINE_UP_FOR_TESTING: 'RMA SC LINE UP FOR TESTING',
  RMA_SC_FOR_CHECKING: 'RMA SC FOR CHECKING',
  RMA_SC_FOR_REPORTING: 'RMA SC FOR REPORTING',
  RMA_SC_PROCESSING: 'RMA SC PROCESSING',
  RMA_SC_TESTED_GOOD: 'RMA SC TESTED GOOD',
  RMA_SC_PENDING: 'RMA SC PENDING',
  RMA_SC_TO_PC_WORTH: 'RMA SC TO PC WORTH',
  RMA_SC_VOIDED_WARRANTY: 'RMA SC VOIDED WARRANTY',
  RMA_SC_WARRANTY_EXPIRED: 'RMA SC WARRANTY EXPIRED',

  // General RMA Statuses
  RMA_LOST_ITEM: 'RMA LOST ITEM',
  RMA_DAMAGED_IN_TRANSIT: 'RMA DAMAGED IN TRANSIT',
  RMA_INCORRECT_ITEM_RECEIVED: 'RMA INCORRECT ITEM RECEIVED',
  RMA_EU_NO_RESPONSE: 'RMA EU NO RESPONSE',
  RMA_UNAUTHORIZED_RETURN: 'RMA UNAUTHORIZED RETURN'
};

export const rmaOptionsStatuses = [
  // Store Statuses
  {
    key: rmaStatuses.RMA_STORE_INQUIRING,
    label: rmaStatuses.RMA_STORE_INQUIRING,
    value: rmaStatuses.RMA_STORE_INQUIRING,
    bgColor: colors.lightBlue[600]
  },
  {
    key: rmaStatuses.RMA_STORE_PENDING,
    label: rmaStatuses.RMA_STORE_PENDING,
    value: rmaStatuses.RMA_STORE_PENDING,
    bgColor: colors.grey[600]
  },
  {
    key: rmaStatuses.RMA_STORE_FOR_CHECKING,
    label: rmaStatuses.RMA_STORE_FOR_CHECKING,
    value: rmaStatuses.RMA_STORE_FOR_CHECKING,
    bgColor: colors.orange[600]
  },
  {
    key: rmaStatuses.RMA_STORE_ONGOING_DIAGNOSIS,
    label: rmaStatuses.RMA_STORE_ONGOING_DIAGNOSIS,
    value: rmaStatuses.RMA_STORE_ONGOING_DIAGNOSIS,
    bgColor: colors.lime[600]
  },
  {
    key: rmaStatuses.RMA_STORE_REPORTED_TO_SERVICE_CENTER,
    label: rmaStatuses.RMA_STORE_REPORTED_TO_SERVICE_CENTER,
    value: rmaStatuses.RMA_STORE_REPORTED_TO_SERVICE_CENTER,
    bgColor: colors.blueGrey[600]
  },
  {
    key: rmaStatuses.RMA_STORE_COMPLETED,
    label: rmaStatuses.RMA_STORE_COMPLETED,
    value: rmaStatuses.RMA_STORE_COMPLETED,
    bgColor: colors.green[600]
  },
  {
    key: rmaStatuses.RMA_STORE_CLOSED_TICKET,
    label: rmaStatuses.RMA_STORE_CLOSED_TICKET,
    value: rmaStatuses.RMA_STORE_CLOSED_TICKET,
    bgColor: colors.teal[600]
  },
  {
    key: rmaStatuses.RMA_STORE_FOR_PICKUP,
    label: rmaStatuses.RMA_STORE_FOR_PICKUP,
    value: rmaStatuses.RMA_STORE_FOR_PICKUP,
    bgColor: colors.purple[600]
  },
  {
    key: rmaStatuses.RMA_STORE_FOR_PICKUP_VOID,
    label: rmaStatuses.RMA_STORE_FOR_PICKUP_VOID,
    value: rmaStatuses.RMA_STORE_FOR_PICKUP_VOID,
    bgColor: colors.red[600]
  },
  {
    key: rmaStatuses.RMA_STORE_FOR_RETURN_TO_EU,
    label: rmaStatuses.RMA_STORE_FOR_RETURN_TO_EU,
    value: rmaStatuses.RMA_STORE_FOR_RETURN_TO_EU,
    bgColor: colors.indigo[600]
  },
  {
    key: rmaStatuses.RMA_STORE_REPLACED,
    label: rmaStatuses.RMA_STORE_REPLACED,
    value: rmaStatuses.RMA_STORE_REPLACED,
    bgColor: colors.green[600]
  },
  {
    key: rmaStatuses.RMA_STORE_REFUNDED,
    label: rmaStatuses.RMA_STORE_REFUNDED,
    value: rmaStatuses.RMA_STORE_REFUNDED,
    bgColor: colors.cyan[600]
  },
  {
    key: rmaStatuses.RMA_STORE_VOIDED_WARRANTY,
    label: rmaStatuses.RMA_STORE_VOIDED_WARRANTY,
    value: rmaStatuses.RMA_STORE_VOIDED_WARRANTY,
    bgColor: colors.deepOrange[600]
  },
  {
    key: rmaStatuses.RMA_STORE_WARRANTY_EXPIRED,
    label: rmaStatuses.RMA_STORE_WARRANTY_EXPIRED,
    value: rmaStatuses.RMA_STORE_WARRANTY_EXPIRED,
    bgColor: colors.amber[600]
  },
  {
    key: rmaStatuses.RMA_STORE_ESCALATED_TO_MANAGEMENT,
    label: rmaStatuses.RMA_STORE_ESCALATED_TO_MANAGEMENT,
    value: rmaStatuses.RMA_STORE_ESCALATED_TO_MANAGEMENT,
    bgColor: colors.brown[600]
  },
  {
    key: rmaStatuses.RMA_STORE_ESCALATED_TO_SUPPLIER,
    label: rmaStatuses.RMA_STORE_ESCALATED_TO_SUPPLIER,
    value: rmaStatuses.RMA_STORE_ESCALATED_TO_SUPPLIER,
    bgColor: colors.brown[600]
  },
  {
    key: rmaStatuses.RMA_STORE_ESCALATED_TO_VENDOR,
    label: rmaStatuses.RMA_STORE_ESCALATED_TO_VENDOR,
    value: rmaStatuses.RMA_STORE_ESCALATED_TO_VENDOR,
    bgColor: colors.brown[600]
  },

  // Service Center Statuses
  {
    key: rmaStatuses.RMA_SC_PULL_OUT,
    label: rmaStatuses.RMA_SC_PULL_OUT,
    value: rmaStatuses.RMA_SC_PULL_OUT,
    bgColor: colors.orange[600]
  },
  {
    key: rmaStatuses.RMA_SC_FOR_RETURN_GOOD,
    label: rmaStatuses.RMA_SC_FOR_RETURN_GOOD,
    value: rmaStatuses.RMA_SC_FOR_RETURN_GOOD,
    bgColor: colors.green[600]
  },
  {
    key: rmaStatuses.RMA_SC_FOR_RETURN_VOID,
    label: rmaStatuses.RMA_SC_FOR_RETURN_VOID,
    value: rmaStatuses.RMA_SC_FOR_RETURN_VOID,
    bgColor: colors.red[600]
  },
  {
    key: rmaStatuses.RMA_SC_FOR_RETURN_REPAIRED,
    label: rmaStatuses.RMA_SC_FOR_RETURN_REPAIRED,
    value: rmaStatuses.RMA_SC_FOR_RETURN_REPAIRED,
    bgColor: colors.blue[600]
  },
  {
    key: rmaStatuses.RMA_SC_FOR_RETURN_REPLACED,
    label: rmaStatuses.RMA_SC_FOR_RETURN_REPLACED,
    value: rmaStatuses.RMA_SC_FOR_RETURN_REPLACED,
    bgColor: colors.teal[600]
  },
  {
    key: rmaStatuses.RMA_SC_FOR_RETURN_REPLACED_SAME_UNIT,
    label: rmaStatuses.RMA_SC_FOR_RETURN_REPLACED_SAME_UNIT,
    value: rmaStatuses.RMA_SC_FOR_RETURN_REPLACED_SAME_UNIT,
    bgColor: colors.cyan[600]
  },
  {
    key: rmaStatuses.RMA_SC_FOR_RETURN_REPLACED_OTHER_MODEL,
    label: rmaStatuses.RMA_SC_FOR_RETURN_REPLACED_OTHER_MODEL,
    value: rmaStatuses.RMA_SC_FOR_RETURN_REPLACED_OTHER_MODEL,
    bgColor: colors.cyan[400]
  },
  {
    key: rmaStatuses.RMA_SC_FOR_CREDIT_MEMO,
    label: rmaStatuses.RMA_SC_FOR_CREDIT_MEMO,
    value: rmaStatuses.RMA_SC_FOR_CREDIT_MEMO,
    bgColor: colors.purple[600]
  },
  {
    key: rmaStatuses.RMA_SC_ONGOING_TESTING,
    label: rmaStatuses.RMA_SC_ONGOING_TESTING,
    value: rmaStatuses.RMA_SC_ONGOING_TESTING,
    bgColor: colors.lime[600]
  },
  {
    key: rmaStatuses.RMA_SC_LINE_UP_FOR_TESTING,
    label: rmaStatuses.RMA_SC_LINE_UP_FOR_TESTING,
    value: rmaStatuses.RMA_SC_LINE_UP_FOR_TESTING,
    bgColor: colors.deepPurple[600]
  },
  {
    key: rmaStatuses.RMA_SC_TESTED_GOOD,
    label: rmaStatuses.RMA_SC_TESTED_GOOD,
    value: rmaStatuses.RMA_SC_TESTED_GOOD,
    bgColor: colors.green[600]
  },

  // General RMA Statuses
  {
    key: rmaStatuses.RMA_LOST_ITEM,
    label: rmaStatuses.RMA_LOST_ITEM,
    value: rmaStatuses.RMA_LOST_ITEM,
    bgColor: colors.grey[600]
  },
  {
    key: rmaStatuses.RMA_DAMAGED_IN_TRANSIT,
    label: rmaStatuses.RMA_DAMAGED_IN_TRANSIT,
    value: rmaStatuses.RMA_DAMAGED_IN_TRANSIT,
    bgColor: colors.deepOrange[600]
  },
  {
    key: rmaStatuses.RMA_INCORRECT_ITEM_RECEIVED,
    label: rmaStatuses.RMA_INCORRECT_ITEM_RECEIVED,
    value: rmaStatuses.RMA_INCORRECT_ITEM_RECEIVED,
    bgColor: colors.yellow[600]
  },
  {
    key: rmaStatuses.RMA_EU_NO_RESPONSE,
    label: rmaStatuses.RMA_EU_NO_RESPONSE,
    value: rmaStatuses.RMA_EU_NO_RESPONSE,
    bgColor: colors.red[600]
  },
  {
    key: rmaStatuses.RMA_UNAUTHORIZED_RETURN,
    label: rmaStatuses.RMA_UNAUTHORIZED_RETURN,
    value: rmaStatuses.RMA_UNAUTHORIZED_RETURN,
    bgColor: colors.blueGrey[600]
  }
];

export const rmaLocations = {
  EARNSHAW: 'EARNSHAW',
  LPC: 'LPC',
  EXPERIENCE: 'EXPERIENCE',
  EU: 'EU',
  SERVICE_CENTER: 'SERVICE CENTER'
};

export type RmaLocation = {
  key: string;
  label: string;
  value: string;
  branch_id?: number;
};

export const rmaLocationOptions: RmaLocation[] = [
  {
    key: 'earnshaw',
    label: rmaLocations.EARNSHAW,
    value: rmaLocations.EARNSHAW,
    branch_id: PCWorthBranchesEnum.Earnshaw
  },
  {
    key: 'lpc',
    label: rmaLocations.LPC,
    value: rmaLocations.LPC,
    branch_id: PCWorthBranchesEnum.LPC
  },
  {
    key: 'experience',
    label: rmaLocations.EXPERIENCE,
    value: rmaLocations.EXPERIENCE,
    branch_id: PCWorthBranchesEnum.Experience
  },
  {
    key: 'eu',
    label: rmaLocations.EU,
    value: rmaLocations.EU
  },
  {
    key: 'service_center',
    label: rmaLocations.SERVICE_CENTER,
    value: rmaLocations.SERVICE_CENTER
  }
];

export const addRMAFields: RMAFormField[] = [
  {
    key: RMAField.PRODUCT_NAME,
    type: 'text',
    label: RMAFieldLabel.PRODUCT_NAME,
    disabled: true,
    grid: 'half'
  },
  {
    key: RMAField.SERIAL_NO,
    type: 'text',
    label: RMAFieldLabel.SERIAL_NO,
    disabled: true,
    grid: 'half'
  },
  {
    key: RMAField.CUSTOMER_NAME,
    type: 'text',
    label: RMAFieldLabel.CUSTOMER_NAME,
    required: true,
    grid: 'half'
  },
  {
    key: RMAField.CONTACT_NO,
    type: 'text',
    label: RMAFieldLabel.CONTACT_NO,
    grid: 'half'
  },
  {
    key: RMAField.STATUS,
    type: 'autocomplete',
    label: 'Status',
    options: rmaOptionsStatuses
  },
  {
    key: RMAField.TECH,
    type: 'text',
    label: RMAFieldLabel.TECH
  },
  {
    key: RMAField.LOCATION,
    type: 'autocomplete',
    label: RMAFieldLabel.LOCATION,
    options: rmaLocationOptions
  },
  {
    key: RMAField.REPORTED_PROBLEM,
    type: 'longText',
    label: RMAFieldLabel.REPORTED_PROBLEM
  },
  { key: RMAField.REMARKS, type: 'longText', label: RMAFieldLabel.REMARKS },
  {
    key: RMAField.RMA_IMAGES,
    type: 'image',
    label: RMAFieldLabel.RMA_IMAGES
  }
];

export const rmaDetailsFields: RMAFormField[] = [
  {
    key: RMAField.PRODUCT_NAME,
    type: 'text',
    label: RMAFieldLabel.PRODUCT_NAME,
    disabled: true,
    grid: 'half'
  },
  {
    key: RMAField.SERIAL_NO,
    type: 'text',
    label: RMAFieldLabel.SERIAL_NO,
    disabled: true,
    grid: 'half'
  },
  {
    key: RMAField.SUPPLIER_NAME,
    type: 'text',
    label: RMAFieldLabel.SUPPLIER_NAME,
    disabled: true,
    grid: 'half'
  },
  {
    key: RMAField.TRANSACTION_NO,
    type: 'text',
    label: RMAFieldLabel.TRANSACTION_NO,
    disabled: true,
    grid: 'half'
  },
  {
    key: RMAField.CUSTOMER_NAME,
    type: 'text',
    label: RMAFieldLabel.CUSTOMER_NAME,
    required: true,
    grid: 'half'
  },
  {
    key: RMAField.CONTACT_NO,
    type: 'text',
    label: RMAFieldLabel.CONTACT_NO,
    grid: 'half'
  },
  {
    key: RMAField.STATUS,
    type: 'autocomplete',
    label: 'Status',
    options: rmaOptionsStatuses
  },
  {
    key: RMAField.LOCATION,
    type: 'autocomplete',
    label: RMAFieldLabel.LOCATION,
    options: rmaLocationOptions
  },
  {
    key: RMAField.TECH,
    type: 'text',
    label: RMAFieldLabel.TECH
  },
  {
    key: RMAField.REPORTED_PROBLEM,
    type: 'longText',
    label: RMAFieldLabel.REPORTED_PROBLEM
  },
  { key: RMAField.REMARKS, type: 'longText', label: RMAFieldLabel.REMARKS },
  {
    key: RMAField.RMA_IMAGES,
    type: 'image',
    label: RMAFieldLabel.RMA_IMAGES
  }
];
