// TODO: Change to taptap customer

import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectTapTapCustomer = (state: RootState) => state.taptapCustomer;

const selectCustomers = createSelector(
  selectTapTapCustomer,
  (state) => state.taptapCustomers
);

const selectProfiles = createSelector(
  selectTapTapCustomer,
  (state) => state.taptapProfiles
);

const selectTaptapCustomerFilter = createSelector(
  selectTapTapCustomer,
  (state) => state.taptapGetCustomersPayload.filters
);

const selectTaptapCustomerColumns = createSelector(
  selectTapTapCustomer,
  (state) => state.taptapColumns
);

const selectTaptapCustomerNextPageLoading = createSelector(
  selectTapTapCustomer,
  (state) => state.isNextPageLoading
);

const selectTaptapCustomerLoading = createSelector(
  selectTapTapCustomer,
  (state) => state.isTapTapCustomersLoading
);

const selectTaptapCustomerSearchValue = createSelector(
  selectTapTapCustomer,
  (state) => state.taptapGetCustomersPayload.search || ''
);

const selectTaptapCustomerPayload = createSelector(
  selectTapTapCustomer,
  (state) => state.taptapGetCustomersPayload
);

const selectTapTapVersion = createSelector(
  selectTapTapCustomer,
  (state) => state.taptapVersion
);

export const selectors = {
  selectProfiles,
  selectCustomers,
  selectTapTapVersion,
  selectTaptapCustomerFilter,
  selectTaptapCustomerColumns,
  selectTaptapCustomerLoading,
  selectTaptapCustomerPayload,
  selectTaptapCustomerSearchValue,
  selectTaptapCustomerNextPageLoading
};
