import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { Customer, CustomInputEvent } from 'src/types';
import { usePermissions } from 'src/hooks';
import { LinkComponent } from 'src/components';
import { useNavigate } from 'react-router';
import { colors } from 'src/constants';

interface Props {
  className?: string;
  customer: Customer;
  transactionNo?: string;
  order_from_branch?: string;
  orderOrigin?: string;
  onCustomerDetailChange: (event: CustomInputEvent) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2)
  }
}));

const component = ({
  transactionNo,
  customer,
  order_from_branch,
  orderOrigin,
  onCustomerDetailChange
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { permissionChecker } = usePermissions();
  const canEditEcomOrder = permissionChecker('can_edit_ecom_order');

  const handleChange = (event: CustomInputEvent) => {
    onCustomerDetailChange(event);
  };

  const onCustomerPress = () => {
    navigate(`/app/customers/${customer.id}`, { state: customer });
  };

  return (
    <Card className={classes.root}>
      <CardHeader title="Customer Information" />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              disabled={!canEditEcomOrder}
              helperText="Please specify the first name"
              label="First name"
              name="first_name"
              onChange={handleChange}
              required
              value={customer.first_name || ''}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              disabled={!canEditEcomOrder}
              fullWidth
              label="Middle Initial"
              name="middle_initial"
              onChange={handleChange}
              value={customer?.middle_initial || ''}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              disabled={!canEditEcomOrder}
              fullWidth
              label="Last name"
              name="last_name"
              onChange={handleChange}
              required
              value={customer?.last_name || ''}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              disabled={!canEditEcomOrder}
              fullWidth
              label="Email Address"
              name="email"
              onChange={handleChange}
              value={customer?.email || ''}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              disabled={!canEditEcomOrder}
              fullWidth
              inputProps={{ maxLength: 11 }}
              helperText="Maximum of 11 characters only. e.g. 09174308787"
              type="tel"
              name="contact_no"
              variant="outlined"
              label="Contact No."
              onChange={handleChange}
              value={customer?.contact_no || ''}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography display="inline" variant="h5">
                  Order Origin: {orderOrigin || ' --'}
                </Typography>
              </Grid>
              <Grid item>
                <Typography display="inline" variant="h5">
                  Order from branch: {order_from_branch || ' --'}
                </Typography>
              </Grid>

              {transactionNo && (
                <Grid item>
                  <Typography display="inline" variant="h5">
                    Transaction No:{' '}
                    <LinkComponent
                      openInNewTab
                      href={`/app/transaction/${transactionNo}`}
                      title={transactionNo}
                      onClick={() =>
                        navigate(`/app/transaction/${transactionNo}`)
                      }
                      extraProps={{
                        style: {
                          textDecoration: 'underline',
                          color: colors.link
                        }
                      }}
                    />
                  </Typography>
                </Grid>
              )}

              {customer?.id && (
                <Grid item>
                  <Typography display="inline" variant="h5">
                    Customer Profile:{' '}
                    <LinkComponent
                      onClick={() => onCustomerPress()}
                      href={`/app/customers/${customer.id}?is_ecomm_user=${customer.is_ecomm_user}`}
                      title="Go to customer profile"
                      extraProps={{
                        style: {
                          textDecoration: 'underline',
                          color: colors.link
                        }
                      }}
                    />
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export const CustomerDetails = React.memo(component);
