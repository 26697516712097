/* eslint-disable no-unused-vars */
import React, {
  useCallback,
  useRef,
  useMemo,
  useEffect,
  useState
} from 'react';
import {
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { LoaderBar, Page } from 'src/components';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import GetAppIcon from '@material-ui/icons/GetApp';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';

import { snakeCaseToTitleCase, downloadCSV } from 'src/utils';
import { colors } from 'src/constants';
import RMATableRow from './components/RMATableRow';
import { RMAData } from 'src/redux/slices/rma/types';
import { initialRMATableColumns } from 'src/redux/slices/rma/constants/rma-table-columns';
import { RMAEmptySearchView } from './components/RMAEmptySearchView';
import Toolbar from './Toolbar';
import { unwrapResult } from '@reduxjs/toolkit';
import { PaginationV2 } from 'src/types';
import { RmaColumnsFilterModal } from 'src/components/modal/rma/RmaColumnsFilterModal';
import useResolution from 'src/hooks/useResolution';
import { cloneDeep } from 'lodash';
import { Transaction } from '@tiptap/pm/state';

const useStyles = makeStyles(() => ({
  root: {},
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  clearText: {
    color: 'rgb(244, 67, 54)',
    display: 'flex',
    justifyContent: 'space-between'
  },
  statusContainer: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const { selectors: rmaSelector, actions: rmaActions } = slices.rma;

const RMAView = () => {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const dispatch = useAppDispatch();
  const rmas = useAppSelector(rmaSelector.selectRmaList);
  const [meta, setMeta] = useState<PaginationV2 | undefined>(undefined);

  // Horizontal Scroll bar related
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const topScrollRef = useRef<HTMLDivElement>(null);
  const [tableWidth, setTableWidth] = useState(0);
  const { sm } = useResolution();
  const [filterVisible, setFilterVisible] = useState<boolean>(false);

  const rmaColumnsRedux = useAppSelector(rmaSelector.selectRMAColumns);
  const loading = useAppSelector(rmaSelector.selectLoading);
  const pageMeta = useAppSelector(rmaSelector.selectPaginationMeta);

  // const taptapColumns = useAppSelector(
  //   taptapCustomerSelectors.selectTaptapCustomerColumns
  // );

  // const isRMAsLoading = useAppSelector(
  //   taptapCustomerSelectors.selectTaptapCustomerLoading
  // );

  // const taptapVersion = useAppSelector(
  //   taptapCustomerSelectors.selectTapTapVersion
  // );

  // const isNextPageLoading = useAppSelector(
  //   taptapCustomerSelectors.selectTaptapCustomerNextPageLoading
  // );

  // const selectedFilter = useAppSelector(
  //   taptapCustomerSelectors.selectTaptapCustomerFilter
  // );

  /** ============================
   * START OF SUPABASE STUFF
   ============================ */

  //  TODO: Fix any
  // const onUpdateLocalCustomerDetail = async (data: any) => {
  //   const updatedCustomerData: RMAData = data?.new ?? {};
  //   dispatch(taptapCustomerActions.updateTaptapCustomer(updatedCustomerData));
  // };

  // const onInsertOnLocalCustomerList = async (data: any) => {
  //   const newCustomerData: RMAData = data?.new ?? {};
  //   dispatch(taptapCustomerActions.insertNewTaptapCustomers(newCustomerData));
  // };

  // const onDeleteLocalCustomer = (data: any) => {
  //   const deletedCustomerData: RMAData = data?.old ?? {};
  //   dispatch(taptapCustomerActions.removeTaptapCustomer(deletedCustomerData));
  // };

  /** ============================
   * END OF SUPABASE STUFF
   ============================ */

  /** ============================
   * START OF COMPONENT STUFF
   ============================ */
  const fetchRMAs = useCallback(
    async (params?: { page: number }) => {
      try {
        const response = await dispatch(rmaActions.getRmasThunk({ page: 1 }));
        const data = unwrapResult(response);
        setMeta(data?.originalData?.meta);
        return data?.originalData?.data || [];
      } catch (error) {
        console.error('Error fetching RMAs:', error);
        return [];
      }
    },
    [dispatch]
  );

  // RMA-TODO: Next Page logic lods
  // const fetchCustomersNextPage = () => {
  //   if (isCustomersLoading || isNextPageLoading) {
  //     return;
  //   }

  //   dispatch(taptapCustomerActions.taptapFetchCustomersNextPageThunk());
  // };

  // const fetchTapTapProfiles = () => {
  //   dispatch(taptapGetProfilesThunk());
  // };

  /** ============================
   * END OF COMPONENT STUFF
   ============================ */

  // useEffect(() => {
  //   fetchRMAs();
  //   // fetchTapTapProfiles(); // To fetch tech later
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    const loadRMAs = async () => {
      await fetchRMAs();
    };

    loadRMAs();
  }, [fetchRMAs]);

  // const selectedFilterIndicator = useMemo(() => {
  //   const selectedQuickFilter = findSelectedQuickFilterViaParam(selectedFilter);
  //   return selectedQuickFilter?.label || 'ALL ?';
  // }, [selectedFilter]);

  const onHandlePageChange = (event: any, page: number) => {
    if (loading) return;

    dispatch(rmaActions.updatePageFilter({ page }));
    dispatch(rmaActions.getRmasThunk({ page }));
  };

  const handleScroll = (event: any) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const isBottom = scrollHeight - (scrollTop + clientHeight) <= 5; // More lenient

    if (topScrollRef.current && event.currentTarget !== topScrollRef.current) {
      topScrollRef.current.scrollLeft = event.currentTarget.scrollLeft;
    }

    if (
      isBottom &&
      pageMeta?.current_page &&
      pageMeta?.last_page &&
      pageMeta.current_page < pageMeta.last_page
    ) {
      if (!loading) {
        onHandlePageChange(null, pageMeta.current_page + 1);
      }
    }
  };

  const handleTopScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (
      tableContainerRef.current &&
      event.currentTarget !== tableContainerRef.current
    ) {
      const newScrollLeft = event.currentTarget.scrollLeft;
      if (tableContainerRef.current.scrollLeft !== newScrollLeft) {
        tableContainerRef.current.scrollLeft = newScrollLeft;
      }
    }
  };

  const onExportToCSV = useCallback(async () => {
    if (!meta?.last_page) return;

    const totalPages = meta.last_page;
    let updatedCSV: RMAData[] = [];
    let fetchedPages = new Set();

    try {
      const fetchPromises = Array.from({ length: totalPages }, (_, i) =>
        fetchRMAs({ page: i + 1 })
      );

      const results = await Promise.all(fetchPromises);

      results.forEach((res, index) => {
        if (res) {
          updatedCSV = [...updatedCSV, ...res];
          fetchedPages.add(index + 1);
        }
      });

      if (fetchedPages.size !== totalPages) {
        console.warn(
          `Warning: Expected ${totalPages} pages, but only fetched ${fetchedPages.size}.`
        );
      }

      if (updatedCSV.length === 0) {
        console.error('Error: No data was fetched for CSV export.');
        return;
      }

      const csvFile = updatedCSV.map((rma: RMAData) => ({
        RMA_ID: rma.rma_id ?? '',
        Customer_Name: rma.customer_name ?? '',
        Contact_No: rma.contact_no ?? '',
        Serial_No: rma.serial_no ?? '',
        Reported_Problem: rma.reported_problem ?? '',
        Remarks: rma.remarks ?? '',
        Product_Name: rma.product_name ?? '',
        Status: rma.status ?? '',
        Supplier: rma.supplier ?? '',
        Transaction_No: rma.transaction_no ?? '',
        Created_By: rma.created_by ?? '',
        Created_At: rma.created_at ?? '',
        Tech: rma.tech ?? '',
        Location: rma.location ?? '',
        Turnaround_Time: rma.turnaround_time ?? ''
      }));

      // Download CSV file
      downloadCSV(csvFile, 'rma.csv');
    } catch (error) {
      console.error('Error exporting CSV:', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchRMAs, meta?.last_page]);

  const memoizedColumns = useMemo(() => {
    const columnsToBeFixed = cloneDeep(initialRMATableColumns);
    const columnsWithValue = columnsToBeFixed?.map((x) => ({
      ...x,
      visible: rmaColumnsRedux[x.name]
    }));
    return columnsWithValue;
  }, [rmaColumnsRedux]);

  const hasColumnsAndData = useMemo(() => memoizedColumns[0] && rmas[0], [
    memoizedColumns,
    rmas
  ]);

  useEffect(() => {
    if (tableContainerRef.current) {
      const table = tableContainerRef.current.querySelector('table');
      if (table) {
        setTableWidth(table.scrollWidth);
      }
    }
  }, [tableContainerRef, memoizedColumns]);

  return (
    <Page className={classes.root} title="Return Merchandise Authorization">
      <Toolbar onSearchCustomer={() => {}} />

      <Button
        variant="outlined"
        color="primary"
        startIcon={<GetAppIcon />}
        onClick={onExportToCSV}
        style={{
          margin: '0px 0 10px 15px'
        }}
      >
        Export CSV
      </Button>

      <Button
        variant="outlined"
        endIcon={<ViewWeekIcon />}
        onClick={() => setFilterVisible(!filterVisible)}
        color="primary"
        fullWidth={sm ? true : false}
        style={{
          margin: '0 0 10px 15px'
        }}
      >
        Columns
      </Button>

      <div style={{ position: 'relative' }}>
        {/* Top Horizontal Scroll */}
        <div
          ref={topScrollRef}
          style={{
            width: '100%',
            overflowX: 'scroll',
            height: '16px',
            marginBottom: '4px'
          }}
          onScroll={handleTopScroll}
        >
          <div style={{ width: `${tableWidth}px`, height: '1px' }} />
        </div>

        <LoaderBar isLoading={loading} />
        <TableContainer
          ref={tableContainerRef}
          onScroll={handleScroll}
          style={{
            maxHeight: '85vh',
            overflowX: 'auto',
            overflowY: 'auto',
            scrollbarWidth: 'none',
            opacity: loading ? 0.3 : 1
          }}
        >
          <Table size="small">
            <TableHead
              style={{
                borderBottom: `1px solid ${colors.grey[300]}`
              }}
            >
              <TableRow>
                {memoizedColumns?.map((column) => {
                  if (column?.visible) {
                    return (
                      <TableCell
                        style={{
                          top: 0,
                          position: 'sticky',
                          left: column.sticky ? 0 : 'auto',
                          backgroundColor: column.sticky
                            ? colors.grey[300]
                            : colors.common.white,
                          zIndex: column.sticky ? 2 : 1,
                          borderBottom: `1px solid ${colors.grey[300]}`,
                          borderRight: `1px solid ${colors.grey[300]}`
                        }}
                        key={column?.field}
                      >
                        {snakeCaseToTitleCase(column?.field)}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {rmas?.map((customer: RMAData) => (
                <RMATableRow key={customer?.id} rmaData={customer} />
              ))}
              {rmas?.length === 0 && <RMAEmptySearchView />}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {/* Modal for Choosing Column to Show */}
      <RmaColumnsFilterModal
        visible={filterVisible}
        onHandleClose={() => setFilterVisible(false)}
      />
    </Page>
  );
};

export default RMAView;
