import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectTransaction = (state: RootState) => state.transaction;

const selectTransactions = createSelector(
  selectTransaction,
  (state) => state.transactions
);

const selectTransactionsWithBal = createSelector(
  selectTransaction,
  (state) => state.transactionsWithBal
);

const isCreateTransactionLoading = createSelector(
  selectTransaction,
  (state) => state.isCreateLoading
);

const isSalesTransactionLoading = createSelector(
  selectTransaction,
  (state) => state.isTransactionSalesLoading
);

export const selectors = {
  selectTransactions,
  selectTransactionsWithBal,
  isCreateTransactionLoading,
  isSalesTransactionLoading
};
