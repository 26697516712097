import React, { useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { TapTapCustomerPayment } from 'src/redux/slices/taptap-customer/types';
import { LinkComponent } from 'src/components';
import { formatCurrency, formatDate } from 'src/utils';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { colors } from 'src/constants';
import { usePasteImageHandler } from 'src/hooks/usePasteImageHandler';
import { taptapUploadMultipleImages } from 'src/redux/slices/taptap-customer';
import { clone } from 'lodash';
import { SupabaseClientService } from 'src/services';
interface Props {
  transactionNo?: string;
  customerPayments?: TapTapCustomerPayment[];
  isPaymentsCollapsibleOpen: boolean;
  onClickEditPayment: (payment: TapTapCustomerPayment) => void;
  // onClickAddPayment: () => void;
}

const TapTapCustomerPaymentRow = ({
  customerPayments = [],
  isPaymentsCollapsibleOpen,
  onClickEditPayment,
  transactionNo
}: // onClickAddPayment
Props) => {
  const [selectedCell, setSelectedCell] = useState<number | null>(null);

  const onPasteOnSelectedCell = async (imgFile: File) => {
    if (selectedCell === null) return;
    const paymentDetailsClone = clone(customerPayments?.[selectedCell] || {});
    try {
      const newImage = {
        file: imgFile,
        img_url: URL.createObjectURL(imgFile),
        created_by: undefined,
        created_at: new Date().toISOString()
      };

      const uploadResponse = await taptapUploadMultipleImages('screenshots/', [
        newImage
      ]);

      if (!uploadResponse || uploadResponse?.length === 0) {
        console.error('Image upload failed.');
        return;
      }

      paymentDetailsClone['screenshots'] = [
        ...(paymentDetailsClone?.screenshots || []),
        uploadResponse[0]
      ];

      const { error } = await SupabaseClientService.from('customers_payments')
        .update({ screenshots: paymentDetailsClone['screenshots'] })
        .eq('id', customerPayments?.[selectedCell]?.id);

      if (error) {
        console.error('Database update failed:', error);
        return;
      }
    } catch (error) {
      console.error('Error in onPasteOnSelectedCell:', error);
    }
  };

  usePasteImageHandler({ onPaste: onPasteOnSelectedCell });

  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
        <Collapse in={isPaymentsCollapsibleOpen} timeout="auto" unmountOnExit>
          <Box margin={2}>
            {customerPayments && customerPayments?.length > 0 && (
              <Table size="small" aria-label="payments">
                <TableHead>
                  <TableRow>
                    <TableCell>Acknowledgement Receipt No.</TableCell>
                    <TableCell>Payment Method</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Screenshots</TableCell>
                    <TableCell>Date Added</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(customerPayments || [])?.map((payment, index) => {
                    return (
                      <TableRow hover key={payment?.id}>
                        <TableCell>
                          {payment?.acknowledgement_receipt_no || '--'}
                        </TableCell>
                        <TableCell component="th">
                          {payment?.payment_type}
                        </TableCell>
                        <TableCell component="th">
                          {formatCurrency(payment?.amount)}
                        </TableCell>
                        <TableCell
                          component="th"
                          onClick={() => setSelectedCell(index)}
                          style={{
                            cursor: 'pointer',
                            backgroundColor:
                              selectedCell === index
                                ? '#e3f2fd'
                                : 'transparent',
                            border:
                              selectedCell === index
                                ? '2px solid #2196f3'
                                : '1px solid transparent'
                          }}
                        >
                          {Array.isArray(payment?.screenshots)
                            ? payment?.screenshots?.map((screenshot, i) => (
                                <LinkComponent
                                  key={i}
                                  openInNewTab
                                  title={
                                    <Typography
                                      style={{ color: colors.link }}
                                      variant="inherit"
                                    >
                                      {`screenshot-${i + 1}, `}
                                    </Typography>
                                  }
                                  href={screenshot?.img_url}
                                />
                              ))
                            : JSON.stringify(payment?.screenshots)}

                          <IconButton
                            size="small"
                            onClick={() => onClickEditPayment(payment)}
                          >
                            <EditOutlinedIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          {formatDate(
                            payment?.created_at,
                            'EEEE, MMM d, yyyy h:mm a'
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}

            {transactionNo ? (
              <LinkComponent
                openInNewTab
                title={
                  <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    style={{ margin: 10 }}
                    startIcon={<AttachMoneyIcon />}
                  >
                    Add Payment
                  </Button>
                }
                href={`/app/transaction/${transactionNo}`}
              />
            ) : (
              <Typography>Not Available. Need Transaction no.</Typography>
            )}
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default TapTapCustomerPaymentRow;
