import React, { useMemo } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import {
  AlternatingColorTableRow,
  LinkComponent,
  LoaderBar
} from 'src/components';
import { GetTransactionsViaTransactionNoResponse } from 'src/types';
import { formatCurrency } from 'src/utils';
import { useNavigate } from 'react-router-dom';
import { colors } from 'src/constants';
import { cloneDeep } from 'lodash';
import { sortProductsByCategory } from 'src/utils/categories/sortByCategory';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  addBtn: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  extraInfo: {
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  }
}));

interface Props {
  isLoading?: boolean;
  poweredBuildDataThatLooksLikeTransaction?: GetTransactionsViaTransactionNoResponse;
}

const component = ({
  isLoading,
  poweredBuildDataThatLooksLikeTransaction
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  //sorted values
  const sortedTransactionItems = useMemo(() => {
    if (poweredBuildDataThatLooksLikeTransaction?.items) {
      const sortedTransactionItems =
        cloneDeep(poweredBuildDataThatLooksLikeTransaction?.items) || [];
      return sortProductsByCategory(sortedTransactionItems);
    }
    return [];
  }, [poweredBuildDataThatLooksLikeTransaction]);

  return (
    <Card style={{ marginTop: 10 }}>
      <CardHeader
        title={<Typography variant="h5">Powered Build SN. TODO</Typography>}
        subheader={
          <div>
            <Typography variant="h6">
              Powered Build Total Price:{' '}
              {formatCurrency(
                poweredBuildDataThatLooksLikeTransaction?.total_price // TODO: Memoize this
              )}
            </Typography>
            {/* <Typography variant="caption">{`Transaction No.: ${transactionDetails?.data?.transaction_no}. Created By: ${transactionDetails?.data?.created_by}. Posted on ${transactionDetails?.data?.date_posted}`}</Typography> */}
            <Typography variant="caption">
              TODO: Add powered Build Info
            </Typography>
          </div>
        }
      />
      <Divider />
      <LoaderBar isLoading={isLoading || false} />

      <CardContent>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Branch</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Product Name</TableCell>
                <TableCell>Serial No.</TableCell>
                <TableCell>Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedTransactionItems?.map((item, i) => (
                <AlternatingColorTableRow
                  style={
                    item?.isDeleted ? { backgroundColor: colors.error } : {}
                  }
                  hover
                  key={`${item?.serial_no}${i}`}
                >
                  <TableCell>{item?.branch_name ?? '--'}</TableCell>
                  <TableCell>{item?.category_name ?? '--'}</TableCell>
                  <TableCell>
                    <LinkComponent
                      openInNewTab
                      onClick={() =>
                        navigate(`/app/products/${item?.product_id}`)
                      }
                      href={`/app/products/${item?.product_id}`}
                      title={`${item?.product_name ?? '--'}`}
                    />
                  </TableCell>
                  <TableCell>
                    {item?.isDeleted ? (
                      item?.serial_no ?? '--'
                    ) : (
                      <LinkComponent
                        onClick={() =>
                          navigate(`/app/products-listings/${item.listing_id}`)
                        }
                        href={`/app/products-listings/${item.listing_id}`}
                        title={item?.serial_no ?? '--'}
                      />
                    )}
                  </TableCell>

                  <TableCell>
                    {formatCurrency(item?.amount || item?.retail_price)}
                  </TableCell>
                </AlternatingColorTableRow>
              ))}
            </TableBody>
          </Table>

          <div className={classes.extraInfo}>
            <Typography variant="h4">
              TOTAL PRICE:{' '}
              {`${formatCurrency(
                poweredBuildDataThatLooksLikeTransaction?.total_price
              ) ?? `0.00`}`}
            </Typography>
          </div>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export const TapTapPowerBuildsCard = React.memo(component);
