import { useEffect } from 'react';
interface Props {
  onPaste: (imgUrl: File) => void;
}

export const usePasteImageHandler = ({ onPaste }: Props) => {
  useEffect(() => {
    const handlePasteFile = async (event: ClipboardEvent) => {
      if (onPaste === null) return;
      if ((event.clipboardData?.files?.length ?? 0) > 0) {
        const file = event.clipboardData?.files[0];
        if (!file || !file.type.startsWith('image')) return;
        file && onPaste(file);
      }
    };
    document.addEventListener('paste', handlePasteFile);
    return () => {
      document.removeEventListener('paste', handlePasteFile);
    };
  }, [onPaste]);
};
