import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Table
} from '@material-ui/core';
import { capitalize } from 'lodash';
import React, { FC, useMemo } from 'react';
import { AuditBase } from 'src/types';
import { getStatusColor } from 'src/utils';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface Props {
  item: AuditBase;
  index: number;
  expandFirstCard: boolean;
  formatValue?: (key: string, value: any) => string | JSX.Element;
}

export const AuditRow: FC<Props> = ({
  item,
  index,
  expandFirstCard = false,
  formatValue
}) => {
  const old_values = useMemo(() => item.old_values ?? {}, [item]);
  const new_values = useMemo(() => item.new_values ?? {}, [item]);

  const showOldValuesColumn = useMemo(
    () => Object.keys(old_values).length > 0,
    [old_values]
  );

  const allKeys = useMemo(
    () =>
      Array.from(
        new Set([
          ...Object.keys(old_values ?? {}),
          ...Object.keys(new_values ?? {})
        ])
      ),
    [new_values, old_values]
  );

  const formatKey = (key: string) => capitalize(key.replace(/_/g, ' '));

  const formatDisplayValue = (value: any) => {
    if (typeof value === 'string') {
      return value;
    }
    if (value && typeof value === 'object') {
      return JSON.stringify(value);
    }
    return value ?? 'N/A';
  };

  return (
    <Accordion
      defaultExpanded={index === 0 && expandFirstCard}
      key={item.audit_id || index}
      style={{ marginBottom: '0.5rem' }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id={`panel-${index}-header`}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography>
            {`${capitalize(item.event || 'N/A')} by ${item.performed_by ||
              ''} ${item.user_type ? `(${capitalize(item.user_type)})` : ''}`}
          </Typography>
          {item.updated_at ? (
            <Typography variant="subtitle1">{item.updated_at}</Typography>
          ) : null}
          {item.endpoint ? (
            <Typography variant="subtitle1">{item.endpoint}</Typography>
          ) : null}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {showOldValuesColumn && <TableCell>Previous Changes</TableCell>}
                <TableCell>New Changes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allKeys.map((key) => (
                <TableRow key={key}>
                  {showOldValuesColumn && (
                    <TableCell>
                      {key === 'status' ? (
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px'
                          }}
                        >
                          From Status:
                          <Typography
                            style={{
                              color: getStatusColor(
                                formatValue
                                  ? formatValue(key, old_values[key])
                                  : formatDisplayValue(old_values[key])
                              )
                            }}
                          >
                            {formatValue
                              ? formatValue(key, old_values[key])
                              : formatDisplayValue(old_values[key])}
                          </Typography>
                        </span>
                      ) : (
                        `From ${formatKey(key)}: ${
                          formatValue
                            ? formatValue(key, old_values[key])
                            : formatDisplayValue(old_values[key])
                        }`
                      )}
                    </TableCell>
                  )}
                  <TableCell>
                    {key === 'status' ? (
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px'
                        }}
                      >
                        To Status:
                        <Typography
                          style={{
                            color: getStatusColor(
                              formatValue
                                ? formatValue(key, new_values[key])
                                : formatDisplayValue(new_values[key])
                            )
                          }}
                        >
                          {formatValue
                            ? formatValue(key, new_values[key])
                            : formatDisplayValue(new_values[key])}
                        </Typography>
                      </span>
                    ) : (
                      `To ${formatKey(key)}: ${
                        formatValue
                          ? formatValue(key, new_values[key])
                          : formatDisplayValue(new_values[key])
                      }`
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};
