import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectRmaState = (state: RootState) => state.rma;

const selectRmaList = createSelector(selectRmaState, (state) => state.rmas);

const selectRmaGetPayload = createSelector(
  selectRmaState,
  (state) => state.rmaGetPayload
);

const selectRMAColumns = createSelector(
  selectRmaState,
  (state) => state.rmaColumn
);

const selectPaginationMeta = createSelector(
  selectRmaState,
  (state) => state.paginationMeta
);

const selectLoading = createSelector(selectRmaState, (state) => state.loading);

export const selectors = {
  selectRmaList,
  selectRmaGetPayload,
  selectRMAColumns,
  selectPaginationMeta,
  selectLoading
};
