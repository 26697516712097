import React, { useMemo, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';

import { formatCurrency, formatDate, getPaymentTypeColor } from 'src/utils';
import { colors } from 'src/constants';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import {
  AlternatingColorTableRow,
  LinkComponent,
  LoaderBar
} from 'src/components';
import { usePermissions } from 'src/hooks';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import RefreshIcon from '@material-ui/icons/Refresh';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { TapTapCustomerPayment } from 'src/redux/slices/taptap-customer/types';
import TapTapCustomerPaymentDetailsDialog from '../TapTapCustomerPaymentDetailsDialog';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  extraInfo: {
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  addBtn: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

interface Props {
  purpose?: 'preview' | 'edit';
  isLoading?: boolean;
  transactionNo: string;
  data?: TapTapCustomerPayment[];
  onFetchPayments?: () => void;
  onSyncPayments?: () => void;
  onClickEditPayment?: (payment: TapTapCustomerPayment) => void;
  currentFormikValues: any;
}

const PaymentsCard = ({
  purpose = 'edit',
  isLoading,
  data = [],
  onFetchPayments,
  onSyncPayments,
  transactionNo,
  currentFormikValues
}: Props) => {
  const classes = useStyles();
  const { canAddTransactionPayment } = usePermissions();

  const [isExpanded, setIsExpanded] = useState(canAddTransactionPayment);

  const [
    editCustomerPaymentViaCustomerIDDialogVisible,
    setEditCustomerPaymentViaCustomerIDDialogVisible
  ] = useState<TapTapCustomerPayment>();

  const totalAmountPaid = useMemo(
    () => data?.reduce((acc, item) => acc + (item.amount || 0), 0) || 0,
    [data]
  );

  const currentBalance = useMemo(
    () => currentFormikValues?.total_amount - totalAmountPaid,
    [currentFormikValues, totalAmountPaid]
  );

  return (
    <Card style={{ marginTop: 10 }}>
      <CardHeader
        avatar={
          <Button
            color="primary"
            variant="text"
            onClick={() => setIsExpanded((prev) => !prev)}
            startIcon={isExpanded ? <ExpandLess /> : <ExpandMoreIcon />}
          />
        }
        action={
          purpose === 'edit' && (
            <Button
              startIcon={<RefreshIcon />}
              variant="outlined"
              color="primary"
              onClick={onSyncPayments}
              style={{ justifyContent: 'center' }}
            >
              Sync Payment Data
            </Button>
          )
        }
        title={
          <Typography variant="h5" style={{ color: colors.purple[500] }}>
            TOTAL PAID AMOUNT: {`${formatCurrency(totalAmountPaid) ?? `0.00`}`}
          </Typography>
        }
        subheader={
          <div>
            <Typography variant="subtitle2" style={{ color: colors.blue[500] }}>
              total transaction amount:{' '}
              {formatCurrency(currentFormikValues?.total_amount) || '0.00'}
            </Typography>
            <Typography variant="subtitle2" style={{ color: colors.red[500] }}>
              total balance: {formatCurrency(currentBalance) || '0.00'}
            </Typography>
          </div>
        }
      />
      <LoaderBar isLoading={isLoading || false} />
      <Divider />

      <Collapse in={isExpanded}>
        <CardContent>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Acknowledgement Receipt No.</TableCell>
                  <TableCell>Type / MOP</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Screenshots</TableCell>
                  <TableCell>Date Posted</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data?.map((item: TapTapCustomerPayment) =>
                  !item?.deleted_by ? (
                    <AlternatingColorTableRow hover key={item.id}>
                      <TableCell>
                        {item?.acknowledgement_receipt_no || '--'}
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="h6"
                          style={{
                            color: getPaymentTypeColor(item?.payment_type)
                          }}
                        >
                          {item?.payment_type || '--'}
                        </Typography>
                      </TableCell>
                      <TableCell>{formatCurrency(item?.amount)}</TableCell>

                      <TableCell component="th">
                        {item?.screenshots?.map((screenshot, i) => (
                          <LinkComponent
                            key={i}
                            openInNewTab
                            title={
                              <Typography
                                style={{ color: colors.link }}
                                variant="inherit"
                              >{`screenshot-${i + 1}, `}</Typography>
                            }
                            href={screenshot?.img_url}
                          />
                        ))}

                        <IconButton
                          disabled={isLoading || purpose === 'preview'}
                          size="small"
                          onClick={() =>
                            setEditCustomerPaymentViaCustomerIDDialogVisible(
                              item
                            )
                          }
                        >
                          <EditOutlinedIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        {formatDate(
                          item?.created_at,
                          'EEEE, MMM d, yyyy h:mm a'
                        ) || '--'}
                      </TableCell>
                    </AlternatingColorTableRow>
                  ) : null
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>

        <Divider />
        <CardActions style={{ justifyContent: 'flex-end' }}>
          <LinkComponent
            href={`/app/transaction/${transactionNo}`}
            title={
              <Button
                disabled={
                  isLoading ||
                  !canAddTransactionPayment ||
                  purpose === 'preview'
                }
                startIcon={<LocalAtmIcon />}
                color="primary"
                variant="contained"
                className={classes.addBtn}
              >
                Add payment
              </Button>
            }
          />
        </CardActions>
      </Collapse>

      <TapTapCustomerPaymentDetailsDialog
        paymentDetails={editCustomerPaymentViaCustomerIDDialogVisible}
        isVisible={editCustomerPaymentViaCustomerIDDialogVisible !== undefined}
        handleClose={() => {
          if (onFetchPayments) {
            onFetchPayments();
          }
          setEditCustomerPaymentViaCustomerIDDialogVisible(undefined);
        }}
      />
    </Card>
  );
};

export const TapTapPaymentsCard = React.memo(PaymentsCard);
