import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Container,
  LinearProgress,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Page } from 'src/components';
import { TransferStockLogToolBar } from './TransferStockLogToolBar';
import { useTransferStockLogs } from 'src/hooks/transfer-stock-logs';
import { TransferStockFilterAciton } from 'src/redux/slices/transfer-stock-logs';
import { TransferStockLogsTable } from './TransferStockLogsTable';
import { Alert, Pagination } from '@material-ui/lab';
import { cleanSN } from 'src/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

export const TransferStockLogsView = () => {
  const classes = useStyles();
  const {
    transferLogsData,
    transferLogsParams,
    isTransferLogsLoading,
    metaTransferLogs,
    setTransferLogsParams,
    getTransferStockLogs
  } = useTransferStockLogs();
  const [searchKeyword, setSearchKeyword] = useState<string>();
  const [filterAction, setFilterAction] = useState<TransferStockFilterAciton>(
    'product'
  );
  const [isDateRangeRequire, setIsDateRangeRequire] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const handleSearchParams = useCallback(
    (action: TransferStockFilterAciton) => {
      switch (action) {
        case 'product':
          setTransferLogsParams((prev) => ({
            ...prev,
            product_name: searchKeyword,
            dr_no: undefined,
            serial_no: undefined,
            st_no: undefined
          }));
          break;
        case 'dr_no':
          setTransferLogsParams((prev) => ({
            ...prev,
            dr_no: searchKeyword,
            product_name: undefined,
            serial_no: undefined,
            st_no: undefined
          }));
          break;
        case 'serial_no':
          setTransferLogsParams((prev) => ({
            ...prev,
            serial_no: cleanSN(searchKeyword ?? ''),
            dr_no: undefined,
            product_name: undefined,
            st_no: undefined
          }));
          break;
        case 'st_no':
          setTransferLogsParams((prev) => ({
            ...prev,
            st_no: searchKeyword,
            dr_no: undefined,
            serial_no: undefined,
            product_name: undefined
          }));
          break;
      }
    },
    [searchKeyword, setTransferLogsParams]
  );

  const onChangeSearchFilterAction = useCallback(
    (action: TransferStockFilterAciton) => {
      setFilterAction(action);
    },
    []
  );

  const onChanggeSearchKeyword = useCallback((keyword?: string) => {
    setSearchKeyword(keyword);
  }, []);

  const onPressApplyfilter = useCallback(() => {
    if (
      (!transferLogsParams?.product_name &&
        !transferLogsParams?.dr_no &&
        !transferLogsParams?.serial_no &&
        !transferLogsParams?.st_no) &&
      (!transferLogsParams?.from_transferred_date ||
      !transferLogsParams?.to_transferred_date)
    ) {
      setIsDateRangeRequire(true);
      return;
    }

    //every request reset back the page number to 1
    setPageNumber(1);
    getTransferStockLogs({ page: 1, ...transferLogsParams });
  }, [getTransferStockLogs, transferLogsParams]);

  const onCloseError = () => {
    setIsDateRangeRequire(false);
  };

  const onChangePage = (event: any, pageNum: number) => {
    setPageNumber(pageNum);
    getTransferStockLogs({ page: pageNum, ...transferLogsParams });
  };

  useEffect(() => {
    handleSearchParams(filterAction);
  }, [filterAction, handleSearchParams]);

  return (
    <Page title={'Transfer Stock Logs'} className={classes.root}>
      <Container maxWidth="xl" style={{ minHeight: '85vh' }}>
        <Box
          mb={3}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography color="textPrimary" gutterBottom variant="h2">
            {'Transfer Stock Logs'}
          </Typography>
        </Box>
        <TransferStockLogToolBar
          isLoading={isTransferLogsLoading}
          dateRangeRequire={isDateRangeRequire}
          onHandleApplyFilter={onPressApplyfilter}
          onHandleDateFrom={(dateFrom?: string | undefined) =>
            setTransferLogsParams((prev) => ({
              ...prev,
              from_transferred_date: dateFrom
            }))
          }
          onHandleDateTo={(dateTo?: string | undefined) =>
            setTransferLogsParams((prev) => ({
              ...prev,
              to_transferred_date: dateTo
            }))
          }
          onHandleSearchBy={(keyword?: string) => {
            onChanggeSearchKeyword(keyword);
            // onHandleSearchParams(keyword);
          }}
          onHandleFilterAction={(action: TransferStockFilterAciton) => {
            onChangeSearchFilterAction(action);
          }}
          onHandleBranchReceiver={(branch_id?: number | undefined) => {
            setTransferLogsParams((prev) => ({
              ...prev,
              branch_receiver: branch_id
            }));
          }}
          onHandleCloseError={onCloseError}
        />

        {isTransferLogsLoading && <LinearProgress />}
        {!transferLogsData && (
          <Alert severity="info" style={{ marginTop: '1rem' }}>
            <Typography variant="subtitle2">
              Set Date Transfer Range To View Transfer Stock Logs
            </Typography>
          </Alert>
        )}
        {transferLogsData && transferLogsData.length > 0 && (
          <Box sx={{ mt: 1 }}>
            <TransferStockLogsTable transferLogsData={transferLogsData} />
          </Box>
        )}
        {transferLogsData && transferLogsData?.length > 0 && (
          <Box width={'100%'} p={3} display="flex" justifyContent="flex-end">
            <Pagination
              page={pageNumber}
              color="primary"
              variant="outlined"
              onChange={onChangePage}
              count={metaTransferLogs?.last_page}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
};
