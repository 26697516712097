import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';

const useResolution = () => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  // Mui Breakpoints: These are usable for the style object of anything including non mui components
  const xs = useMemo(() => width <= 500, [width]);
  const sm = useMemo(() => width <= 600, [width]); // tablet potrait width
  const md = useMemo(() => width <= 900, [width]);
  const lg = useMemo(() => width <= 1200, [width]);
  const lg_plus = useMemo(() => width <= 1279, [width]);
  const xl = useMemo(() => width > 1200, [width]);

  const isIPadAir = useMemo(() => {
    return width === 820 && height === 1180;
  }, [height, width]);

  const isIPadPro = useMemo(() => {
    return width === 1024 && height === 1366;
  }, [height, width]);

  const isMobile = useMemo(() => {
    const userAgent = navigator.userAgent;
    const mobileOS = /Android|iPhone|iPad|iPod|Windows Phone|BlackBerry|Opera Mini|IEMobile/i;

    return mobileOS.test(userAgent) && (width <= 1024 || height <= 1024); // some tablet have 1024 width
  }, [height, width]);

  const isLandscape = useMemo(() => {
    return width > height;
  }, [height, width]);

  const isLandscapeForDevices = useMemo(() => {
    return (isMobile || isIPadAir || isIPadPro || lg) && width > height;
  }, [isMobile, isIPadAir, isIPadPro, lg, width, height]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const windowListener = useCallback(
    debounce(() => {
      if (window) {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
      }
    }, 250),
    []
  );

  useEffect(() => {
    if (window) {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
      window.addEventListener('resize', windowListener);
    }
    return () => {
      windowListener.cancel();
      window && window.removeEventListener('resize', windowListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    width,
    height,
    isMobile,
    isIPadAir,
    isIPadPro,
    isLandscape,
    isLandscapeForDevices,
    xs,
    sm,
    md,
    lg,
    lg_plus,
    xl
  };
};

export default useResolution;
