import React, { useCallback } from 'react';
import {
  makeStyles,
  TableBody,
  TableCell,
  Typography
} from '@material-ui/core';

import { Inventory, ManufacturerOption } from 'src/types';
import { slices, useAppSelector } from 'src/redux';
import {
  formatCurrency,
  formatDate,
  getStatusColor,
  manufacturerIDToName
} from 'src/utils';
import { ProductTypeEnum } from 'src/enums';
import { AlternatingColorTableRow, LinkComponent } from 'src/components';
import { usePermissions } from 'src/hooks';

const { selectors: inventorySelectors } = slices.inventory;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  inventoryCard: {
    height: '100%'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  tableRow: {
    height: 35,
    padding: 10
  },
  priceInput: {
    height: 30
  }
}));

interface Props {
  manufacturers?: ManufacturerOption[];
}

const TableInventoryBody = ({ manufacturers }: Props) => {
  const classes = useStyles();
  const inventoryResponse = useAppSelector(
    inventorySelectors.selectInventoryResponse
  );
  const inventoryColumns = useAppSelector(
    inventorySelectors.selectInventoryColumns
  );
  const { canSeeDealersPrice } = usePermissions();

  const getItemSerialNo = useCallback((item: Inventory) => {
    if (item?.product_type === ProductTypeEnum.Consumable) {
      return '--';
    }
    if (item?.serial_no) {
      return item?.serial_no;
    }
    return '--';
  }, []);

  return (
    <TableBody>
      {inventoryResponse?.items?.map((item: Inventory, index: number) => (
        <AlternatingColorTableRow
          className={classes.tableRow}
          onClick={() => {}}
          hover
          key={`${index}-${item.id}`}
        >
          {inventoryColumns?.id && (
            <TableCell className={classes.tableRow}>
              {item?.id || '--'}
            </TableCell>
          )}

          {inventoryColumns?.quantity && (
            <TableCell className={classes.tableRow}>
              {item?.quantity || '--'}
            </TableCell>
          )}

          {inventoryColumns?.product_name && (
            <TableCell className={classes.tableRow}>
              {item?.product_name || '--'}
            </TableCell>
          )}

          {inventoryColumns?.sku && (
            <TableCell className={classes.tableRow}>
              {item?.sku || '--'}
            </TableCell>
          )}

          {inventoryColumns?.dr_no && (
            <TableCell className={classes.tableRow}>
              {item?.dr_no || '--'}
            </TableCell>
          )}

          {inventoryColumns?.serial_no && (
            <TableCell className={classes.tableRow}>
              {getItemSerialNo(item)}
            </TableCell>
          )}

          {inventoryColumns?.status && (
            <TableCell className={classes.tableRow}>
              <Typography style={{ color: getStatusColor(item?.status) }}>
                {item.status}
              </Typography>
            </TableCell>
          )}

          {inventoryColumns?.dealers_price && canSeeDealersPrice ? (
            <TableCell className={classes.tableRow}>
              {formatCurrency(item?.dealers_price)}
            </TableCell>
          ) : null}

          {inventoryColumns?.retail_price && (
            <TableCell className={classes.tableRow}>
              {formatCurrency(item?.retail_price)}
            </TableCell>
          )}

          {inventoryColumns?.manufacturer_id && (
            <TableCell className={classes.tableRow}>
              {manufacturerIDToName(item.manufacturer_id, manufacturers) ??
                '--'}
            </TableCell>
          )}

          {inventoryColumns?.supplier_name && (
            <TableCell className={classes.tableRow}>
              {item?.supplier_name || '--'}
            </TableCell>
          )}

          {inventoryColumns?.branch_name && (
            <TableCell className={classes.tableRow}>
              {item?.branch_name || '--'}
            </TableCell>
          )}

          {inventoryColumns?.encoded_by && (
            <TableCell className={classes.tableRow}>
              {item?.encoded_by || '--'}
            </TableCell>
          )}

          {inventoryColumns?.released_by && (
            <TableCell className={classes.tableRow}>
              {item?.released_by || '--'}
            </TableCell>
          )}

          {inventoryColumns?.transaction_no && (
            <TableCell className={classes.tableRow}>
              <LinkComponent
                href={`/app/transaction/${item?.transaction_no}`}
                target="_blank"
                rel="noopener noreferrer"
                title={`${item?.transaction_no ?? '--'}`}
              />
            </TableCell>
          )}

          {inventoryColumns?.customer_name && (
            <TableCell className={classes.tableRow}>
              {item?.customer_name || '--'}
            </TableCell>
          )}

          {inventoryColumns?.date_sold && (
            <TableCell className={classes.tableRow}>
              {item?.date_sold ? formatDate(item?.date_sold) : '--'}
            </TableCell>
          )}

          {inventoryColumns?.date_purchased && (
            <TableCell className={classes.tableRow}>
              {item?.date_purchased ? formatDate(item?.date_purchased) : '--'}
            </TableCell>
          )}

          {inventoryColumns?.date_encoded && (
            <TableCell className={classes.tableRow}>
              {item?.date_encoded ? formatDate(item?.date_encoded) : '--'}
            </TableCell>
          )}
        </AlternatingColorTableRow>
      ))}
    </TableBody>
  );
};

export const InventoryTableBody = React.memo(TableInventoryBody);
