import React from "react";
import { Dialog, DialogContent, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

interface ImageGalleryDialogProps {
  images: { file_url: string }[];
  isPreviewOpen: boolean;
  isGalleryOpen: boolean;
  selectedImage: string | null;
  onClosePreview: () => void;
  onCloseGallery: () => void;
  openPreview: (imgUrl: string) => void;
}

const PreviewImageDialog: React.FC<ImageGalleryDialogProps> = ({
  images,
  isPreviewOpen,
  isGalleryOpen,
  selectedImage,
  onClosePreview,
  onCloseGallery,
  openPreview,
}) => {
  return (
    <>
      {/* Inline Image Preview Modal */}
      <Dialog open={isPreviewOpen} onClose={onClosePreview} maxWidth="md">
        <DialogContent style={{ textAlign: "center", position: "relative" }}>
          <IconButton onClick={onClosePreview} style={{ position: "absolute", right: 10, top: 10 }}>
            <CloseIcon />
          </IconButton>
          <img
            src={selectedImage || ''}
            alt="Preview"
            style={{ maxWidth: "100%", maxHeight: "80vh", borderRadius: "10px" }}
          />
        </DialogContent>
      </Dialog>

      {/* Full Image Gallery Dialog */}
      <Dialog open={isGalleryOpen} onClose={onCloseGallery} maxWidth="md">
        <DialogContent>
          <IconButton onClick={onCloseGallery} style={{ position: "absolute", right: 10, top: 10 }}>
            <CloseIcon />
          </IconButton>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
            {images.map((img, index) => (
              <img
                key={index}
                src={img?.file_url}
                alt={`full_img_${index + 1}`}
                style={{
                  width: 120,
                  height: 120,
                  objectFit: "cover",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={() => openPreview(img?.file_url)}
              />
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PreviewImageDialog;
