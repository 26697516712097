import React, { useEffect } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  InputAdornment,
  SvgIcon,
  Typography,
  makeStyles,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { CustomInputEvent, CustomKeyboardEvent } from 'src/types';
import { multiBranchFeat } from 'src/constants/feature-toggle';
import useUserInfo from 'src/hooks/user/use-user-info';
import useResolution from 'src/hooks/useResolution';

interface Props {
  className?: string;
  searchText?: string;
  selectedBranchIDs?: number[];
  setSearchText: (val: string) => void;
  setSelectedBranchIDs: (val: number[]) => void;
  onSearchTransaction: (keyword: string, branch_ids: number[]) => void;
  isLoading: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  cardContent: {
    flexDirection: 'row'
  }
}));

const Toolbar = ({
  className,
  searchText,
  setSearchText,
  onSearchTransaction,
  selectedBranchIDs,
  setSelectedBranchIDs,
  isLoading,
  ...rest
}: Props) => {
  const classes = useStyles();
  const { getUserDetails, userBranchOptions } = useUserInfo();
  const { sm } = useResolution();

  const handleChange = (event: CustomInputEvent) => {
    setSearchText(event.target.value);
  };

  const onSearchClick = () => {
    onSearchTransaction(searchText || '', selectedBranchIDs || []);
  };

  const onKeyPress = (e: CustomKeyboardEvent) => {
    if (e.key === 'Enter') {
      onSearchTransaction(searchText || '', selectedBranchIDs || []);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, [getUserDetails]);
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Typography color="textPrimary" gutterBottom variant="h3">
          Transactions
        </Typography>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Grid alignItems="center" container spacing={2}>
              <Grid item lg={4} md={3} xs={6}>
                <Box maxWidth={500}>
                  <TextField
                    disabled={isLoading}
                    fullWidth
                    value={searchText}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Search transaction"
                    variant="outlined"
                    onKeyPress={onKeyPress}
                  />
                </Box>
              </Grid>
              {multiBranchFeat ? (
                <Grid item xs={6} sm={3} md={3} lg={3}>
                  <FormControl required fullWidth>
                    <InputLabel variant="outlined" id="branch">
                      Branch
                    </InputLabel>
                    <Select
                      defaultValue={''}
                      disabled={isLoading}
                      value={selectedBranchIDs}
                      variant="outlined"
                      label="Branch"
                      multiple={userBranchOptions?.length > 1}
                      onChange={(e: any) => {
                        setSelectedBranchIDs(
                          Array.isArray(e.target.value)
                            ? e.target.value
                            : [e.target.value]
                        );
                      }}
                      input={<OutlinedInput label="Branch" />}
                    >
                      {userBranchOptions.map((b) => (
                        <MenuItem key={b.value} value={b.value}>
                          {b.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              ) : null}
              <Grid
                alignItems="center"
                item
                xs={12}
                sm={2}
                md={3}
                lg={2}
                style={{
                  ...(sm && {
                    display: 'flex',
                    justifyContent: 'end'
                  })
                }}
              >
                <Button
                  onClick={onSearchClick}
                  color="primary"
                  variant="contained"
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default Toolbar;
