import Papa from 'papaparse';
import { v4 as uuid } from 'uuid';

export const downloadCSV = (data: any[] = [], filename?: string) => {
  const csv = Papa.unparse(data);
  const blob = new Blob([csv], { type: 'text/csv' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  const uuidName = uuid();
  link.download = filename || uuidName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadSupabaseCSV = (
  data: string = '',
  filename: string = 'data.csv'
) => {
  const blob = new Blob([data], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  document.body.appendChild(a);
  a.download = filename;
  a.click();
  document.body.removeChild(a);
};
