import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import React, { FC, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { BundleSummary } from 'src/redux/slices/pc-bundles/types';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';

interface Props {
  bundle: BundleSummary;
}

export const BundleCard: FC<Props> = ({ bundle }) => {
  const navigate = useNavigate();

  // band-aid
  // will probably be replaced with manufacturer logo?
  const brandTitle = useMemo(() => {
    return bundle.brand?.replace(/powered by/i, '').trim();
  }, [bundle]);

  const bundleID = useMemo(() => bundle.pc_bundle_id, [bundle]);

  const bundleGroupCounts = useMemo(() => {
    const { available_component_groups_count } = bundle;
    if (!available_component_groups_count) {
      return null;
    }
    const countsObj = Object.entries(available_component_groups_count);
    return countsObj[0] ? countsObj : null;
  }, [bundle]);

  return (
    <Card style={{ marginBottom: '1em' }}>
      <CardContent style={{ padding: '1em' }}>
        <Grid container>
          <Grid
            item
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
            xs={9}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Typography variant="h5">{bundle.title}</Typography>
              {bundleGroupCounts?.[0] ? (
                <Box style={{ display: 'flex', columnGap: '1em' }}>
                  {bundleGroupCounts?.map((i) => (
                    <Typography
                      style={{ fontWeight: 'bold', color: '#7D879C' }}
                      variant="subtitle1"
                      key={i[0]}
                    >{`${i[0]}: ${i[1]}`}</Typography>
                  ))}
                </Box>
              ) : null}
            </Box>

            <Box style={{ display: 'flex' }}>
              <Typography variant="h4">{brandTitle}</Typography>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <CreateOutlinedIcon
                  style={{
                    cursor: 'pointer',
                    fontSize: '1em',
                    marginLeft: '1em',
                    height: '100%'
                  }}
                  onClick={() => navigate(`/app/pc-bundles/${bundleID}`)}
                />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end'
            }}
          >
            <Button
              variant="outlined"
              onClick={() =>
                navigate(`/app/pc-bundles/components/${bundleID}`, {
                  state: { brand: bundle.brand, build_name: bundle.title }
                })
              }
            >
              Add Component Group
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
