import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Tooltip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { copyToClipboard, formatCurrency } from 'src/utils';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { LinkComponent } from 'src/components';
import { priceColumn } from 'src/utils/fast-moving-products';
import { SelectedColumn } from 'src/types';
import useResolution from 'src/hooks/useResolution';
import { BigCalendar } from './BigCalender';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { useFastMovingProducts } from 'src/hooks/fast-moving-products';

const useStyles = makeStyles({
  tableContainer: {
    marginTop: 16,
    border: '1px solid black',
    maxHeight: 600,
    overflow: 'auto'
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    minWidth: 800
  },
  tableCell: {
    border: '1px solid black',
    padding: 8,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    zIndex: 0
  },
  tableHeaderCell: {
    border: '2px solid black',
    fontWeight: 'bold',
    backgroundColor: '#f0f0f0',
    textAlign: 'center',
    position: 'sticky',
    top: 0,
    zIndex: 12
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#e0e0e0'
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#ffffff'
    },
    '&:hover': {
      background: '#bdbdbd',

      // Ensure sticky column gets the same hover effect
      '& $stickyColumn': {
        background: '#bdbdbd'
      }
    }
  },
  stickyColumn: {
    position: 'sticky',
    left: 0,
    zIndex: 11,
    backgroundColor: '#f0f0f0',
    border: '1px solid black',

    // Ensure hover applies from row hover
    '&:hover, $tableRow:hover &': {
      background: '#bdbdbd'
    }
  },
  stickyHeaderColumn: {
    position: 'sticky',
    left: 0,
    top: 0,
    zIndex: 99,
    backgroundColor: '#f0f0f0',
    borderBottom: '2px solid gray'
  }
});

interface Props {
  headers?: string[];
  fastMovingData?: any[];
  columnToShow?: SelectedColumn[];
}

const Component: FC<Props> = ({ fastMovingData, columnToShow }) => {
  const classes = useStyles();
  const { lg } = useResolution();
  const { getSoldTimelineParams } = useFastMovingProducts();
  const [customFastMovingData, setcustomFastMovingData] = useState<any>();
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);

  //this is highly focus on defining the keys for each column
  const filteredShowColumns = useMemo(() => {
    if (!columnToShow) return ['product_name'];

    const soldColumns: string[] = [];
    const availableColumns: string[] = [];
    const otherColumns: string[] = [];

    columnToShow.forEach((column) => {
      const name = column.name;
      if (name?.includes('_sold')) {
        soldColumns.push(name);
      } else if (name?.includes('_available')) {
        availableColumns.push(name);
      } else {
        otherColumns.push(name || '');
      }
    });

    // Sort alphabetically within their groups
    soldColumns.sort();
    availableColumns.sort();
    otherColumns.sort();

    return [
      'product_name',
      ...soldColumns,
      ...availableColumns,
      ...otherColumns
    ];
  }, [columnToShow]);

  const onHandleCopyProductName = (productName?: string) => {
    copyToClipboard(productName || '');
  };

  const viewSoldTimeline = (product_id?: string) => {
    setIsCalendarOpen(true);
    getSoldTimelineParams({ product_id });
  };

  useEffect(() => {
    const updateFastMovingData = fastMovingData?.map((item) => {
      // Filter only allowed keys ending in "_sold" and "_available" (excluding total_sold and total_available)
      const soldKeys = filteredShowColumns?.filter(
        (key) => key.endsWith('_sold') && key in item && key !== 'total_sold'
      );
      const availableKeys = filteredShowColumns?.filter(
        (key) =>
          key.endsWith('_available') && key in item && key !== 'total_available'
      );

      // Sum values dynamically
      const totalSold = soldKeys.reduce(
        (sum, key) => sum + (item[key] || 0),
        0
      );
      const totalAvailable = availableKeys.reduce(
        (sum, key) => sum + (item[key] || 0),
        0
      );

      return {
        ...item,
        total_sold: totalSold, // Corrected total_sold calculation
        total_available: totalAvailable // Corrected total_available calculation
      };
    });

    setcustomFastMovingData(updateFastMovingData);
  }, [columnToShow, fastMovingData, filteredShowColumns]);

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {filteredShowColumns?.map((header, index) => (
                <TableCell
                  key={header}
                  className={`${classes.tableHeaderCell} ${
                    !lg && index === 0 ? classes.stickyHeaderColumn : ''
                  }`}
                >
                  {header?.replace(/_/g, ' ').toUpperCase()}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {customFastMovingData?.map((data: any, rowIndex: number) => (
              <TableRow key={rowIndex} className={classes.tableRow}>
                {filteredShowColumns?.map((header: any, colIndex) => (
                  <TableCell
                    className={`${classes.tableCell} ${
                      !lg && colIndex === 0 ? classes.stickyColumn : ''
                    }`}
                    key={colIndex}
                  >
                    {header === 'product_name' ? (
                      <Box
                        display={'flex'}
                        style={{
                          textAlign: 'left',
                          columnGap: '.5em',
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'row',
                          minWidth: 500,
                          maxWidth: 500
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            wordBreak: 'break-word',
                            overflowWrap: 'break-word',
                            whiteSpace: 'normal'
                          }}
                        >
                          <FileCopyIcon
                            style={{ cursor: 'pointer', marginRight: '5px' }}
                            onClick={() =>
                              onHandleCopyProductName(data[header])
                            }
                          />
                          <Box>
                            <LinkComponent
                              openInNewTab={true}
                              href={`/app/products/${data?.product_id}`}
                              title={`${data[header] ?? '--'}`}
                            />
                          </Box>
                        </div>
                        <Tooltip title={'View sold items timeline'}>
                          <CalendarTodayIcon
                            style={{ cursor: 'pointer', marginRight: '5px' }}
                            onClick={() => viewSoldTimeline(data?.product_id)}
                          />
                        </Tooltip>
                      </Box>
                    ) : (
                      <>
                        {priceColumn.includes(header)
                          ? formatCurrency(data[header] ?? 0)
                          : header === 'margin_percentage'
                          ? `${data[header] ?? 0}%`
                          : data[header] ?? 0}
                      </>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <BigCalendar
        isOpen={isCalendarOpen}
        onHandleClose={() => setIsCalendarOpen(false)}
      />
    </>
  );
};

export const FastMovingProductTableV2 = memo(Component);
