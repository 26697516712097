import React, { FC, Fragment, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Typography,
  LinearProgress
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AuditBase } from 'src/types';
import { AuditRow } from './AuditRow';

interface Props {
  summary?: string;
  auditData: AuditBase[];
  isLoading?: boolean;
  expandFirstCard?: boolean;
  moduleName: string;
  formatValue?: (key: string, value: any) => string | JSX.Element;
}

export const AuditHistory: FC<Props> = ({
  auditData = [],
  isLoading,
  summary,
  expandFirstCard = false,
  moduleName,
  formatValue
}) => {
  const [isExpand, setIsExpand] = useState<boolean>(false);

  return (
    <Fragment>
      <Paper style={{ marginTop: '2rem' }} elevation={0}>
        <Accordion
          onChange={() => setIsExpand(!isExpand)}
          style={{ marginBottom: '1.5rem', padding: '0.5rem' }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box sx={{ padding: 0 }}>
              <Typography variant="h5">{summary}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails style={{ padding: '1rem' }}>
            <Paper style={{ width: '100%', padding: 0 }} elevation={0}>
              {isLoading ? <LinearProgress /> : null}
              {!isLoading && auditData[0] ? (
                auditData.map((item, index) => (
                  <AuditRow
                    key={index}
                    item={item}
                    index={index}
                    formatValue={formatValue}
                    expandFirstCard={expandFirstCard}
                  />
                ))
              ) : (
                <Typography>
                  No Update/Edit History for this {moduleName}
                </Typography>
              )}
            </Paper>
          </AccordionDetails>
        </Accordion>
      </Paper>
    </Fragment>
  );
};
