import {
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core';
import {
  Page as PDFPage,
  View,
  Document,
  StyleSheet,
  PDFViewer
} from '@react-pdf/renderer';
import React, { FC } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { RenderCompanyInfo } from './RenderCompanyInfo';
import { RenderCustomerInfo } from './RenderCustomerInfo';
import { RenderEmployeeInfo } from './RenderEmployeeInfo';
import { RenderFooter } from './RenderFooter';
import { RenderItemsTable } from './RenderItems';
import { RenderPageNoAndTotal } from './RenderPageNoAndTotal';
import { RenderPages } from './RenderPages';
import { RenderWarrantySlipTitle } from './RenderWarrantySlipTitle';
import {
  GetTransactionsViaTransactionNoResponse,
  TransactionPrintPageData
} from 'src/types';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white'
  },
  container: {
    margin: 15,
    flex: 1
  },
  text: {
    fontSize: 8,
    maxLines: 1
  },
  flex: {
    flex: 1
  }
});

interface Props {
  open: boolean;
  documentTitle: string;
  isBlankPage: boolean;
  transactionStateData: GetTransactionsViaTransactionNoResponse | null;
  pagesData: TransactionPrintPageData[];
  dateSold: string;
  totalAmount: number;
  releasedBy: string;
  toggleOpen: () => void;
}

export const PrintTransactionModal: FC<Props> = ({
  open,
  toggleOpen,
  documentTitle,
  isBlankPage,
  transactionStateData,
  pagesData,
  dateSold,
  totalAmount,
  releasedBy
}) => {
  return (
    <Dialog maxWidth={'md'} fullWidth open={open}>
      <DialogTitle>
        <Typography variant="h4">Transaction Details</Typography>
        <IconButton
          aria-label="close"
          style={{
            position: 'absolute',
            right: 5,
            top: 5
          }}
          color="secondary"
          onClick={toggleOpen}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Container maxWidth={false}>
          <PDFViewer
            width="100%"
            height="100%"
            style={{ height: '80vh', width: '100%' }}
          >
            <Document title={documentTitle}>
              {isBlankPage ? (
                <PDFPage size={[421, 612]} style={styles.page}>
                  <View style={styles.container}>
                    <RenderCompanyInfo
                      storeLocation={transactionStateData?.store_address || ''}
                    />
                    <RenderWarrantySlipTitle orderNo={''} transactionNo={''} />
                    <RenderCustomerInfo
                      dateSold={''}
                      customerInfo={undefined}
                      paymentMode={''}
                    />
                    <RenderItemsTable isBlankPage={true} items={[]} />
                    <RenderPageNoAndTotal isBlankPage={true} />
                    <RenderEmployeeInfo releasedBy={''} />
                    <RenderFooter customerInfo={undefined} />
                  </View>
                </PDFPage>
              ) : (
                <RenderPages
                  pagesData={pagesData}
                  transactionStateData={transactionStateData}
                  dateSold={dateSold}
                  totalAmount={totalAmount}
                  releasedBy={releasedBy}
                />
              )}
            </Document>
          </PDFViewer>
        </Container>
      </DialogContent>
    </Dialog>
  );
};
