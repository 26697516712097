import React, {
  FC,
  Fragment,
  memo,
  useCallback,
  useMemo,
  useState
} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles
} from '@material-ui/core';
import clsx from 'clsx';
import InfiniteScroll from 'react-infinite-scroll-component';
import { colors } from 'src/constants';
import { PriceListColumn, ProductPriceListData, Stock } from 'src/types';
import { formatCurrency, getColorGeneric } from 'src/utils';
import { Name } from './TableCell/Name';
import { TypographyPriceList } from './TypographyPriceList';
import { usePermissions } from 'src/hooks';
import { slices, useAppSelector } from 'src/redux';
import { cloneDeep } from 'lodash';
import { priceListColumns } from '../constants';
import { LoaderBar } from 'src/components';
import { ProductPriceModal } from './ProductPriceModal';
import { BranchStockList } from './BranchStockList';
import { StocksQuantityList } from './StocksQuantityList';
import { InstallmentList } from './InstallmentList';

const useStyles = makeStyles(() => ({
  table: {
    border: '2px solid black',
    borderColor: colors.primary,
    maxWidth: 2000
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: colors.grey[200]
    },
    '&:hover': {
      backgroundColor: colors.pink[50]
    }
  },
  tableHeader: {
    backgroundColor: colors.orange[200],
    padding: '0.5rem',
    margin: 0
  },
  tableCell: {
    padding: '0.5rem',
    textAlign: 'center',
    borderRight: '0.5px solid black',
    borderColor: colors.primary,
    cursor: 'pointer'
  },
  compactCell: {
    padding: '0.3rem'
  },
  multiCellParent: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    padding: 0
  },
  boxCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '1px solid black',
    padding: '0.5rem',
    '&:last-child': {
      borderBottom: 'none'
    }
  }
}));

interface MainPriceListTableProps {
  isLoading: boolean;
  loadMore: boolean;
  branchIds?: number[];
  priceList: ProductPriceListData[];
  onUpdatePriceList: (data: ProductPriceListData) => void;
  onLoadMoreItems: () => void;
}

const { selectors: appSelectors } = slices.product;

const component: FC<MainPriceListTableProps> = ({
  isLoading,
  loadMore,
  branchIds,
  priceList,
  onUpdatePriceList,
  onLoadMoreItems
}) => {
  const selectedPriceListColumns = useAppSelector(
    appSelectors.selectPriceListColumns
  );

  const classes = useStyles();
  const {
    canSeePriceListDealersPrice,
    canSeePriceListGrossPrice,
    canSeePriceListMarginPercentage,
    canUpdateRetailPrice,
    userPermissionsStringArray
  } = usePermissions();

  const [showProductModal, setShowProductModal] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<
    ProductPriceListData | undefined
  >();

  const internalColumnsWithPermissions = useMemo(() => {
    const clonedColumns = cloneDeep(priceListColumns || []);
    let columnsWillAppear: PriceListColumn[] = clonedColumns;

    columnsWillAppear = columnsWillAppear?.filter((x) => {
      if (selectedPriceListColumns[x.code]) {
        if (x?.permissions && x?.permissions?.length > 0) {
          return x?.permissions?.some((r) =>
            userPermissionsStringArray?.includes(r)
          );
        }

        return true;
      }
      return false;
    });
    return columnsWillAppear;
  }, [userPermissionsStringArray, selectedPriceListColumns]);

  const onProductClicked = (row: ProductPriceListData) => {
    if (!canUpdateRetailPrice) return;
    setSelectedProduct(row);
    setShowProductModal(true);
  };

  const onCloseProductModal = (action: string, data?: ProductPriceListData) => {
    setShowProductModal(false);
    if (action === 'update' && data) {
      onUpdatePriceList(data);
    }
  };

  const addUniqueBranch = useCallback(
    (product: Stock, branchNames: string[]) => {
      const { branch_name, stocks } = product;

      return !branch_name || !stocks || branchNames.includes(branch_name)
        ? branchNames
        : [...branchNames, branch_name];
    },
    []
  );

  const pluckBranchesWithStock = useCallback(
    (product: ProductPriceListData) => {
      let uniqueBranches: string[] = [];

      [...(product.stocks ?? []), ...(product.transfer_stocks ?? [])].forEach(
        (stock) => {
          if (stock.branch_name) {
            uniqueBranches = addUniqueBranch(stock, uniqueBranches);
          }
        }
      );

      return uniqueBranches;
    },
    [addUniqueBranch]
  );

  return (
    <Fragment>
      <InfiniteScroll
        dataLength={priceList.length}
        next={onLoadMoreItems}
        hasMore={loadMore}
        loader={
          <p style={{ textAlign: 'center', marginTop: 12 }}>
            <b>Loading More...</b>
          </p>
        }
        endMessage={
          <p style={{ textAlign: 'center', marginTop: 12 }}>
            <b>
              {isLoading
                ? ''
                : priceList.length
                ? 'Yay! You have seen it all'
                : 'No Result Found!'}
            </b>
          </p>
        }
      >
        <LoaderBar isLoading={isLoading} />
        <TableContainer className={classes.table}>
          <Table>
            <TableHead>
              <TableRow>
                {internalColumnsWithPermissions?.map((columnItem, index) => (
                  <TableCell
                    key={index}
                    className={clsx(classes.tableCell, classes.tableHeader)}
                  >
                    <TypographyPriceList text={columnItem.label} />
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {priceList.map((rowItem, index) => (
                <TableRow
                  key={index}
                  className={classes.tableRow}
                  onClick={() => onProductClicked(rowItem)}
                >
                  <Name index={index} data={rowItem} branch_ids={branchIds} />

                  <TableCell
                    className={clsx(classes.tableCell, classes.multiCellParent)}
                  >
                    {/* TODO: new component? accepts all stock and transferstock row shit i have to now show a row without a stock */}
                    <BranchStockList
                      branches={pluckBranchesWithStock(rowItem)}
                      className={classes.boxCell}
                    />
                  </TableCell>
                  <TableCell
                    className={clsx(classes.tableCell, classes.multiCellParent)}
                  >
                    <StocksQuantityList
                      branches={pluckBranchesWithStock(rowItem)}
                      className={classes.boxCell}
                      stocks={rowItem?.stocks || []}
                    />
                  </TableCell>
                  <TableCell
                    className={clsx(classes.tableCell, classes.multiCellParent)}
                  >
                    <StocksQuantityList
                      branches={pluckBranchesWithStock(rowItem)}
                      className={classes.boxCell}
                      stocks={rowItem?.transfer_stocks || []}
                    />
                  </TableCell>

                  <TableCell className={classes.tableCell}>
                    <TypographyPriceList
                      color={colors.grey[600]}
                      text={formatCurrency(
                        rowItem?.standard_price || 0,
                        'PHP '
                      )}
                      extraTextStyle={{ textDecoration: 'line-through' }}
                    />
                  </TableCell>

                  <TableCell
                    className={clsx(classes.tableCell, classes.multiCellParent)}
                  >
                    <InstallmentList
                      installmentData={{
                        three_months: rowItem?.installment?.three_months || '0',
                        six_months: rowItem?.installment?.six_months || '0',
                        twelve_months:
                          rowItem?.installment?.twelve_months || '0'
                      }}
                      className={classes.boxCell}
                    />
                  </TableCell>

                  <TableCell className={classes.tableCell}>
                    <TypographyPriceList
                      text={formatCurrency(rowItem?.retail_price || 0, 'PHP ')}
                    />
                  </TableCell>
                  {canSeePriceListDealersPrice && (
                    <TableCell className={classes.tableCell}>
                      <TypographyPriceList
                        color={colors.warning}
                        text={formatCurrency(
                          rowItem?.dealers_price || 0,
                          'PHP '
                        )}
                      />
                    </TableCell>
                  )}
                  {canSeePriceListGrossPrice && (
                    <TableCell className={classes.tableCell}>
                      <TypographyPriceList
                        color={getColorGeneric(rowItem?.margin_status)}
                        text={formatCurrency(rowItem?.gross_price || 0, 'PHP ')}
                      />
                    </TableCell>
                  )}
                  {canSeePriceListMarginPercentage && (
                    <TableCell className={classes.tableCell}>
                      <TypographyPriceList
                        color={getColorGeneric(rowItem.margin_status)}
                        text={rowItem?.margin_percentage}
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </InfiniteScroll>
      <ProductPriceModal
        visible={showProductModal}
        product={selectedProduct || {}}
        onHandleClose={(action, data) => onCloseProductModal(action, data)}
      />
    </Fragment>
  );
};

export const MainPriceListTable = memo(component);
