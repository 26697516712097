import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { PriceList, SelectedPriceListColumns } from 'src/types';
import { Product } from 'src/types/product';
import * as thunks from './thunks';

type ProductState = {
  products: Product[];
  priceList: PriceList[];
  priceListDate: string;
  productTableColumns: SelectedPriceListColumns;
};

const initialState: ProductState = {
  products: [],
  priceList: [],
  priceListDate: '',
  productTableColumns: {
    name: true,
    branch: true,
    quantity: true,
    srp: true,
    installment: true,
    promo_price: true,
    dealer_price: true,
    gross: true,
    margin: true,
    out_of_stock_at: true
  }
};

const slice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    updateProductViaId: (state, { payload }: PayloadAction<Product>) => {
      if (payload.id) {
        const index = state.products.findIndex((x) => x.id);
        if (index > -1) {
          state.products[index] = payload;
        }
      }
    },
    updatePriceListPriceViaIndex: (
      state,
      { payload }: PayloadAction<{ promo_price: number; index: number }>
    ) => {
      state.priceList[payload.index].promo_price = payload.promo_price;
    },

    removeProduct: (state, { payload }: PayloadAction<number>) => {
      const clonedProducts = cloneDeep(state.products);
      const index = state?.products?.findIndex((x) => x?.id === payload);
      if (index > -1) {
        clonedProducts.splice(index, 1);
      }
      state.products = clonedProducts;
    },

    onSetProductTableColumns: (state, { payload }) => {
      state.productTableColumns = payload;
    }
  },

  extraReducers(builder) {
    builder.addCase(thunks.getProductsThunk.fulfilled, (state, { payload }) => {
      state.products = payload?.originalData?.data;
    });
  }
});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions,
  ...thunks
};
