import { Box } from '@material-ui/core';
import React, { FC } from 'react';
import { TypographyPriceList } from './TypographyPriceList';

interface Props {
  branches: string[];
  className?: string;
}

export const BranchStockList: FC<Props> = ({ branches, className }) => {
  return branches[0] ? (
    <>
      {branches.map((branch, index) => (
        <Box key={index} className={className}>
          <TypographyPriceList text={`${branch}`} />
        </Box>
      ))}
    </>
  ) : null;
};
