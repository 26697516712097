import React from 'react';
import {
  Box,
  Button,
  Card,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  CustomInputEvent,
  GetTransactionPaymentsResponse,
  TransactionPayment
} from 'src/types';
import { formatCurrency } from 'src/utils';
import { colors } from 'src/constants';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import { LoaderBar } from 'src/components';
import { usePermissions } from 'src/hooks';
import useResolution from 'src/hooks/useResolution';
import { PaymentRow } from './PaymentRow';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  extraInfo: {
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  addBtn: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

interface Props {
  isLoading?: boolean;
  onAddPaymentPress: () => void;
  data?: GetTransactionPaymentsResponse;
  onDeletePayment: (item: TransactionPayment) => void;
  onSaveARField: (e: any, item: TransactionPayment) => void;
  onChangeARFieldText: (e: CustomInputEvent, index: number) => void;
}

const PaymentsCard = ({
  isLoading,
  data,
  onAddPaymentPress,
  onDeletePayment,
  onSaveARField,
  onChangeARFieldText
}: Props) => {
  const classes = useStyles();
  const {
    canDeleteTransactionPayment,
    canAddTransactionPayment
  } = usePermissions();
  const { isMobile } = useResolution();

  return (
    <div>
      <Typography color="textPrimary" variant="h5">
        Payments
      </Typography>
      <LoaderBar isLoading={isLoading || false} />
      <Card className={classes.cardContainer}>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            {data?.data && data?.data?.length > 0 ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date Posted</TableCell>
                    <TableCell>Acknowledgement Receipt No.</TableCell>
                    <TableCell>Type / MOP</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Created By</TableCell>
                    {canDeleteTransactionPayment && (
                      <TableCell>Delete</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.data?.map(
                    (item: TransactionPayment, index: number) => (
                      <PaymentRow
                        key={index}
                        payment={item}
                        index={index}
                        isDeleted={!!item.deleted_by}
                        canDeleteTransactionPayment={
                          canDeleteTransactionPayment
                        }
                        onSaveARField={onSaveARField}
                        onDeletePayment={onDeletePayment}
                        onChangeARFieldText={onChangeARFieldText}
                      />
                    )
                  )}
                </TableBody>
              </Table>
            ) : null}

            {data ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: isMobile ? 'start' : 'end',
                  paddingLeft: isMobile ? '1em' : 'initial'
                }}
              >
                <div
                  className={classes.extraInfo}
                  style={{ alignItems: isMobile ? 'start' : 'end' }}
                >
                  {canAddTransactionPayment && (
                    <Button
                      disabled={isLoading || !canAddTransactionPayment}
                      startIcon={<LocalAtmIcon />}
                      onClick={onAddPaymentPress}
                      color="primary"
                      variant="outlined"
                      className={classes.addBtn}
                    >
                      Add payment
                    </Button>
                  )}

                  <Typography
                    variant="h5"
                    style={{ color: colors.purple[500] }}
                  >
                    TOTAL PAID AMOUNT :{' '}
                    {`${formatCurrency(data?.total_paid_amount) ?? `0.00`}`}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{ color: colors.blue[500] }}
                  >
                    total transaction amount:{' '}
                    {`${formatCurrency(data?.total_transaction_amount) ??
                      `0.00`}`}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{ color: colors.red[500] }}
                  >
                    total balance:{' '}
                    {`${formatCurrency(data?.total_balance) ?? `0.00`}`}
                  </Typography>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: isMobile ? 'start' : 'end'
                }}
              >
                <div className={classes.extraInfo}>
                  <Button
                    fullWidth
                    disabled={isLoading}
                    startIcon={<LocalAtmIcon />}
                    onClick={onAddPaymentPress}
                    color="primary"
                    variant="outlined"
                  >
                    Add payment
                  </Button>
                </div>
              </div>
            )}
          </Box>
        </PerfectScrollbar>
      </Card>
    </div>
  );
};

export const TransactionPaymentsCard = React.memo(PaymentsCard);
