import React, { useMemo } from 'react';
import { makeStyles, Typography } from '@material-ui/core';

import { QuotationProduct } from 'src/types';
import { formatCurrency, promoPriceToSRP } from 'src/utils';
import { colors } from 'src/constants';

interface Props {
  product: QuotationProduct;
  showSRP: boolean;
}

const width = 700;

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: width,
      display: 'flex',
      flexDirection: 'row',
      borderStyle: 'solid',
      borderWidth: 0.5,
      borderRadius: 10,
      borderColor: colors.grey[500],
      padding: theme.spacing(2)
    },
    autoComplete: {
      maxWidth: width,
      borderBottomWidth: 1,
      borderBottomColor: 'pink'
    },
    optionListPrice: {
      fontWeight: 500,
      fontSize: 14
    },
    productImg: {
      height: 'auto',
      width: 50
    },
    productTitle: {
      fontWeight: 700,
      letterSpacing: 1,
      fontSize: 16
    },
    productProperties: {
      marginLeft: theme.spacing(2)
    },
    propertiesContainer: {
      marginTop: theme.spacing(2)
    },
    productPropertyName: {
      fontWeight: 500,
      fontSize: 12,
      lineHeight: 0.5,
      color: colors.grey[500]
    },
    productPropertyValue: {
      fontWeight: 500,
      fontSize: 15
    }
  }),
  { index: 999 }
);

const ProductOption = ({ product, showSRP }: Props) => {
  const classes = useStyles();

  const srp = useMemo(() => formatCurrency(product?.srp_applied), [product]);

  const promoPrice = useMemo(
    () => formatCurrency(promoPriceToSRP(product?.retail_price, false)),
    [product.retail_price]
  );

  // const arrayOfFakeProps = [
  //   {
  //     name: 'socket',
  //     value: 'AM4'
  //   },
  //   {
  //     name: 'no_of_cores',
  //     value: '4'
  //   },
  //   {
  //     name: 'core_clock',
  //     value: '3.5GHz'
  //   },
  //   {
  //     name: 'integrated_graphics',
  //     value: 'Radeon R7'
  //   },
  //   {
  //     name: 'integrated_graphics',
  //     value: 'Radeon R7'
  //   },
  //   {
  //     name: 'integrated_graphics',
  //     value: 'Radeon R7'
  //   }
  // ];

  return (
    <div className={classes.root}>
      <div>
        <img
          className={classes.productImg}
          src={
            product?.img_thumbnail ||
            '/static/images/products/product_not_found.png'
          }
        />
      </div>
      <div className={classes.productProperties}>
        <div>
          <Typography className={classes.productTitle}>
            {product.product_name}
          </Typography>
          <Typography className={classes.optionListPrice}>
            {` PP:${promoPrice} `}
            {showSRP ? `v SRP:${srp}` : null}
          </Typography>
        </div>
        {/* <Grid container spacing={2} className={classes.propertiesContainer}>
          {arrayOfFakeProps?.map((x, i) => (
            <Grid key={`${x?.name}${i}`} item>
              <Typography className={classes.productPropertyValue}>
                {x?.value || '--'}
              </Typography>
              <Typography className={classes.productPropertyName}>
                {x?.name || '--'}
              </Typography>
            </Grid>
          ))}
        </Grid> */}
      </div>
    </div>
  );
};

export const QuotationProductOption = React.memo(ProductOption);
