import React from 'react';
import { Box } from '@material-ui/core';
import { TypographyPriceList } from './TypographyPriceList';
import { getInstallmentOptions } from 'src/utils/pricelist/index';

interface InstallmentBreakdownListProps {
  installmentData: {
    three_months: string;
    six_months: string;
    twelve_months: string;
  };
  className?: string;
}

export const InstallmentList: React.FC<InstallmentBreakdownListProps> = ({
  installmentData,
  className
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      style={{
        gap: 1
      }}
    >
      {getInstallmentOptions(installmentData).map(
        (installment: any, index: any) => (
          <Box
            key={index}
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            minHeight={40}
            className={className}
          >
            <TypographyPriceList
              color={installment.color}
              text={`₱ ${installment.value} ${
                installment.label
              }`}
            />
          </Box>
        )
      )}
    </Box>
  );
};
