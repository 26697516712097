import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  makeStyles,
  Paper,
  TableCell,
  TextField,
  Tooltip
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import { CustomInputEvent, Quotation, QuotationProduct } from 'src/types';
import { Autocomplete } from '@material-ui/lab';
import { colors } from 'src/constants';
import { QuotationProductOption } from './QuotationProductOption';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

interface QuotationProductCellProps {
  item: Quotation;
  branchId?: number;
  index: number;
  showSRP: boolean;
  customProductInput: string;
  productOptions: QuotationProduct[];
  isCategoryLoading?: boolean;
  onChangeProduct: (val: QuotationProduct | null, index: number) => void;
  onCustomProductInputChange: (e: CustomInputEvent, index: number) => void;
}

const width = 700;

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: width,
      padding: theme.spacing(1)
    },
    autoComplete: {
      maxWidth: width,
      borderBottomWidth: 1,
      borderBottomColor: 'pink'
    },
    input: {
      color: colors.blue[500],
      fontWeight: 500
    },
    optionListPrice: {
      fontWeight: 600
    }
  }),
  { index: 999 }
);

const ProductCell = ({
  index,
  item,
  showSRP,
  productOptions,
  isCategoryLoading,
  customProductInput,
  onCustomProductInputChange,
  onChangeProduct
}: QuotationProductCellProps) => {
  const inputRef = useRef([]);
  const classes = useStyles();

  const [sortedProductOptions, setSortedProductOptions] = useState<
    QuotationProduct[]
  >(productOptions);

  const [sortActiveAlphabet, setSortActiveAlphabet] = useState<boolean>(true);
  const [sortActiveRetailPrice, setSortActiveRetailPrice] = useState<boolean>(
    false
  );

  const onHandleSortAlphabetically = useCallback(
    (activeState: boolean) => {
      if (sortedProductOptions) {
        const sortedProducts = [...sortedProductOptions].sort(
          (a: QuotationProduct, b: QuotationProduct) => {
            const nameA = a?.product_name || '';
            const nameB = b?.product_name || '';
            return activeState
              ? nameA.localeCompare(nameB) // If activeState is false, sort alphabetically A to Z
              : nameB.localeCompare(nameA); // If activeState is true, sort alphabetically Z to A
          }
        );
        setSortedProductOptions(sortedProducts);
        setSortActiveAlphabet(activeState);
        setSortActiveRetailPrice(false);
      }
    },
    [sortedProductOptions]
  );

  const onHandleSortByRetailPrice = useCallback(
    (activeState) => {
      if (sortedProductOptions) {
        const sortedProducts = [...sortedProductOptions].sort((a, b) => {
          const priceA = a?.retail_price || 0;
          const priceB = b?.retail_price || 0;

          // Sort descendding or ascending based on sortDirection
          return !activeState ? priceB - priceA : priceA - priceB;
        });
        setSortedProductOptions(sortedProducts);
        setSortActiveRetailPrice(activeState);
        setSortActiveAlphabet(false);
      }
    },
    [sortedProductOptions]
  );

  const productCellOptionLabel = useCallback(
    (option: QuotationProduct) => {
      return <QuotationProductOption product={option} showSRP={showSRP} />;
    },
    [showSRP]
  );

  const onBlur = (e: CustomInputEvent) => {
    const { value } = e.target;
    const customInputMatch = productOptions?.filter(
      (x) => x.product_name?.toUpperCase() === value?.toUpperCase()
    );
    if (customInputMatch?.length > 0) {
      onChangeProduct(customInputMatch[0], index);
    }
  };

  //   const onClickLoadOptions = async () => {
  //     setIsLoading(true);
  //     if (item?.product?.category_id) {
  //       const response = unwrapResult(
  //         await dispatch(
  //           quotationActions.getQuotationsProductsThunk({
  //             category_ids: [item?.product?.category_id],
  //             branch_id: branchId,
  //             limit: 200,
  //             page: 1
  //           })
  //         )
  //       );

  //       if (response?.success) {
  //         setIsLoading(false);
  //         const meta = response?.originalData?.meta;

  //         if (meta && meta.last_page !== undefined && meta.last_page >= 2) {
  //           setIsLastPage(false);
  //           setProductOptionsState((prevState) => [
  //             ...prevState,
  //             ...(response?.originalData?.data || [])
  //           ]);
  //         } else {
  //           setIsLastPage(true);
  //         }
  //       }
  //     }
  //   };

  useEffect(() => {
    let categProducts = productOptions;

    if (item?.product?.category_id) {
      categProducts = productOptions?.filter(
        (x) => x.category_id === item?.product?.category_id
      );
    }

    setSortActiveAlphabet(true);
    setSortActiveRetailPrice(false);
    setSortedProductOptions(categProducts);
  }, [item.product.category_id, productOptions]);

  return (
    <TableCell
      hidden={productOptions?.length <= 0}
      onClick={(e) => e.stopPropagation()}
      className={classes.root}
    >
      {item?.custom ? (
        <TextField
          fullWidth
          onBlur={onBlur}
          value={customProductInput}
          onChange={(e) => onCustomProductInputChange(e, index)}
          label=""
          margin="none"
          variant="standard"
          InputProps={{
            className: classes.input,
            endAdornment: (
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onChangeProduct(null, index);
                }}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            )
          }}
        />
      ) : (
        <Autocomplete
          fullWidth
          size="small"
          id="product"
          ref={inputRef.current[index]}
          value={item?.product}
          // dont disable the input whenever loading
          // disabled={isCategoryLoading}
          className={classes.autoComplete}
          options={sortedProductOptions}
          getOptionSelected={(option, value) =>
            option?.product_id === value?.product_id
          }
          onChange={(_, newValue) => onChangeProduct(newValue, index)}
          getOptionLabel={(option) => option.product_name ?? ''}
          renderOption={productCellOptionLabel}
          onInputChange={() => {}}
          renderInput={(params) => (
            <TextField
              {...params}
              multiline
              label=""
              margin="none"
              variant="standard"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isCategoryLoading ? (
                      <CircularProgress color="primary" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
            />
          )}
          PaperComponent={({ children }) => {
            return (
              <Paper>
                <Box sx={{ padding: '1rem' }}>
                  <Tooltip
                    title={sortActiveAlphabet ? 'Sort by A-Z' : 'Sort by Z-A'}
                  >
                    <Button
                      color="primary"
                      variant={sortActiveAlphabet ? 'contained' : 'outlined'}
                      startIcon={<ImportExportIcon fontSize="small" />}
                      style={{
                        background: sortActiveAlphabet ? colors?.primary : '',
                        color: sortActiveAlphabet ? 'white' : colors?.primary,
                        padding: 5,
                        margin: 3
                      }}
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent Autocomplete from closing
                        onHandleSortAlphabetically(!sortActiveAlphabet);
                      }}
                      onMouseDown={(e) => {
                        e.preventDefault(); // Prevent dropdown from closing
                      }}
                    >
                      {sortActiveAlphabet ? `Sort By A -Z` : 'Sort By Z - A'}
                    </Button>
                  </Tooltip>

                  <Tooltip title={'Sort by Price'}>
                    <Button
                      color="primary"
                      variant={sortActiveRetailPrice ? 'contained' : 'outlined'}
                      startIcon={<AttachMoneyIcon fontSize="small" />}
                      style={{
                        background: sortActiveRetailPrice
                          ? colors?.primary
                          : '',
                        color: sortActiveRetailPrice
                          ? 'white'
                          : colors?.primary,
                        padding: 5,
                        margin: 3
                      }}
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent Autocomplete from closing
                        onHandleSortByRetailPrice(!sortActiveRetailPrice);
                      }}
                      onMouseDown={(e) => {
                        e.preventDefault(); // Prevent dropdown from closing
                      }}
                    >
                      {sortActiveRetailPrice
                        ? 'Sort By Price (Lowest to Highest)'
                        : 'Sort By Price (Highest to Lowest)'}
                    </Button>
                  </Tooltip>

                  {children}
                </Box>
              </Paper>
            );
          }}
        />
      )}
    </TableCell>
  );
};

export const QuotationProductCell = React.memo(ProductCell);
