/* eslint-disable no-unused-vars */

import { Box } from '@material-ui/core';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { ProductPriceListData, Stock } from 'src/types';
import { TypographyPriceList } from './TypographyPriceList';
import { getQtyColor } from '../utils';

interface Props {
  branches: string[];
  stocks: Stock[];
  className?: string;
}

export const StocksQuantityList: FC<Props> = ({
  stocks,
  className,
  branches
}) => {
  const branchWithStock = useMemo(() => {
    let result: Stock[] = [];
    // let result: { [key: string]: Stock } = {};
    stocks.forEach((stock) => {
      const { branch_name } = stock;

      if (branch_name && branches.includes(branch_name)) {
        result = [...result, stock];
      }
    });
    return result;
  }, [branches, stocks]);

  return branchWithStock[0] ? (
    <>
      {branchWithStock.map((branch, index) => (
        <Box key={index} className={className}>
          <TypographyPriceList
            key={index}
            color={getQtyColor(branch.stocks)}
            text={`${branch.stocks || 0}`}
          />
        </Box>
      ))}
    </>
  ) : null;
};
