import React, { useCallback } from 'react';
import { TableCell, TextField, Typography } from '@material-ui/core';
import { LinkComponent } from 'src/components';
import { useNavigate } from 'react-router';
import { colors } from 'src/constants';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { RMAColumn, RMAData } from 'src/redux/slices/rma/types';
import { initialRMATableColumns } from 'src/redux/slices/rma/constants/rma-table-columns';
import { RMAField } from 'src/redux/slices/rma/constants/rma-fields';
import {
  rmaOptionsStatuses
  // rmaLocationOptions
} from 'src/redux/slices/rma/constants/rma-form-fields';
import { AlternatingColorTableRow } from 'src/components/table/AlternatingColorTableRow';
import { Autocomplete } from '@material-ui/lab';
import { unwrapResult } from '@reduxjs/toolkit';
import { RMAImages } from './RMAImages';

interface Props {
  rmaData: RMAData;
}

// const { selectors: taptapCustomerSelectors } = slices.taptapCustomer;
const { actions: rmaActions, selectors: rmaSelector } = slices.rma;

const RMATableRow = ({ rmaData }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const rmaColumnsRedux = useAppSelector(rmaSelector.selectRMAColumns);

  const handleFieldChange = useCallback(
    async (fieldName: string, newValue?: string) => {
      const rmaID = rmaData?.rma_id;

      try {
        const updateRmaRes = unwrapResult(
          await dispatch(
            rmaActions.updateRmaThunk({
              [fieldName]: newValue,
              rma_id: Number(rmaID)
            })
          )
        );

        if (!updateRmaRes?.success) {
          console.error('Error updating data:', updateRmaRes?.message);
          return;
        }
      } catch (error) {
        console.error('Error in handleFieldChange:', error);
      }
    },
    [dispatch, rmaData.rma_id]
  );

  const customBgCellColor = useCallback(
    (column: RMAColumn) => {
      if (column.sticky) {
        return colors.grey[100];
      }
      if (column.field === RMAField.STATUS) {
        const statusBgColor = rmaOptionsStatuses?.find(
          (status) => status.value === rmaData?.status
        )?.bgColor;

        return statusBgColor || 'inherit';
      }
      return 'inherit';
    },
    [rmaData.status]
  );

  const RenderCustomRow = useCallback(
    (fieldName: string) => {
      if (fieldName === RMAField.SERIAL_NO) {
        return (
          <LinkComponent
            openInNewTab
            title={
              <Typography variant="inherit" style={{ color: colors.link }}>
                {rmaData?.serial_no || '-- Not Available'}
              </Typography>
            }
            href={`/app/rma/${rmaData?.rma_id}`}
            onClick={() => navigate(`/app/rma/${rmaData?.rma_id}`)}
          />
        );
      }
    },
    [rmaData, navigate]
  );

  const RenderAutoComplete = useCallback(
    (autoCompleteCol: RMAColumn) => {
      const autoCompleteValue = autoCompleteCol.options?.find(
        (x) => x.value === rmaData[autoCompleteCol.field]
      );

      return (
        <Autocomplete
          options={autoCompleteCol.options || []}
          getOptionLabel={(option) => option.label}
          value={autoCompleteValue}
          onChange={(event, newValue) => {
            handleFieldChange(autoCompleteCol.field, newValue?.value);
          }}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
      );
    },
    [handleFieldChange, rmaData]
  );

  // eslint-disable-next-line no-unused-vars
  const RenderRows = useCallback(
    () =>
      initialRMATableColumns?.map((column) => {
        if (!rmaColumnsRedux[column.name]) {
          return null;
        }

        return (
          <TableCell
            style={{
              position: column.sticky ? 'sticky' : 'static',
              left: column.sticky ? 0 : 'auto',
              backgroundColor: customBgCellColor(column),
              zIndex: column.sticky ? 1 : 'auto',
              borderRight: `1px solid ${colors.grey[300]}`,
              minHeight: 20,
              minWidth: column.minWidth ? column.minWidth : 200
            }}
            key={column.field}
          >
            {column?.type === 'custom' && RenderCustomRow(column.field)}
            {column?.type === 'text' && rmaData[column.field]}
            {column?.type === 'link' && (
              <LinkComponent
                openInNewTab
                href={`transaction/${rmaData[column.field]}`}
                title={rmaData[column.field]}
              />
            )}
            {column?.type === 'autocomplete' && RenderAutoComplete(column)}
            {column?.type === 'image' && (
              <RMAImages rmaData={rmaData} columnWithImgs={column} />
            )}
            {/* {column?.type === 'date' &&
              formatDate(customer[column.field], 'MMM d, yyyy h:mm a')} */}
            {/* {column?.type === 'currency' &&
              formatCurrency(customer[column.field])} */}
            {/* {column?.type === 'boolean' && (
              <div>
                {customer[column.field] === true ? (
                  <CheckIcon style={{ color: colors.success }} />
                ) : (
                  <ClearIcon style={{ color: colors.error }} />
                )}
              </div>
            )} */}
          </TableCell>
        );
      }, []),
    [
      RenderAutoComplete,
      RenderCustomRow,
      customBgCellColor,
      rmaColumnsRedux,
      rmaData
    ]
  );

  return (
    <React.Fragment key={rmaData?.id}>
      <AlternatingColorTableRow hover>{RenderRows()}</AlternatingColorTableRow>
    </React.Fragment>
  );
};

export default RMATableRow;
