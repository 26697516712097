/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Container,
  LinearProgress,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Page } from 'src/components';
import { useFastMovingProducts } from 'src/hooks/fast-moving-products';
import {
  FastMovingProductSubToolbar,
  FastMovingProductTableList,
  FastMovingProductToolBar
} from './components';
import useUserInfo from 'src/hooks/user/use-user-info';
import { Alert } from '@material-ui/lab';
import { StockFilter } from 'src/enums/fast-moving-products';
import {
  FastMovingProductsData,
  SortData
} from 'src/redux/slices/fast-moving-products';
import { FastMovingProductTableV2 } from './components/FastMovingProductTableV2';
import {
  fastMovingHeaderKeys,
  fastMovingSortedData
} from 'src/utils/fast-moving-products';
import { isArray, isEmpty } from 'lodash';
import { SelectedColumn } from 'src/types';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));

export const FastMovingStockView = () => {
  const classes = useStyles();
  const { userBranchOptions } = useUserInfo();
  const {
    isLoading,
    fastMovingProdParams,
    fastMovingProductData,
    dataCopy,
    setFastMovingProdParams,
    getFastMovingProducts
  } = useFastMovingProducts();

  const [searchKeyword, setSearchKeyword] = useState<string>();
  const [isCategoryRequire, setIsCategoryRequire] = useState<boolean>(false);
  const [isDateRangeRequire, setIsDateRangeRequire] = useState<boolean>(false);
  const [columnToHide, setColumnToHide] = useState<number[]>();
  const [sortFlag, setSortFlag] = useState<SortData>({});
  const [selectedShowColumn, setSelectedShowColumn] = useState<
    SelectedColumn[]
  >([]);

  const defaultValueBranch = useMemo(() => {
    let branchIds: number[] = [];

    branchIds = userBranchOptions.map((branch) => branch?.value);
    return branchIds;
  }, [userBranchOptions]);

  const headerKeys = useMemo(() => {
    return fastMovingHeaderKeys(fastMovingProductData);
  }, [fastMovingProductData]);

  const sortedData: any = useMemo(() => {
    return fastMovingSortedData(fastMovingProductData);
  }, [fastMovingProductData]);

  const onApplyFilterPress = useCallback(
    (columnsToShow?: SelectedColumn[]) => {
      const { status_filter, ...newFastMovingParams } = fastMovingProdParams;
      let newParams = newFastMovingParams;
      if (!fastMovingProdParams.category_id) {
        setIsCategoryRequire(true);
        return;
      }

      //We extract columns that contain branch ids
      const branchIds: any = columnsToShow
        ?.filter((column) => column?.value !== 0)
        .map((branch) => branch?.value)
        .filter((value) => value !== undefined);

      if (
        !fastMovingProdParams?.date_sold_from ||
        !fastMovingProdParams?.date_sold_to
      ) {
        setIsDateRangeRequire(true);
        return;
      }
      if (fastMovingProdParams?.status_filter === 'with_sales') {
        newParams = {
          ...fastMovingProdParams,
          with_sales: 1
        };
        setFastMovingProdParams(newParams);
      } else {
        newParams = {
          ...fastMovingProdParams,
          with_sales: 0
        };

        setFastMovingProdParams(newParams);
      }

      if (!isEmpty(branchIds) && isArray(branchIds)) {
        newParams = {
          ...newFastMovingParams,
          branch_ids: branchIds
        };
      }

      setSelectedShowColumn(columnsToShow || []);
      getFastMovingProducts(newParams);
      setSortFlag({});
    },
    [fastMovingProdParams, getFastMovingProducts, setFastMovingProdParams]
  );

  const handleErrorClose = () => {
    setIsCategoryRequire(false);
    setIsDateRangeRequire(false);
  };

  //has default branch for multiple selection
  useEffect(() => {
    setFastMovingProdParams((prev) => ({
      ...prev,
      keyword: searchKeyword || undefined,
      branch_ids: prev.branch_ids || defaultValueBranch
    }));
  }, [defaultValueBranch, searchKeyword, setFastMovingProdParams]);

  return (
    <Page title="Fast Moving Products" className={classes.root}>
      <Container maxWidth={false}>
        <Typography color="textPrimary" gutterBottom variant="h3">
          Fast Moving Products
        </Typography>

        <FastMovingProductToolBar
          loadingData={isLoading}
          initialBranchIds={defaultValueBranch}
          onApplyFilter={() => onApplyFilterPress()}
          categoryRequire={isCategoryRequire}
          dateRangeRequire={isDateRangeRequire}
          onHandleBranch={(branch_ids?: number[]) =>
            setFastMovingProdParams((prev) => ({ ...prev, branch_ids }))
          }
          onHandleSearchKeyword={(keyword?: string) =>
            setSearchKeyword(keyword)
          }
          onHandleCategoryId={(category_id?: number) =>
            setFastMovingProdParams((prev) => ({ ...prev, category_id }))
          }
          onHandleDateFrom={(date_sold_from?: string | undefined) =>
            setFastMovingProdParams((prev) => ({ ...prev, date_sold_from }))
          }
          onHandleDateTo={(date_sold_to?: string | undefined) =>
            setFastMovingProdParams((prev) => ({ ...prev, date_sold_to }))
          }
          onHandleErrorClose={handleErrorClose}
          onChangeStatusFilter={(val: StockFilter) =>
            setFastMovingProdParams((prev) => ({
              ...prev,
              status_filter: val
            }))
          }
        />

        {isLoading && <LinearProgress />}
        {!fastMovingProductData && (
          <Alert severity="info" style={{ marginTop: '1rem' }}>
            <Typography variant="subtitle2">
              Select category and date sold range to load fast moving products
            </Typography>
          </Alert>
        )}
        {/*TODO: remove later might be useful for improvement of v2 */}
        {/* {fastMovingProductData && fastMovingProductData?.length > 0 && (
          <>
       
            <FastMovingProductTableList
              columnToHide={columnToHide}
              // fastMovingProducts={fastMovingProductData || []}
              fastMovingProducts={tempData || []}
              productsCopy={dataCopy}
              sortFlag={sortFlag}
              setSortFlag={setSortFlag}
              setFastMovingProducts={(val: FastMovingProductsData[]) =>
                setFastMovingProductData(val)
              }
            />
          </>
        )} */}

        {sortedData && sortedData?.length > 0 && !isLoading && (
          <>
            <FastMovingProductSubToolbar
              headerKeys={headerKeys}
              columnIndicesToHide={columnToHide}
              fastMovingProductToCsv={sortedData || []}
              columnToHide={(indices: number[]) => setColumnToHide(indices)}
              onApplyFilterColumn={(columnToShow?: SelectedColumn[]) => {
                setSelectedShowColumn(columnToShow || []);
              }}
            />

            <FastMovingProductTableV2
              headers={headerKeys}
              fastMovingData={sortedData || []}
              columnToShow={selectedShowColumn || []}
            />
          </>
        )}
      </Container>
    </Page>
  );
};
