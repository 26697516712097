import { TapTapColumn } from '../types';
import { taptapCustomerField } from './taptap-fields';

export const initialTaptapTableColumns: TapTapColumn[] = [
  {
    field: taptapCustomerField.FIRST_NAME,
    type: 'custom',
    visible: true,
    sticky: true
  },
  {
    field: taptapCustomerField.LAST_NAME,
    type: 'text',
    visible: true
  },
  {
    field: taptapCustomerField.INTERNAL_TRANSACTION_NO,
    type: 'custom',
    visible: true
  },
  {
    field: taptapCustomerField.BRANCH_RELEASED,
    type: 'text',
    visible: true
  },
  {
    field: taptapCustomerField.STATUS,
    type: 'custom',
    visible: true
  },
  {
    field: taptapCustomerField.CREATED_AT,
    type: 'date',
    visible: true
  },
  {
    field: taptapCustomerField.CONTACT_NO,
    type: 'text',
    visible: false
  },
  {
    field: taptapCustomerField.ADDRESS,
    type: 'text',
    visible: false
  },
  {
    field: taptapCustomerField.IS_AGREE_TNC,
    type: 'boolean',
    visible: false
  },
  {
    field: taptapCustomerField.IS_AGREE_MARKETING,
    type: 'boolean',
    visible: false
  },

  {
    field: taptapCustomerField.SOURCE,
    type: 'text',
    visible: true
  },
  {
    field: taptapCustomerField.FB_TRANSACTION_TYPE,
    type: 'text',
    visible: true
  },
  {
    field: taptapCustomerField.VIBER_TRANSACTION_TYPE,
    type: 'text',
    visible: true
  },
  {
    field: taptapCustomerField.ECOMM_TRANSACTION_TYPE,
    type: 'text',
    visible: true
  },
  {
    field: taptapCustomerField.ONLINE_SALES_REPRESENTATIVE,
    type: 'text',
    visible: true
  },
  {
    field: taptapCustomerField.COURIER,
    type: 'text',
    visible: true
  },
  {
    field: taptapCustomerField.SERVICE_TYPE,
    type: 'text',
    visible: true
  },
  {
    field: taptapCustomerField.TOTAL_AMOUNT,
    type: 'currency',
    visible: true
  },
  {
    field: taptapCustomerField.PAID_AMOUNT,
    type: 'currency',
    visible: true
  },
  {
    field: taptapCustomerField.BALANCE,
    type: 'custom',
    visible: true
  },
  {
    field: taptapCustomerField.SHIPPING_FEE,
    type: 'currency',
    visible: true
  },
  {
    field: taptapCustomerField.IS_SHIPPING_FEE_PAID,
    type: 'boolean',
    visible: true
  },
  {
    field: taptapCustomerField.TRIAL_OS,
    type: 'text',
    visible: true
  },
  {
    field: taptapCustomerField.REMARKS,
    type: 'text',
    visible: true
  },
  {
    field: taptapCustomerField.BUILD_TAG,
    type: 'text',
    visible: true
  },
  {
    field: taptapCustomerField.KIND_OF_SALES_TRANSFER,
    type: 'text',
    visible: true
  },
  {
    field: taptapCustomerField.TYPE_OF_BUILD,
    type: 'text',
    visible: true
  },
  {
    field: taptapCustomerField.WARRANTY_SLIP_NO,
    type: 'text',
    visible: false
  },
  {
    field: taptapCustomerField.WITH_UPGRADE,
    type: 'boolean',
    visible: true
  }
];
