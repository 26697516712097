import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CalendarParamsState, FastMovingProductState } from './types';

const initialState: FastMovingProductState = {
  calendar_state: {}
};

const slice = createSlice({
  name: 'fast-moving-prooducts',
  initialState,
  reducers: {
    calendarParamsActions(state, action: PayloadAction<CalendarParamsState>) {
      state.calendar_state = { ...state.calendar_state, ...action?.payload };
    }
  }
});

export const reducer = slice.reducer;
export const actions = { ...slice?.actions };
