import React, { useEffect, useMemo, useState } from 'react';

import {
  GetTransactionsViaTransactionNoResponse,
  Transaction,
  TransactionPrintPageData
} from 'src/types';
import {
  formatDate,
  getNumberOrZero,
  initializedWordsOtherThanFirst,
  isEmptyOrSpaces,
  promoPriceToSRP
} from 'src/utils';
import { RenderDetailEditor } from './components';
import { RenderRMAEditor } from './components/RenderRMAEditor';
import { cloneDeep } from 'lodash';
import { ProductTypeEnum } from 'src/enums';
import { transactionDataCleaner } from './util';
import { PrintTransactionModal } from './components/PrintTransactionModal';

// Create Document Component
const MyDocument = () => {
  const [isSIPricing, setIsSIPricing] = useState<boolean>(false);
  const [isPrintOpen, setIsPrintOpen] = useState<boolean>(false);
  const [isBlankPage, setIsBlankPage] = useState<boolean>(false);
  const [
    transactionStateData,
    setTransactionStateData
  ] = useState<GetTransactionsViaTransactionNoResponse | null>(null);
  const [
    prevTransactionState,
    setPrevTransactionState
  ] = useState<GetTransactionsViaTransactionNoResponse | null>();

  const documentTitle = useMemo(() => {
    const firstName = transactionStateData?.customer?.first_name ?? '';
    const lastName = transactionStateData?.customer?.last_name ?? '';
    const transactionNo = transactionStateData?.transaction_no ?? '';
    return `${+new Date()}-${transactionNo}-${firstName}-${lastName}`;
  }, [transactionStateData]);

  const dateSold = useMemo(() => {
    if (
      transactionStateData?.items &&
      transactionStateData?.items?.length > 0
    ) {
      if (transactionStateData?.items[0]?.created_at) {
        return formatDate(transactionStateData?.items[0].created_at);
      }
      return '--';
    }
    return '--';
  }, [transactionStateData]);

  const onUpdateOfTransaction = (
    newData: GetTransactionsViaTransactionNoResponse
  ) => {
    setTransactionStateData(newData);
  };

  const onSalesInvoicePriceToggle = (v: boolean) => {
    setIsSIPricing(v);

    const clonedTransactionState = cloneDeep(transactionStateData);
    // let adjustedSIItems = clonedTransactionState?.items || [];

    if (v) {
      setPrevTransactionState(transactionStateData);
      clonedTransactionState?.items?.map((x: Transaction) => {
        x.amount = promoPriceToSRP(x.amount);
        x.retail_price = promoPriceToSRP(x.retail_price);
      });
      // setTransactionStateData(clonedTransactionState);
      setTransactionStateData((prev) => ({
        ...prev,
        items: prev?.items?.map((x) => {
          const foo = {
            ...x,
            /* Show retail price if amount is unavailable in table cell below for items with quantity by 
            meters eg. rj45 w/ quantity 3 meters because retail price is manually set by operations regardless of meters */
            amount: promoPriceToSRP(x.amount || x.retail_price),
            retail_price: promoPriceToSRP(x.retail_price)
          };
          return foo;
        })
      }));
    } else {
      if (prevTransactionState) {
        setTransactionStateData(prevTransactionState);
      }
    }
  };

  const onUpdateOfRmaItems = (newItems: Transaction[]) => {
    setTransactionStateData((prev) => ({ ...prev, items: newItems }));
  };

  const transactionItems = useMemo(() => {
    let clonedTransactionStateData = cloneDeep(transactionStateData);
    if (clonedTransactionStateData) {
      clonedTransactionStateData.items = clonedTransactionStateData.items?.filter(
        (x) => x?.rma?.is_hidden_print === false || !x?.rma
      );
    }
    const arr: Transaction[] = clonedTransactionStateData?.items || [];
    const reducedItems: Transaction[] = [];
    arr.forEach((item) => {
      const existingIndex = reducedItems?.findIndex(
        (x) =>
          x?.product_name == item?.product_name &&
          // also check price because some products are same but have different retails price
          x?.retail_price === item?.retail_price
      );
      if (existingIndex > -1) {
        reducedItems[existingIndex].quantity =
          (reducedItems[existingIndex].quantity || 0) + (item?.quantity || 0);
        reducedItems[existingIndex].amount =
          (reducedItems[existingIndex].amount || 0) + (item?.amount || 0);

        if (item?.serial_no) {
          reducedItems[existingIndex].serial_nos?.push(item?.serial_no);
        }
      } else {
        if (item.serial_no) {
          item.serial_nos = [item.serial_no];
        }
        reducedItems.push(item);
      }
    });
    return reducedItems;
  }, [transactionStateData]);

  const totalPriceOnPrint = useMemo(() => {
    const totalKachiPoints = Math.abs(transactionStateData?.kachi_points || 0);
    const total = transactionStateData?.items?.reduce((accumulator, item) => {
      let toBeAdded: number = 0;
      if (
        item?.rma?.is_hidden_print ||
        (item?.rma && item?.rma?.is_hidden_print === undefined)
      ) {
        toBeAdded = 0;
      } else {
        toBeAdded = getNumberOrZero(item?.amount);
      }
      return accumulator + toBeAdded;
    }, 0);
    const newTotal = (total || 0) - totalKachiPoints;

    return newTotal;
  }, [transactionStateData]);

  const pagesData = useMemo(() => {
    const limitOfLinePerPage = 22; // hard coded optimal number by manually counting lines before overflow occurs
    let totalLinesAccumulated = 0;

    let pageData: TransactionPrintPageData[] = [
      {
        pageNo: 1,
        page: {
          ...transactionStateData,
          items: []
        }
      }
    ];

    const clonedTransactionItems = cloneDeep(transactionItems);

    clonedTransactionItems.forEach((i, ind) => {
      const name = i.product_name || '';
      const characterPerLine = 43; // no. of characters the productname cell can fit before wrapping to next line
      const filteredSerial =
        i.serial_nos?.filter((serial) => {
          // check serial conditions
          const isValidSerial =
            i?.product_type !== ProductTypeEnum.Consumable &&
            i?.serial_nos &&
            i?.serial_nos?.length > 0;
          // do not allow non serialized items
          const IsSerializeditem =
            !/^SN-\d{13}$/?.test(serial) && !serial?.includes('PCWSN-');

          const serialConditions = [isValidSerial, IsSerializeditem];

          return serialConditions.every((condition) => !!condition);
        }) || [];

      const linesTakenBySerial = filteredSerial.length || 0;
      const pageNoBasedOnLength = pageData?.length;
      const lastIndexOfPageData = pageNoBasedOnLength - 1;
      const isLastPage = ind === clonedTransactionItems.length - 1;
      const nameLength = name.length || 0;
      const linesTakenByName = Math.ceil(nameLength / characterPerLine);
      const linesTakeByWarranty = 1;
      let linesTakenByItem =
        linesTakenByName + linesTakeByWarranty + linesTakenBySerial;

      if (transactionStateData?.kachi_points !== 0 && isLastPage) {
        linesTakenByItem++; // add kachi points to line consideration if its the last item
      }

      const updatedItem = { ...i, serial_nos: filteredSerial };

      if (totalLinesAccumulated + linesTakenByItem <= limitOfLinePerPage) {
        // @ts-ignore: Object is possibly 'null'. Intended.
        pageData[lastIndexOfPageData]?.page.items.push(updatedItem);
        totalLinesAccumulated += linesTakenByItem;
      } else {
        totalLinesAccumulated = 0; // reset total lines val and re add new one
        totalLinesAccumulated += linesTakenByItem;
        pageData.push({
          pageNo: pageNoBasedOnLength + 1,
          page: {
            ...transactionStateData,
            items: [updatedItem]
          }
        });
      }
    });

    const finalData = transactionDataCleaner(pageData);
    return finalData;
  }, [transactionItems, transactionStateData]);

  const releasedBy = useMemo(() => {
    const elementWithName = transactionStateData?.items?.find(
      (x) => typeof x.released_by_name !== 'undefined'
    );
    // const arrName = elementWithName?.released_by_name;
    let firstAndInitialLastName = initializedWordsOtherThanFirst(
      elementWithName?.released_by_name || ''
    );
    if (isEmptyOrSpaces(firstAndInitialLastName)) {
      firstAndInitialLastName = 'PC Worth';
    }
    return `${elementWithName?.created_by || 0}-${firstAndInitialLastName}`;
  }, [transactionStateData]);

  const hasRMA = useMemo(
    () => transactionStateData?.items?.filter((x) => x.rma)?.length,
    [transactionStateData]
  );

  useEffect(() => {
    // Get the state from sessionStorage
    if (!transactionStateData) {
      const data = JSON.parse(sessionStorage.getItem('transactionData') || '');
      setTransactionStateData(data);
    }
  }, [transactionStateData]);

  return (
    <div>
      <RenderDetailEditor
        isSIPricing={isSIPricing}
        transactionDetails={transactionStateData}
        onUpdateOfTransaction={onUpdateOfTransaction}
        onSalesInvoicePriceToggle={onSalesInvoicePriceToggle}
        isBlankPage={isBlankPage}
        toggleBlankPage={() => setIsBlankPage(!isBlankPage)}
        togglePrintOpen={() => setIsPrintOpen(!isPrintOpen)}
      />
      {hasRMA !== 0 ? (
        <RenderRMAEditor
          onUpdateOfRmaItems={onUpdateOfRmaItems}
          items={transactionStateData?.items || []}
        />
      ) : null}
      {isPrintOpen ? (
        <PrintTransactionModal
          isBlankPage={isBlankPage}
          releasedBy={releasedBy}
          totalAmount={totalPriceOnPrint}
          transactionStateData={transactionStateData}
          documentTitle={documentTitle}
          open={isPrintOpen}
          toggleOpen={() => setIsPrintOpen(!isPrintOpen)}
          pagesData={pagesData}
          dateSold={dateSold}
        />
      ) : null}
    </div>
  );
};

export default MyDocument;
