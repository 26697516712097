import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { taptapAddCustomerFields } from 'src/redux/slices/taptap-customer';
import { cloneDeep, isEmpty } from 'lodash';
import RMAForm from './components/RMAForm';
import { LoaderBar, LoadingButton, Page } from 'src/components';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useSnackBar } from 'src/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { slices, useAppDispatch } from 'src/redux';
import {
  GetTransactionsViaTransactionNoResponse,
  Listing,
  Transaction
} from 'src/types';
import { cleanSN } from 'src/utils';
import {
  addRMAFields,
  rmaDefaultValues,
  rmaLocationOptions,
  rmaStatuses
} from 'src/redux/slices/rma/constants/rma-form-fields';
import { RMAField } from 'src/redux/slices/rma/constants/rma-fields';
import { CreateRmaRequest } from 'src/redux/slices/rma/types';

import { RMATransactionNoInput } from './components/RMATransactionNoInput';
import { RMATransactionItem } from './components/RMATransactionItem';
import { RMANonSNSelector } from './components/RMANonSNSelector';
import useUserInfo from 'src/hooks/user/use-user-info';
import useResolution from 'src/hooks/useResolution';

interface FormValues {
  [key: string]: any; // could string, boolean, number, etc
}

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100%',
    paddingBottom: 20,
    paddingTop: 20
  },
  contactCard: {
    marginTop: 20
  }
}));

const initialValues: FormValues = {
  [RMAField.CUSTOMER_NAME]: '',
  [RMAField.STATUS]: rmaStatuses.RMA_STORE_INQUIRING,
  [RMAField.CONTACT_NO]: '',
  [RMAField.LOCATION]: '',
  [RMAField.TECH]: '',
  [RMAField.REMARKS]: '',
  [RMAField.REPORTED_PROBLEM]: '',

  [RMAField.PRODUCT_ID]: null, // FE Only. Useless After Passed on API
  [RMAField.LISTING_ID]: null, // FE Only. Useless After Passed on API
  [RMAField.PRODUCT_NAME]: '', // FE Only
  [RMAField.SERIAL_NO]: '' // FE Only
};

const { actions: rmaActions } = slices.rma;
const { actions: transactionActions } = slices.transaction;
const { actions: listingActions } = slices.listing;

const CreateRMAView = () => {
  const classes = useStyles();
  const snackBar = useSnackBar();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userBranchIds } = useUserInfo();
  const isMoblie = useResolution();

  const isLoading = useRef(false);

  const [searchInfoLoading, setSearchInfoLoading] = useState<boolean>(false);
  const [transactionNoInput, setTransactionNoInput] = useState<string>('');
  const [transactionInfoResponse, setTransactionInfoResponse] = useState<
    GetTransactionsViaTransactionNoResponse
  >();
  const [serialNoSearchInput, setSerialNoSearchNoInput] = useState<string>('');

  const rmaTransactionItems = useMemo(() => {
    const notDeletedTransactionItems =
      transactionInfoResponse?.items?.filter((item) => !item?.isDeleted) || [];
    return notDeletedTransactionItems;
  }, [transactionInfoResponse]);

  const resetFormAlongWithOtherStates = () => {
    formik.resetForm();
    setTransactionNoInput('');
    setTransactionInfoResponse(undefined);
    setSearchInfoLoading(false);
  };

  const setInitialFormValues = (
    someValuesAsideConstInitialValues: FormValues
  ) => {
    formik.setValues({
      ...initialValues,
      ...someValuesAsideConstInitialValues
    });
  };

  const resetState = () => {
    formik.resetForm();
  };

  const autoFillFormForStockUnit = (listingInfo: Listing) => {
    const listingBranch = rmaLocationOptions?.find(
      (x) => x.branch_id === listingInfo?.branch_id
    )?.value;

    const newAddFormValues: FormValues = {
      [RMAField.CONTACT_NO]: '',
      [RMAField.PRODUCT_ID]: listingInfo?.product_id,
      [RMAField.LISTING_ID]: listingInfo?.id,
      [RMAField.PRODUCT_NAME]: listingInfo?.product_name,
      [RMAField.SERIAL_NO]: listingInfo?.serial_no,
      [RMAField.LOCATION]: listingBranch || null,
      [RMAField.CUSTOMER_NAME]: rmaDefaultValues.defaultCustomerNameStockUnit
    };

    formik.setValues({ ...formik.values, ...newAddFormValues });
    setTransactionInfoResponse(undefined);
  };

  const getTransactionInfoAndValidateIt = async (transactionNo?: string) => {
    if (!transactionNo) {
      // Meaning stock
      snackBar.show({
        severity: 'error',
        message: 'Transaction No. is required'
      });
      resetFormAlongWithOtherStates();
      return;
    }

    const transactionInfoResp: any = unwrapResult(
      await dispatch(
        transactionActions.getTransactionViaTransactionNo(transactionNo)
      )
    );

    if (
      !transactionInfoResp?.success ||
      isEmpty(transactionInfoResp?.originalData)
    ) {
      snackBar.show({
        severity: 'error',
        message: 'Error Fetching Transaction Data'
      });
      resetFormAlongWithOtherStates();
      return;
    }
    if (transactionInfoResp?.originalData?.items?.length <= 0) {
      snackBar.show({
        severity: 'error',
        message: 'There is not even a product in this transaction?'
      });
      resetFormAlongWithOtherStates();
      return;
    }

    const transactionInfo: GetTransactionsViaTransactionNoResponse =
      transactionInfoResp?.originalData;
    const transactionInfoItems = transactionInfo?.items || [];

    const allDeleted = transactionInfoItems.every(
      (item) => item.isDeleted === 1
    );

    if (allDeleted) {
      snackBar.show({
        severity: 'error',
        message: 'This transaction is already voided?'
      });
      resetFormAlongWithOtherStates();
      return;
    }

    return transactionInfo;
  };

  const onInputOfTransactionNo = async () => {
    const transactionNo: string = transactionNoInput?.trim();
    setSearchInfoLoading(true);
    setTransactionNoInput('');

    const transactionInfoResp = await getTransactionInfoAndValidateIt(
      transactionNo
    );

    if (!transactionInfoResp) {
      return;
    }

    setTransactionInfoResponse(transactionInfoResp);
    setSearchInfoLoading(false);
  };

  const onInputOfSerialNoSearchNo = async () => {
    const serialNoToBeSearched: string = serialNoSearchInput?.trim();
    setSearchInfoLoading(true);
    setSerialNoSearchNoInput('');

    const inventoryInfoViaSN = unwrapResult(
      await dispatch(rmaActions.getRmaSNTransactionNo(serialNoToBeSearched))
    );

    if (!inventoryInfoViaSN?.success) {
      snackBar.show({
        severity: 'error',
        message:
          inventoryInfoViaSN?.message || "Error Fetching Serial No.'s Info"
      });
      resetFormAlongWithOtherStates();
      return;
    }

    if (
      inventoryInfoViaSN?.originalData?.items &&
      inventoryInfoViaSN?.originalData?.items?.length <= 0
    ) {
      snackBar.show({
        severity: 'error',
        message: inventoryInfoViaSN?.message || 'Serial no. does not exist'
      });
      resetFormAlongWithOtherStates();
      return;
    }

    if (
      inventoryInfoViaSN?.originalData?.items &&
      inventoryInfoViaSN?.originalData?.items?.length > 1
    ) {
      snackBar.show({
        severity: 'error',
        message: '1. Why are there duplicate SN?. Please contact the dev team?.'
      });
      resetFormAlongWithOtherStates();
      return;
    }

    const serialNoTransactionNo =
      inventoryInfoViaSN?.originalData?.items?.[0]?.transaction_no;

    // STOCK UNIT FLOW
    // If the transaction no. of the serial no. is not found
    // Meaning, the serial no. is not in any transaction. Probably Stock Unit
    if (!serialNoTransactionNo) {
      const transactionItemForRMA =
        inventoryInfoViaSN?.originalData?.items?.[0];
      const listingInfoRes = unwrapResult(
        await dispatch(
          listingActions.getListingViaSNThunk({
            serial_no: cleanSN(transactionItemForRMA?.serial_no || ''),
            branch_ids: userBranchIds || []
          })
        )
      );
      const listingInfo = listingInfoRes?.originalData?.listing;
      if (!listingInfo) {
        snackBar.show({
          severity: 'error',
          message: 'Error Fetching Listing Info'
        });
        resetFormAlongWithOtherStates();
        return;
      }

      autoFillFormForStockUnit(listingInfo);
      setSearchInfoLoading(false);
      return;
    }

    // TODO: Maybe another function
    // CUSTOMER UNIT FLOW
    // If the transaction no. of the serial no. is existing
    // Meaning, the serial no. is a Customer Unit
    if (serialNoTransactionNo) {
      const transactionInfoResp = await getTransactionInfoAndValidateIt(
        serialNoTransactionNo
      );

      if (!transactionInfoResp) {
        return;
      }

      setSearchInfoLoading(false);

      const transactionItemForRMA = transactionInfoResp?.items?.find(
        (x) => x.serial_no === serialNoToBeSearched && !x.isDeleted
      );

      if (!transactionItemForRMA) {
        snackBar.show({
          severity: 'error',
          message:
            '2. Why are there duplicate SN?. Please contact the dev team.'
        });
        resetFormAlongWithOtherStates();
        return;
      }

      const newAddFormValues: FormValues = {
        [RMAField.CUSTOMER_NAME]: `${transactionInfoResp?.customer
          ?.first_name || ''} ${transactionInfoResp?.customer?.last_name ||
          ''}`,
        [RMAField.CONTACT_NO]: transactionInfoResp?.customer?.contact_no,
        [RMAField.PRODUCT_ID]: transactionItemForRMA?.product_id,
        [RMAField.LISTING_ID]: transactionItemForRMA?.listing_id,
        [RMAField.PRODUCT_NAME]: transactionItemForRMA?.product_name,
        [RMAField.SERIAL_NO]: transactionItemForRMA?.serial_no
      };

      formik.setValues({ ...formik.values, ...newAddFormValues });
    }
  };

  const onSelectTransactionItemOnTransactionDialog = (
    transactionItemForRMA: Transaction
  ) => {
    const newAddFormValues: FormValues = {
      [RMAField.CUSTOMER_NAME]: `${transactionInfoResponse?.customer
        ?.first_name || ''} ${transactionInfoResponse?.customer?.last_name ||
        ''}`,
      [RMAField.CONTACT_NO]: transactionInfoResponse?.customer?.contact_no,
      [RMAField.PRODUCT_ID]: transactionItemForRMA?.product_id,
      [RMAField.LISTING_ID]: transactionItemForRMA?.listing_id,
      [RMAField.PRODUCT_NAME]: transactionItemForRMA?.product_name,
      [RMAField.SERIAL_NO]: transactionItemForRMA?.serial_no
    };

    formik.setValues({ ...formik.values, ...newAddFormValues });
    setTransactionInfoResponse(undefined);
  };

  const onSelectItemOnNonSNInput = (selectedNonSnListing?: Listing) => {
    if (selectedNonSnListing) {
      autoFillFormForStockUnit(selectedNonSnListing);
    }
  };

  // When adding a rma some fields have conditions that need to be met before they can appear
  // Here, what we do is remove the value of the field if the condition is not met
  const isSomeConditionalFieldsHaveUselessValues = () => {
    const currentFormikValues: any = cloneDeep(formik?.values);
    addRMAFields?.forEach((field) => {
      if (field?.conditional) {
        const dependentValue = currentFormikValues[field.conditional.dependsOn];
        if (!field?.conditional?.value?.includes(dependentValue)) {
          formik.setFieldValue(field.key, initialValues[field.key]);
          currentFormikValues[field.key] = initialValues[field.key];
        }
      }
    });

    return currentFormikValues;
  };

  // For some reason it skips this function when the form is submitted
  const handleFormSubmit = async (values: any) => {
    if (isLoading.current) {
      return;
    }

    isLoading.current = true;

    // Check if formik fields are valid
    if (formik?.errors && Object.keys(formik?.errors)?.length > 0) {
      isLoading.current = false;

      snackBar.show({
        severity: 'error',
        message: JSON.stringify(formik?.errors)
      });
      return;
    }

    let newRMAData: CreateRmaRequest = values;

    // Check if there are conditional fields that have useless values
    const thereIsAConditionalFieldThatHasAUselessValue = isSomeConditionalFieldsHaveUselessValues();
    newRMAData = thereIsAConditionalFieldThatHasAUselessValue;

    // DELETE USELESS FIELDS BEFORE API
    const clonedRMAData = cloneDeep(newRMAData);
    delete clonedRMAData.product_name;
    delete clonedRMAData.serial_no;

    // TODO: Palitan ng legit na product_id
    const response = unwrapResult(
      await dispatch(
        rmaActions.createRmaThunk({
          ...clonedRMAData,
          product_id: clonedRMAData?.product_id,
          listing_id: clonedRMAData?.listing_id
        })
      )
    );

    if (response?.errors) {
      snackBar.show({
        severity: 'error',
        message: JSON.stringify(response?.errors)
      });

      isLoading.current = false;

      console.error(response?.errors);
      return;
    }

    snackBar.show({
      severity: 'success',
      message: 'RMA added successfully'
    });

    isLoading.current = false;
    resetState();

    if (response?.originalData?.data?.rma_transaction_id) {
      return response?.originalData?.data?.rma_transaction_id;
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleFormSubmit,
    validationSchema: formSchema
  });

  useEffect(() => {
    const search = location.search;
    const params = new URLSearchParams(search);

    const paramsKeyValuePair: any = {};
    params.forEach((value, key) => {
      paramsKeyValuePair[key] = value;
    });

    const validKeys = taptapAddCustomerFields.map((item) => item.key);

    // Filter the data object to include only valid keys
    const filteredData = Object.keys(paramsKeyValuePair)
      .filter((key) => validKeys.includes(key)) // Keep keys that are in the schema
      .reduce((acc: any, key) => {
        acc[key] = paramsKeyValuePair[key];
        return acc;
      }, {});

    setInitialFormValues(filteredData);
    // formik.setValues({ ...initialValues, ...filteredData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateClick = async (
    action: 'navigate_rma' | 'create_another'
  ) => {
    const validateForm = await formik.validateForm();
    if (validateForm && Object.keys(validateForm).length > 0) {
      const errorFields = Object.keys(validateForm).join(', ');
      const message = `Please check the following fields: ${errorFields}`;
      snackBar.show({ severity: 'error', message, useSound: true });
      return;
    }

    if (isLoading.current) {
      snackBar.show({
        severity: 'error',
        message: 'Currently loading. Please wait.'
      });
      return;
    }

    const newRmaId = await formik.submitForm();

    if (newRmaId) {
      if (action === 'navigate_rma') {
        navigate(`/app/rma/${newRmaId}`);
        return;
      }

      // Clear the fields
      if (action === 'create_another') {
        resetState();
        return;
      }
    }
  };

  return (
    <Page className={classes.root} title="Add RMA">
      <Container
        maxWidth={false}
        style={{
          opacity: isLoading.current || searchInfoLoading ? 0.3 : 1
        }}
      >
        {formik?.values?.is_deleted && (
          <Alert
            hidden
            severity="error"
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <Typography variant="h3">
              RMA IS ALREADY DELETED. YOU CANNOT EDIT THIS RMA ANYMORE.
            </Typography>
          </Alert>
        )}
        <LoaderBar isLoading={isLoading.current} />
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography color="textPrimary" gutterBottom variant="h3">
            Add RMA {`- ${formik?.values?.customer_name || ''}`}
          </Typography>
        </Box>

        <Box mb={3}>
          <RMATransactionNoInput
            isLoading={searchInfoLoading}
            transactionNoInput={transactionNoInput}
            onEnterOfTransactionNo={onInputOfTransactionNo}
            onChangeTransactionNoInput={(val) => setTransactionNoInput(val)}
            serialNoInput={serialNoSearchInput}
            onEnterOfSerialNo={onInputOfSerialNoSearchNo}
            onChangeSerialNoInput={(val) => setSerialNoSearchNoInput(val)}
          />
        </Box>

        <Box mb={3}>
          <RMANonSNSelector onChangeSelectedItem={onSelectItemOnNonSNInput} />
        </Box>

        <Divider />

        <Box mt={3} style={{ opacity: isLoading.current ? 0.3 : 1 }}>
          <RMAForm formikHookRef={formik} formFields={addRMAFields} />
        </Box>

        {/* ADD RMA BUTTON IN /rma/add */}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ gap: 20 }}
        >
          <LoadingButton
            fullWidth
            onClick={() => handleCreateClick('navigate_rma')}
            color="primary"
            variant="contained"
            height={isMoblie ? 70 : 50}
            disabled={isLoading.current}
            loading={isLoading.current}
            title="Add and Navigate to RMA"
          />
          <LoadingButton
            fullWidth
            onClick={() => handleCreateClick('create_another')}
            color="default"
            variant="contained"
            height={isMoblie ? 70 : 50}
            disabled={isLoading.current}
            loading={isLoading.current}
            title="Add and Create another RMA"
          />
        </Box>
      </Container>

      <Dialog
        fullWidth
        open={transactionInfoResponse ? true : false}
        onClose={resetFormAlongWithOtherStates}
      >
        <DialogTitle>Select an item for RMA</DialogTitle>
        <DialogContent>
          <List>
            {rmaTransactionItems?.map((item) => (
              <RMATransactionItem
                key={item?.id}
                transactionItem={item}
                onSelectTransactionItem={
                  onSelectTransactionItemOnTransactionDialog
                }
              />
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetFormAlongWithOtherStates} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default CreateRMAView;

const formSchema = yup.object().shape({
  [RMAField.CUSTOMER_NAME]: yup.string().required('Customer Name is required'),
  [RMAField.LOCATION]: yup.string().required('Location is required'),
  [RMAField.STATUS]: yup.string().required('Status is required'),
  [RMAField.REPORTED_PROBLEM]: yup
    .string()
    .required('Reported Problem is required'),
  [RMAField.CONTACT_NO]: yup
    .string()
    .nullable()
    .matches(/\d+$/, 'Contact number must be numeric')
});
