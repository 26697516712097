/* eslint-disable no-unused-vars */
import {
  Typography,
  Box,
  Grid,
  Button,
  List,
  ListItem,
  ListItemText,
  CardContent,
  Card,
  Divider,
  ListItemIcon,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove
} from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import {
  DndContext,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';

import useUserInfo from 'src/hooks/user/use-user-info';
import { toTitleCase } from 'src/utils';
import { slices } from 'src/redux';
import { useSelector } from 'react-redux';

const { selectors: favoriteModuleSelectors } = slices.favorites;
import { useAppDispatch } from 'src/redux';
import { Divide } from 'react-feather';
import useResolution from 'src/hooks/useResolution';
import SortableButton from './components/SortableButton';

const useStyles = makeStyles(() => ({
  avatar: {
    display: 'block',
    margin: '1em auto',
    width: 250,
    height: 250
  },
  navigation: {
    marginTop: 2,
    height: '180px'
  },
  nameAndDate: {
    fontSize: '1.7em'
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },

  flexRow: {
    width: 'inherit',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    marginBottom: '10px'
  },
  button: {
    marginTop: 'auto'
  }
}));

const { actions: addModuleToFavoritesAction } = slices.favorites;
const { selectors: recentlyVisitedModuleSelector } = slices.recentView;
const { actions: addModulesToListAction } = slices.recentView;

const WelcomePage = () => {
  const classes = useStyles();
  const { userDetails } = useUserInfo();
  const dispatch = useAppDispatch();
  const { isMobile } = useResolution();

  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [isDragging, setIsDragging] = useState(false);
  const [rearrangeMode, setRearrangeMode] = useState(false);

  const recentlyVisitedUrls = useSelector(
    recentlyVisitedModuleSelector.selectRecentlyVisitedUrls
  );

  const favoriteModules = useSelector(
    favoriteModuleSelectors.selectFavoriteModule
  );

  const handleRearrangeToggle = () => {
    setRearrangeMode((prev) => !prev);
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 150,
        tolerance: 5
      }
    })
  );

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = (event: any) => {
    setTimeout(() => {
      setIsDragging(false);
    }, 100);

    const { active, over } = event;
    if (!over || active.id === over.id) return;

    const oldIndex = favoriteModules.findIndex((m) => m.url === active.id);
    const newIndex = favoriteModules.findIndex((m) => m.url === over.id);

    if (oldIndex === -1 || newIndex === -1) return;

    const newOrder = arrayMove(favoriteModules, oldIndex, newIndex);
    dispatch(addModuleToFavoritesAction.updateFavoriteModulesOrder(newOrder));
  };

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    href: string,
    title: string
  ) => {
    if (isDragging) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    dispatch(
      addModulesToListAction.addModulesToList({
        url: href,
        title: title
      })
    );

    if (event.ctrlKey || event.metaKey || event.button === 2) {
      window.open(href, '_blank');
    } else {
      window.location.href = href;
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Box sx={{ p: 2, height: '90vh' }}>
      <Typography variant="h4" gutterBottom style={{ fontSize: '2em' }}>
        Welcome to the Internal Systems
      </Typography>
      <Box className={classes.flexRow}>
        <Typography variant="h6" className={classes.nameAndDate}>
          {toTitleCase(userDetails?.first_name ?? '')}
        </Typography>
        <Typography variant="h6" className={classes.nameAndDate}>
          {currentDateTime.toLocaleDateString()}{' '}
          {currentDateTime.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit'
          })}
        </Typography>
      </Box>
      <Typography variant="body1" gutterBottom style={{ marginBottom: '1em' }}>
        This platform allows you to manage various aspects of the business,
        including customer management, transactions, inventory, and more. Use
        the navigation links below to get started.
      </Typography>
      {/* TODO: Make this recently visited in the future. */}
      <Divider />
      <Grid container spacing={2} style={{ marginTop: 5, marginBottom: '1em' }}>
        <Grid item xs={12}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5">Shortcut Links</Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={rearrangeMode}
                  onChange={handleRearrangeToggle}
                />
              }
              label="Rearrange the button"
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          {favoriteModules.length === 0 ? (
            <Typography variant="subtitle2" color="textSecondary">
              No Shortcut Links
            </Typography>
          ) : (
            <Box sx={{ p: 2, height: '90vh' }}>
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
              >
                <SortableContext
                  items={favoriteModules.map((module) => ({ id: module.url }))}
                  strategy={verticalListSortingStrategy}
                >
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    style={{ gap: 2 }}
                    justifyContent={isMobile ? 'center' : undefined}
                  >
                    {favoriteModules.map((module, index) => (
                      <SortableButton
                        key={module.url}
                        module={module}
                        index={index}
                        onClick={(e: any) =>
                          handleClick(e, module.url, module.title)
                        }
                        isDragging={isDragging}
                        disabled={rearrangeMode}
                        rearrangeMode={rearrangeMode}
                      />
                    ))}
                    <Grid
                      container
                      spacing={1}
                      style={{ marginTop: '2em', marginBottom: '1em' }}
                    >
                      <Grid item xs={12}>
                        <Typography variant="h5">
                          Recently Viewed Modules
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <List style={{ width: '100%' }}>
                          {recentlyVisitedUrls.length === 0 ? (
                            <Typography
                              variant="subtitle2"
                              color="textSecondary"
                            >
                              No recently viewed modules
                            </Typography>
                          ) : (
                            recentlyVisitedUrls.map((module, index) => (
                              <ListItem key={index}>
                                <ListItemIcon style={{ minWidth: '20px' }}>
                                  •
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    <a
                                      href={module.url}
                                      style={{
                                        textDecoration: 'none',
                                        color: 'inherit',
                                        display: 'block',
                                        padding: '5px 10px',
                                        borderRadius: '5px',
                                        width: '300px'
                                      }}
                                    >
                                      {module.title}
                                    </a>
                                  }
                                />
                              </ListItem>
                            ))
                          )}
                        </List>
                      </Grid>
                    </Grid>
                  </Box>
                </SortableContext>
              </DndContext>
            </Box>
          )}
        </Grid>
      </Grid>

      <Divider />
    </Box>
  );
};

export default WelcomePage;
