import { RMAColumn } from '../types';
import { RMAField } from './rma-fields';
import { rmaLocationOptions, rmaOptionsStatuses } from './rma-form-fields';

export const initialRMATableColumns: RMAColumn[] = [
  {
    field: RMAField.SERIAL_NO,
    type: 'custom',
    name: 'serial_no',
    visible: true,
    sticky: true
  },
  {
    field: RMAField.STATUS,
    type: 'autocomplete',
    name: 'status',
    visible: true,
    minWidth: 300,
    options: rmaOptionsStatuses
  },
  {
    field: RMAField.SUPPLIER_NAME,
    name: 'supplier_name',
    type: 'text',
    visible: true
  },
  {
    field: RMAField.TRANSACTION_NO,
    name: 'transaction_no',
    type: 'link',
    visible: true
  },
  {
    field: RMAField.PRODUCT_NAME,
    name: 'product_name',
    type: 'text',
    visible: true
  },
  {
    field: RMAField.LOCATION,
    type: 'autocomplete',
    name: 'location',
    visible: true,
    minWidth: 300,
    options: rmaLocationOptions
  },
  {
    field: RMAField.REPORTED_PROBLEM,
    name: 'reported_problem',
    type: 'text',
    visible: true
  },
  {
    field: RMAField.CUSTOMER_NAME,
    name: 'customer_name',
    type: 'text',
    visible: true
  },
  {
    field: RMAField.REMARKS,
    name: 'remarks',
    type: 'text',
    visible: true
  },
  {
    field: RMAField.RMA_IMAGES,
    name: 'images',
    type: 'image',
    visible: true
  },
  {
    field: RMAField.CONTACT_NO,
    name: 'contact_no',
    type: 'text',
    visible: true
  },
  {
    field: RMAField.TECH,
    name: 'tech',
    type: 'text',
    visible: true
  },
  {
    field: RMAField.CREATED_BY,
    name: 'created_by',
    type: 'text',
    visible: true
  },
  {
    field: RMAField.CREATED_AT,
    name: 'created_at',
    type: 'text',
    visible: true
  },
  {
    field: RMAField.TURN_AROUND_TIME,
    name: 'turnaround_time',
    type: 'text',
    visible: true
  }
];
