import { IconButton, TableCell, TableRow, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { CustomInputEvent, TransactionPayment } from 'src/types';
import { TransactionDetailsARCell } from './TransactionARTableCell';
import { formatCurrency, getPaymentTypeColor } from 'src/utils';
import DeleteIcon from '@material-ui/icons/Delete';
import { colors } from 'src/constants';

interface Props {
  payment: TransactionPayment;
  index: number;
  isDeleted?: boolean;
  canDeleteTransactionPayment: boolean;
  onSaveARField: (e: any, payment: TransactionPayment) => void;
  onDeletePayment: (payment: TransactionPayment) => void;
  onChangeARFieldText: (e: CustomInputEvent, index: number) => void;
}

export const PaymentRow: FC<Props> = ({
  payment,
  index,
  isDeleted = false,
  canDeleteTransactionPayment,
  onSaveARField,
  onChangeARFieldText,
  onDeletePayment
}) => {
  return (
    <TableRow
      hover
      key={payment.id}
      style={isDeleted ? { backgroundColor: colors.error } : {}}
    >
      <TableCell>{payment?.date_posted || '--'}</TableCell>
      <TransactionDetailsARCell
        data={payment}
        index={index}
        isDeleted={isDeleted}
        onSave={onSaveARField}
        onChangeText={onChangeARFieldText}
      />
      <TableCell>
        <Typography
          variant="h6"
          style={{
            color: getPaymentTypeColor(payment?.payment_type)
          }}
        >
          {payment?.payment_type || '--'}
        </Typography>
      </TableCell>
      <TableCell>{formatCurrency(payment?.amount)}</TableCell>
      <TableCell>{payment?.created_by || '--'}</TableCell>
      {canDeleteTransactionPayment && (
        <TableCell>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              onDeletePayment(payment);
            }}
          >
            <DeleteIcon color="secondary" />
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  );
};
