import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import { taptapGetProfiles } from './services';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import { TapTapCustomerData, TapTapProfile } from './types';
import { cloneDeep } from 'lodash';
import { getCustomersQueryBuilder } from './utils';

export const taptapFetchCustomersThunk = createAsyncThunk<
  PostgrestSingleResponse<TapTapCustomerData[]>,
  undefined,
  { state: RootState }
>('taptapCustomers/taptapFetchCustomersThunk', async (_, thunkApi) => {
  const filterPayload = thunkApi.getState().taptapCustomer
    .taptapGetCustomersPayload;

  const response = await getCustomersQueryBuilder({
    payloadArg: filterPayload
  });

  if (response.error) {
    throw new Error('Failed API Call on taptapFetchCustomersThunk');
  }

  return response;
});

export const taptapFetchCustomersNextPageThunk = createAsyncThunk<
  PostgrestSingleResponse<TapTapCustomerData[]>,
  undefined,
  { state: RootState }
>('taptapCustomers/taptapFetchCustomersNextPageThunk', async (_, thunkApi) => {
  const lastPageReached = thunkApi.getState().taptapCustomer.lastPageReached;
  if (lastPageReached) {
    throw new Error('Failed API Call on taptapFetchCustomersThunk');
  }

  const filterPayload = thunkApi.getState().taptapCustomer
    .taptapGetCustomersPayload;
  const clonedFilterPayload = cloneDeep(filterPayload); // cloned becase mutation is not allowed and process wont proceed

  clonedFilterPayload.pagination.page = clonedFilterPayload.pagination.page + 1; // Syempre next page nga eh heheh
  const maxRecordCount = thunkApi.getState().taptapCustomer
    .taptapCustomerMaxCount;

  const response = await getCustomersQueryBuilder({
    payloadArg: clonedFilterPayload,
    maxRecordCount
  });

  return response;
});

export const taptapGetProfilesThunk = createAsyncThunk<
  PostgrestSingleResponse<TapTapProfile[]>,
  undefined,
  { state: RootState }
>('taptapCustomers/taptapGetProfilesThunk', async () => {
  const response = await taptapGetProfiles();
  if (response.error) {
    throw new Error('Failed API Call on taptapFetchCustomersThunk');
  }

  return response;
});
