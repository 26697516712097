import { Typography, Button, ListItemText } from '@material-ui/core';
import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface Module {
  url: string;
  title: string;
}

interface SortableButtonProps {
  module: Module;
  index: number;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  isDragging?: boolean;
  disabled?: boolean;
  rearrangeMode: boolean;
}

const SortableButton: React.FC<SortableButtonProps> = ({
  module,
  onClick,
  rearrangeMode
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: module.url,
    disabled: !rearrangeMode
  });

  const style: React.CSSProperties = {
    transform: transform ? CSS.Transform.toString(transform) : undefined,
    transition: transition || undefined,
    backgroundColor: rearrangeMode ? '#f0f0f0' : '#d5d5d5',
    width: '300px',
    textAlign: 'center',
    padding: '10px',
    opacity: isDragging ? 0.5 : 1,
    cursor: rearrangeMode ? 'grab' : 'pointer',
    touchAction: 'none'
  };

  const handleButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    if (isDragging || rearrangeMode) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    onClick?.(e);
  };

  return (
    <Button
      ref={setNodeRef}
      {...(rearrangeMode ? attributes : {})} 
      {...(rearrangeMode ? listeners : {})}
      component="a"
      href={rearrangeMode ? undefined : module.url} 
      style={style}
      onClick={handleButtonClick}
    >
      <ListItemText
        primary={<Typography variant="subtitle2">{module.title}</Typography>}
      />
    </Button>
  );
};

export default SortableButton;
