/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Collapse,
  List,
  ListItem,
  makeStyles
} from '@material-ui/core';
import { DrawerItem as DrawerItemType } from 'src/types/layout/drawer';
import clsx from 'clsx';
import { DrawerItemLabelEnum } from 'src/layouts/DashboardLayout/types';
import { useNavigate } from 'react-router';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import ArrowRightOutlinedIcon from '@material-ui/icons/ArrowRightOutlined';
import { useDrawerItemsWithPerms } from 'src/hooks/use-drawer-items-with-perms';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';

const { actions: appActions, selectors: appSelectors } = slices.app;
const { actions: addModuleToFavoritesAction } = slices.favorites;
const { actions: addModulesToListAction } = slices.recentView;

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    // fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 5px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  nested: {
    paddingLeft: theme.spacing(.625)
  }
}));

export const DrawerSection = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { drawerItemsWithPermissions } = useDrawerItemsWithPerms();

  const openDrawerSectionIndicesArr = useAppSelector(
    appSelectors.selectOpenDrawerSectionIndices
  );

  const [activeDrawerItem, setActiveDrawerItem] = useState<
    DrawerItemLabelEnum
  >();

  const onClickItem = (
    event: React.MouseEvent<HTMLLIElement>,
    item: DrawerItemType
  ) => {
    event.preventDefault(); // Prevent default navigation

    if (event.ctrlKey || event.metaKey || event.button === 2) {
      dispatch(
        addModulesToListAction.addModulesToList({
          url: item.href,
          title: item.title
        })
      );
      window.open(item.href);
      window.location.href = item.href;
    } else {
      dispatch(
        addModulesToListAction.addModulesToList({
          url: item.href,
          title: item.title
        })
      );
      window.location.href = item.href;
    }

    setActiveDrawerItem(item.title);
  };

  // UseEffect for checking if the url is opened or not
  useEffect(() => {
    const currentPath = window.location.pathname;
    const currentDrawerItem = drawerItemsWithPermissions
      ?.flatMap((x) => x.sectionItems)
      .find((x) => x.href === currentPath);

    if (currentDrawerItem) {
      dispatch(
        addModulesToListAction.addModulesToList({
          url: currentDrawerItem.href,
          title: currentDrawerItem.title
        })
      );
      setActiveDrawerItem(currentDrawerItem.title);
    }
  }, [drawerItemsWithPermissions, dispatch]);

  const isSectionOpen = useCallback(
    (index: number) => {
      const isExist = openDrawerSectionIndicesArr?.findIndex(
        (x) => x === index
      );
      return isExist > -1;
    },
    [openDrawerSectionIndicesArr]
  );

  const favoriteModules = useAppSelector(
    (state) => state.favoriteModules.favoriteModules
  );

  const isFavorite = (href: string) => {
    return favoriteModules.some(
      (module) => module.url === href && module.isFavorite
    );
  };

  const toggleFavorite = (href: string, title: string) => {
    const favoriteExists = isFavorite(href);
    if (favoriteExists) {
      dispatch(
        addModuleToFavoritesAction.removeModulesToFavorite({
          url: href,
          title,
          isFavorite: false
        })
      );
    } else {
      dispatch(
        addModuleToFavoritesAction.addModulesToFavorite({
          url: href,
          title,
          isFavorite: true
        })
      );
    }
  };

  const onToggleSection = (index: number) => {
    dispatch(appActions.toggleDrawerSection(index));
  };

  return (
    <List style={{ display: 'flex', flexDirection: 'column' }}>
      {drawerItemsWithPermissions?.map((x, i) => (
        <div key={x?.sectionTitle}>
          <ListItem className={classes.item} disableGutters>
            <Button
              color="primary"
              className={classes.button}
              onClick={() => onToggleSection(i)}
            >
              <ArrowRightOutlinedIcon
                className={classes.icon}
                fontSize="small"
              />
              <span className={classes.title}>{x?.sectionTitle}</span>
            </Button>
          </ListItem>
          <Collapse in={isSectionOpen(i)} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {x?.sectionItems?.map((y) => (
                <ListItem
                  key={y?.title}
                  disableGutters
                  className={clsx(classes.item, classes.nested)}
                  onClick={(event) => {
                    onClickItem(event, y);
                  }}
                >
                  <Button
                    style={{
                      width: 'fit-content',
                      minWidth: 'auto',
                      padding: '4px',
                      fontSize: '20px'
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      toggleFavorite(y.href, y.title);
                    }}
                  >
                    {isFavorite(y.href) ? (
                      <StarIcon color="primary" style={{ fontSize: '20px' }} />
                    ) : (
                      <StarBorderIcon style={{ fontSize: '20px' }} />
                    )}
                  </Button>
                  <Button
                    href={
                      y?.title === DrawerItemLabelEnum.Logout
                        ? undefined
                        : y?.href
                    }
                    className={clsx(
                      classes.button,
                      activeDrawerItem === y?.title ? classes.active : undefined
                    )}
                    color="primary"
                  >
                    {y.icon ? (
                      <y.icon className={classes.icon} fontSize="small" />
                    ) : null}
                    <span className={classes.title}>{y.title}</span>
                  </Button>
                </ListItem>
              ))}
            </List>
          </Collapse>
        </div>
      ))}
    </List>
  );
};
