import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectFavoriteModuleState = (state: RootState) =>
  state.fastMovingProducts;

const selectCalendarParams = createSelector(
  selectFavoriteModuleState,
  (state) => state?.calendar_state
);

export const selectors = {
  selectCalendarParams
};
