import React, { useMemo } from 'react';
import { makeStyles, TableCell } from '@material-ui/core';

import { colors } from 'src/constants';
import { copyToClipboard } from 'src/utils';

import { ProductPriceListData } from 'src/types';
import { TypographyPriceList } from '../TypographyPriceList';
import { getCategoryColor } from 'src/utils/categories/getCategoryColor';

interface TableCellNameProps {
  branch_ids?: number[];
  id?: string;
  data: ProductPriceListData;
  index: number;
  isLoading?: boolean;
  priceList?: ProductPriceListData[];
}

const useStyles = makeStyles(
  {
    tableCellBorder: {
      borderRight: '0.5px solid black',
      borderColor: colors.primary
    }
  },
  { index: 1 }
);

export const Component = ({ index, data, branch_ids }: TableCellNameProps) => {
  const classes = useStyles();

  const colorVariant = useMemo(() => (index % 2 ? '100' : '200'), [index]);

  const backgroundColor = useMemo(
    () => getCategoryColor(data.category_id || 0)[colorVariant],
    [colorVariant, data.category_id]
  );

  const onCopyText = (text: string) => {
    const newText = `${text}`;
    copyToClipboard(newText);
  };

  return (
    <TableCell
      style={{ backgroundColor }}
      className={classes.tableCellBorder}
      onClick={(e) => e.stopPropagation()}
    >
      <TypographyPriceList
        link={`/app/products/${data?.id}`}
        text={data?.product_name || ''}
        subText={`SKU: ${data?.sku || ''}`}
        onCopy={() => {
          onCopyText(data?.product_name || '');
        }}
        branchIds={branch_ids}
      />
    </TableCell>
  );
};

export const Name = React.memo(Component);
