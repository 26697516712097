import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import Modal from '@material-ui/core/Modal';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { CustomKeyboardEvent, ProductPriceListData } from 'src/types';
import { between, formatCurrency, getColorGeneric } from 'src/utils';
import { TypographyPriceList } from './TypographyPriceList';
import { isEmpty } from 'lodash';
import { colors } from 'src/constants';
import { usePermissions } from 'src/hooks';

const useStyles = makeStyles((theme) => ({
  body: {
    position: 'absolute',
    width: 500,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    borderRadius: 8
  },
  filter: {
    marginTop: 20,
    marginBottom: 20
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  footer: {
    marginTop: 20
  },
  computationContainer: {
    marginTop: theme.spacing(2)
  },

  priceInput: {
    height: 25
  },
  saveIcon: {
    padding: 0
  }
}));

const bodyStyle: CSSProperties = {
  top: '20%',
  width: '90%', // Responsive width for mobile
  maxWidth: 600, // Maximum width for larger screens
  margin: '0 auto', // Center the modal
  padding: '16px', // Add padding for better spacing
  backgroundColor: 'white', // Ensure background is visible
  borderRadius: '8px', // Add rounded corners
  boxSizing: 'border-box' // Ensure padding is included in width
};

const modalStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

interface Props {
  visible: boolean;
  product?: ProductPriceListData;
  onHandleClose: (action: string, data?: ProductPriceListData) => void;
}

export const ProductPriceModal = ({
  visible,
  product,
  onHandleClose
}: Props) => {
  const classes = useStyles();
  const { canUpdateRetailPrice } = usePermissions();

  const [updatedProduct, setUpdatedProduct] = useState<ProductPriceListData>(
    product || {}
  );

  const [marginValue, setMarginValue] = useState<number>(0);

  useEffect(() => {
    if (product) {
      const dealersPrice = product.dealers_price || 1;
      const retailPrice = product.retail_price || 0;
      const initialMargin = ((retailPrice - dealersPrice) / dealersPrice) * 100;
      setMarginValue(initialMargin);
      setUpdatedProduct({ ...product });
    } else {
      setUpdatedProduct({});
      setMarginValue(0);
    }
  }, [product, visible]);

  const onClose = useCallback(
    (action: string, marginStat?: 'good' | 'bad' | 'warn') => {
      const updatedProductCopy = { ...updatedProduct };
      onHandleClose(action, {
        ...updatedProductCopy,
        margin_percentage: margin.toFixed(3) + '%',
        gross_price: gross,
        standard_price: srp,
        margin_status: marginStat //update locally for now status
      });
      setUpdatedProduct({});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onHandleClose, updatedProduct]
  );

  const onKeyDown = useCallback(
    (e: CustomKeyboardEvent) => {
      if (e.key === 'Enter') {
        onClose('update');
      }
    },
    [onClose]
  );

  const onMarginChange = (value: string) => {
    const newMargin = parseFloat(value);
    setMarginValue(newMargin);

    const dealersPrice = updatedProduct?.dealers_price || 1;
    const newRetailPrice = dealersPrice * (newMargin / 100) + dealersPrice;

    setUpdatedProduct((prev) => ({
      ...prev,
      retail_price: newRetailPrice
    }));
  };

  const onRetailPriceChange = (value: string) => {
    const newRetailPrice = parseFloat(value);

    const dealersPrice = updatedProduct?.dealers_price || 1;
    const newMargin = ((newRetailPrice - dealersPrice) / dealersPrice) * 100;

    setUpdatedProduct((prev) => ({
      ...prev,
      retail_price: newRetailPrice,
      installment: {
        three_months: (newRetailPrice / 3).toFixed(2),
        six_months: ((newRetailPrice + newRetailPrice * 0.12) / 6).toFixed(2),
        twelve_months: ((newRetailPrice + newRetailPrice * 0.12) / 12).toFixed(
          2
        )
      }
    }));
    setMarginValue(newMargin);
  };

  const gross = useMemo(() => {
    const dealersPrice = updatedProduct?.dealers_price || 0;
    const promoPrice = updatedProduct?.retail_price || 0;
    const difference = promoPrice - dealersPrice;

    return difference || 1;
  }, [updatedProduct]);

  const margin = useMemo(() => {
    const divisor = updatedProduct?.dealers_price || 1;
    const dividend = gross || 1;

    const quotient = dividend / divisor;
    const percentage = quotient * 100;
    return percentage;
  }, [gross, updatedProduct.dealers_price]);

  const srp = useMemo(() => {
    const retailPrice = updatedProduct?.retail_price || 0;

    const newSrp = retailPrice + retailPrice * 0.12;

    return newSrp || 0;
  }, [updatedProduct]);

  const marginColorViaGross = useMemo(() => {
    let status: 'good' | 'warn' | 'bad' | undefined = undefined;

    if (between(margin, -999999, 6)) {
      status = 'bad';
    }
    if (between(margin, 6, 10)) {
      status = 'warn';
    }
    if (between(margin, 10, 999999)) {
      status = 'good';
    }

    return getColorGeneric(status);
  }, [margin]);

  const marginStatusViaGross = useMemo(() => {
    if (between(margin, -999999, 6)) {
      return 'bad';
    }
    if (between(margin, 6, 10)) {
      return 'warn';
    }
    if (between(margin, 10, 999999)) {
      return 'good';
    }
  }, [margin]);

  useEffect(() => {
    setUpdatedProduct(isEmpty(product) ? {} : { ...product });
  }, [product, visible]);

  return (
    <>
      <Modal
        disableBackdropClick
        open={visible}
        onClose={() => onClose('close')}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={modalStyle}
      >
        <div style={bodyStyle} className={classes.body}>
          <div className={classes.titleContainer}>
            <Typography variant="h4" color="textPrimary">
              Product Listing Computations
            </Typography>
          </div>
          <div className={classes.computationContainer}>
            <Typography
              style={{ marginBottom: 12 }}
              variant="h5"
              color="textPrimary"
            >
              {product?.product_name}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TypographyPriceList
                  color={colors.warning}
                  text={
                    'Dealer Price: ' +
                    formatCurrency(product?.dealers_price || 0, 'PHP ')
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TypographyPriceList
                  color={colors.grey[600]}
                  extraTextStyle={{ textDecoration: 'line-through' }}
                  text={'SRP: ' + formatCurrency(srp || 0, 'PHP ')}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TypographyPriceList
                  color={marginColorViaGross}
                  text={`Gross: ${formatCurrency(gross)}`}
                />
              </Grid>

              <Grid item xs={12} sm={6} style={{ marginTop: -5 }}>
                <TextField
                  className={classes.priceInput}
                  onFocus={(e) => {
                    e.stopPropagation();
                  }}
                  fullWidth
                  size="small"
                  variant="standard"
                  type="number"
                  name="margin_percentage"
                  value={marginValue}
                  onChange={(e) => onMarginChange(e.target.value)}
                  onKeyDown={(e) => onKeyDown(e)}
                  InputProps={{
                    style: {
                      paddingLeft: 5,
                      color: marginColorViaGross,
                      border: `2px solid ${marginColorViaGross}`,
                      borderRadius: 5,
                      fontSize: 14
                    },
                    endAdornment: (
                      <InputAdornment
                        className={classes.saveIcon}
                        position="start"
                        color={marginColorViaGross}
                      >
                        %
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>

              <Grid item xs={12} style={{ marginTop: 12 }}>
                <TextField
                  className={classes.priceInput}
                  onFocus={(e) => {
                    e.stopPropagation();
                  }}
                  fullWidth
                  size="small"
                  variant="outlined"
                  onKeyDown={(e) => onKeyDown(e)}
                  type="number"
                  name="retail_price"
                  onChange={(e) => onRetailPriceChange(e.target.value)}
                  value={updatedProduct?.retail_price ?? ''}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        className={classes.saveIcon}
                        position="start"
                      >
                        ₱
                      </InputAdornment>
                    )
                  }}
                />
                <Typography variant="subtitle1" style={{ marginTop: 20 }}>
                  Current Promo Price:{' '}
                  {formatCurrency(product?.retail_price || 0, 'PHP ')}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            className={classes.footer}
          >
            <Button
              fullWidth
              onClick={() => onClose('close')}
              color="secondary"
              variant="outlined"
              style={{ marginRight: 10 }}
            >
              Close
            </Button>
            <Button
              fullWidth
              onClick={() => onClose('update', marginStatusViaGross)}
              color="primary"
              variant="outlined"
              disabled={!canUpdateRetailPrice}
            >
              Update
            </Button>
          </Box>
        </div>
      </Modal>
    </>
  );
};
