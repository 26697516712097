import { Button, ButtonGroup, Menu, MenuItem } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { FC, memo, useEffect, useState } from 'react';
import { usePermissions } from 'src/hooks';
import { SRPOption } from 'src/types/srp-options';

interface Props {
  title?: string;
  options?: any[];
  initial_val?: string;
  disableButton?: boolean;
  applyPermissions?: boolean;
  onChange?: (option: SRPOption) => void;
  onApplyClick?: (option: SRPOption) => void;
}

const SplitButton: FC<Props> = ({
  title = 'Split Button',
  options = [],
  initial_val,
  applyPermissions = false,
  disableButton = false,
  onApplyClick,
  onChange
}) => {
  const { canEditTransactionSRPPercentage } = usePermissions();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const handleClick = () => {
    const selectedOption = options[selectedIndex];
    if (onApplyClick) {
      onApplyClick(selectedOption);
    }
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    if (onChange) {
      onChange(options[index]);
    }
  };

  const handleToggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (initial_val) {
      const index = options?.findIndex((prev) => prev.name === initial_val);
      setSelectedIndex(index || 0);
    }
  }, [initial_val, options]);

  return (
    <>
      <ButtonGroup
        disabled={applyPermissions && !canEditTransactionSRPPercentage}
        variant="contained"
        color="primary"
      >
        <Button disabled={disableButton} onClick={handleClick}>
          {`${title}: ${options[selectedIndex]?.name || initial_val}`}
        </Button>
        <Button
          color="primary"
          size="small"
          variant="text"
          aria-controls="split-button-menu"
          aria-expanded={anchorEl ? 'true' : undefined}
          aria-haspopup="menu"
          onClick={handleToggleMenu}
        >
          <ExpandMoreIcon />
        </Button>
      </ButtonGroup>
      <Menu
        id="split-button-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export const SplitButtons = memo(SplitButton);
