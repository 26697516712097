import React from 'react';
import { Button, Link, makeStyles, Typography } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { colors } from 'src/constants';
import { useNavigate } from 'react-router-dom';

interface TypographyPriceListProps {
  text?: string;
  subText?: string;
  color?: string;
  link?: string;
  fontSize?: number;
  branchIds?: number[];
  onCopy?: () => void;
  extraTextStyle?: React.CSSProperties;
}

const useStyles = makeStyles(
  {
    row: { flexDirection: 'row', display: 'flex' },
    text: {
      letterSpacing: 0.5,
      fontWeight: 600
    }
  },
  { index: 999 }
);

export const PriceList = ({
  text,
  subText,
  onCopy,
  link,
  color = colors.black,
  fontSize = 14,
  extraTextStyle,
  branchIds
}: TypographyPriceListProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const customTextStyle = {
    color,
    fontSize,
    ...extraTextStyle
  };

  return (
    <div className={classes.row}>
      {onCopy ? (
        <Button
          style={{ padding: 0, margin: 0 }}
          startIcon={<FileCopyIcon />}
          onClick={onCopy}
        />
      ) : null}

      {link ? (
        <Link
          href={link}
          onClick={() => navigate(link, { state: branchIds || [] })}
        >
          <Typography className={classes.text} style={customTextStyle}>
            {text}
          </Typography>
          {subText && (
            <Typography className={classes.text} style={customTextStyle}>
              {subText}
            </Typography>
          )}
        </Link>
      ) : (
        <Typography className={classes.text} style={customTextStyle}>
          {text}
        </Typography>
      )}
    </div>
  );
};

export const TypographyPriceList = React.memo(PriceList);
